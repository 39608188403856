import { Button } from "@mui/material";
import "./conversationmenutabs.scss";
const ConversationMenuTabs = () => {
    return (
        <div className="conversationMenuTabs">

        </div>
    )
}

export default ConversationMenuTabs;