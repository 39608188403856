import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { DarkGreenButton } from "../../../../../theme/buttons";
import { selectAllDatasets } from "../../../settingsSlice";
import "./datasetsmainpage.scss";
import DatasetsDataTable from "../../components/datatable/DatasetDataTable";

const DatasetsMainPage = () => {
    const navigate = useNavigate();

    const addButtonClicked = (e) => {
        navigate("new");
    }

    return (
        <div className="datasetsmainpage">
            <div className="header">
                <div className="title">
                    <h3>Datasets</h3>
                </div>
                <div className="newButton">
                    <DarkGreenButton
                        onClick={addButtonClicked}
                        startIcon={<AddCircleOutlinedIcon/>}
                        size="small"
                    >Add Dataset</DarkGreenButton>
                </div>
            </div>
            <DatasetsDataTable />
        </div>
    )
}

export default DatasetsMainPage;