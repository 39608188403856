import ReturnBar from "../../../../components/returnbar/ReturnBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import SingleInfoProductForm from "../../components/singleinfoproductform/SingleInfoProductForm";
import "./singleinfoproduct.scss";

const SingleInfoProduct = () => {

    return (
        <div className="singleInfoProduct">
            <Sidebar/>
            <ReturnBar/>
            <SingleInfoProductForm/>
        </div>
    )
}

export default SingleInfoProduct;