import { MenuItem, Select, TextField } from "@mui/material";
import { useAddCaseCommentMutation, useGetCaseCommentsQuery } from "../../rtkReceptionControlSlice";
import "./singlebatchcomments.scss";
import { useState } from "react";
import { LightGreenButton } from "../../../../theme/buttons";
import SaveIcon from '@mui/icons-material/Save';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../users/usersSlice";
import { selectRTKCurrentUser } from "../../../auth/authSlice";


const SingleBatchComments = ({ caseId, caseStatus }) => {

    const { data: caseComments, isSuccess } = useGetCaseCommentsQuery(caseId);
    const [addComment] = useAddCaseCommentMutation();

    const [messageContent, setMessageContent] = useState("");
    const [newStatus, setNewStatus] = useState("");
    const currentUser = useSelector(selectRTKCurrentUser);

    const messageContentChanged = (e) => setMessageContent(e.target.value);
    const newStatusChanged = (e) => setNewStatus(e.target.value);

    const addCommentSubmitBtnClicked = (e) => {
        e.preventDefault();
        const addCommentObj = {
            caseId,
            userEmail: currentUser.email,
            messageContent,
            newStatus
        };

        try {
            addComment(addCommentObj);
        } catch (err) {
            console.log(err);
        } finally {
            setMessageContent("");
            setNewStatus("");
        }
    }


    return (
        <div className="commentsContainer">
            <div className="headerTitle">
                <h2>Comments</h2>
            </div>
            <div className="commentsList">
                <div className="commentsListHeader">
                    {/* <div className="commentId">
                        <p>ID</p>
                    </div> */}
                    <div className="commentCreatedAtDate">
                        <p>Datum</p>
                    </div>
                    <div className="commentCreatedAtTime">
                        <p>Tijd</p>
                    </div>
                    <div className="commentUserFullName">
                        <p>Gebruiker</p>
                    </div>
                    <div className="commentMessageContent">
                        <p>Opmerking</p>
                    </div>
                    <div className="commentStartStatus">
                        <p>Start Status</p>
                    </div>
                    <div className="commentEndStatus">
                        <p>Eind Status</p>
                    </div>
                </div>
                {isSuccess &&
                    caseComments.map((comment) => {

                        const date = new Date(comment.created_at);
                        const formattedDate = new Intl.DateTimeFormat('nl-Nl', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(date);
                        const formattedTime = new Intl.DateTimeFormat('nl-Nl', { hour: 'numeric', minute: 'numeric' }).format(date);


                        return (
                            <div key={comment.id} className="commentsListItem">
                                {/* <div className="commentId">
                                    <p>{comment.id}</p>
                                </div> */}
                                <div className="commentCreatedAtDate">
                                    <p>{formattedDate}</p>
                                </div>
                                <div className="commentCreatedAtTime">
                                    <p>{formattedTime}</p>
                                </div>
                                <div className="commentUserFullName">
                                    <p>{comment.user_full_name}</p>
                                </div>
                                <div className="commentMessageContent">
                                    <p>{comment.message_content}</p>
                                </div>
                                <div className="commentStartStatus">
                                    <span className={`${comment.start_status}`}>{comment.start_status}</span>
                                </div>
                                <div className="commentEndStatus">
                                    <span className={`${comment.end_status}`}>{comment.end_status}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="addCommentContainer">
                <div className="addCommentTitle">
                    <h2>Add Comment</h2>
                </div>
                <div className="addCommentForm">
                    <form onSubmit={addCommentSubmitBtnClicked}>
                        <div className="fieldContainer">

                            <div className="messageContent">
                                <TextField size="small" multiline fullWidth minRows={1} sx={{ backgroundColor: "FFF" }} value={messageContent} onChange={messageContentChanged}></TextField>
                            </div>
                            <div className="updatedStatus">
                                <Select
                                    size="small"
                                    value={newStatus}
                                    required
                                    onChange={newStatusChanged}
                                    sx={{ width: "100%" }}
                                >
                                    <MenuItem key='open' id="open" value="open">Open</MenuItem>
                                    <MenuItem key='inprogress' id="inprogress" value="inprogress">In behandeling</MenuItem>
                                    <MenuItem key='closed' id="closed" value="closed">Afgesloten</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="submitBtn">
                            <LightGreenButton
                                type="submit"
                                startIcon={<SaveIcon />}
                                size="small"
                            >Save</LightGreenButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleBatchComments;