import { Avatar, Button, Divider, FormControl, MenuItem, Select, SvgIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarShortCode } from "../../../../utils";
import { selectAllConversations, updateTelegramUserVerifiedStatus } from "../../conversationsSlice";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TelegramIcon from '@mui/icons-material/Telegram';
import "./conversationprofile.scss";
import { useState } from "react";
import { useEffect } from "react";
import { fetchAllUsers, selectAllUsers, selectCurrentUser, updatedSingleUser } from "../../../users/usersSlice";
import WarningIcon from '@mui/icons-material/Warning';
import { LightGreenButton, RedButton } from "../../../../theme/buttons";
import CheckIcon from '@mui/icons-material/Check';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useGetConversationsQuery, useUpdateTelegramUserVerifiedStatusMutation } from "../../rtkConversationsSlice";
import { useGetUsersQuery, useUpdateUserMutation } from "../../../users/rtkUsersSlice";

const ConversationProfile = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(selectCurrentUser);

    //telegram tables
    //const conversations = useSelector(selectAllConversations);
    const { data: conversations } = useGetConversationsQuery();
    const selectedConversation = useSelector(state => state.conversations.currentConversation);
    const thisConversation = conversations?.find((conversation) => {
        return conversation.chatid === selectedConversation
    });
    const userShortCode = thisConversation && getAvatarShortCode(thisConversation.firstname, thisConversation.lastname);

    //general tables
    //const users = useSelector(selectAllUsers);
    const { data: users } = useGetUsersQuery()

    const thisUser = thisConversation && users?.find(user => user.mobilePhone === thisConversation.phonenumber);

    // const [usersStatus, setUsersStatus] = useState("idle")
    // const [telegramUserVerified, setTelegramUserVerified] = useState(false);
    // const [associatedUser, setAssociatedUser] = useState({});

    // useEffect(() => {
    //     if (usersStatus === "idle") {
    //         try {
    //             dispatch(fetchAllUsers(currentUser));
    //         } catch (err) {
    //             console.log(err);
    //         } finally {
    //             setUsersStatus("succeeded");
    //         }
    //     }
    // }, [usersStatus, dispatch]);

    const [updateTelegramUserVerifiedStatus] = useUpdateTelegramUserVerifiedStatusMutation()
    const [updateUser] = useUpdateUserMutation()

    // useEffect(() => {
    //     if (thisConversation) {
    //         setTelegramUserVerified(thisConversation.isverified);
    //     }
    // }, [thisConversation]);

    const handleChange = async (e) => {

        //update is_verified column of table telegram_users
        //setTelegramUserVerified(e.target.value);
        const telegramUserObj = {
            userId: thisConversation.userid,
            verifiedStatus: e.target.value
        }
        //dispatch(updateTelegramUserVerifiedStatus(telegramUserObj));
        await updateTelegramUserVerifiedStatus(telegramUserObj);

        console.log(thisUser);

        //update telegramId column of table users
        const userObject = {
            id: thisUser.id,
            firstName: thisUser.firstName,
            lastName: thisUser.lastName,
            companyId: thisUser.company.id,
            userRole: thisUser.userRole,
            usergroup: thisUser.usergroup?.id,
            email: thisUser.email,
            mobilePhone: thisUser.mobilePhone,
            telegramId: e.target.value === 'true' ? thisConversation.userid : null,
            currentUser
        }

        // dispatch(updatedSingleUser(userObject));
        await updateUser(userObject);

    }

    return (
        <div className="profileContainer">
            <div className="top">
                <div className="title">
                    <p>TELEGRAM DATA</p>
                </div>
                <div className="content">
                    <div className="avatarContainer">
                        <Avatar
                            sizes="large"
                            sx={{
                                fontSize: "20px",
                                height: "50px",
                                width: "50px"
                            }}
                        >{userShortCode}</Avatar>
                    </div>
                    {thisConversation &&
                        <div className="userData">
                            <div className="firstName">
                                <h3>{thisConversation.firstname} {thisConversation.lastname}</h3>
                            </div>
                            <div className="telegramId">
                                <TelegramIcon />
                                <p>{thisConversation.userid}</p>
                            </div>
                            <div className="phoneNumber">
                                <SmartphoneIcon />
                                <p>{thisConversation.phonenumber}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="bottom">
                <div className="title">
                    <p>FLOWER MSG DATA</p>
                </div>
                <div className="content">
                    <div className={`associatedUser ${thisConversation && thisConversation.isverified ? 'isVerified' : 'notVerified'}`}>
                        {thisUser && thisConversation
                            ? <div>
                                <div className="personalDetails">
                                    <p className="subtitle">PERSONAL DETAILS:</p>
                                    <p>{thisUser.firstName} {thisUser.lastName}</p>
                                    <p>{thisUser.userRole}</p>
                                    <p>{thisUser.mobilePhone}</p>
                                    <p>{thisUser.telegramId ? thisUser.telegramId : 'No TelegramID yet'}</p>
                                </div>
                                <div className="companyDetails">
                                    <p className="subtitle">COMPANY DETAILS</p>
                                    <p>{thisUser.company.name}</p>
                                    <p>{thisUser.company.role}</p>
                                    <p>{thisUser.company.admin_number}</p>
                                </div>
                                {!thisConversation.isverified &&
                                    <div className="verifiedStatus">
                                        <div className="iconContainer">
                                            <WarningIcon color="error" fontSize="large" />
                                        </div>
                                        <div className="statusText">
                                            <p>User not verified.</p>
                                            <p>Verify User ?</p>
                                        </div>
                                        <div className="buttonContainer">
                                            <LightGreenButton
                                                onClick={handleChange}
                                                startIcon={<CheckIcon />}
                                                size="small"
                                                value={true}
                                            >Confirm</LightGreenButton>
                                        </div>
                                    </div>
                                }
                                {thisConversation.isverified &&
                                    <div className="verifiedStatus">
                                        <div className="iconContainer">
                                            <HowToRegIcon color="darkGreen" fontSize="large" />
                                        </div>
                                        <div className="statusText">
                                            <p>User verified.</p>
                                            <p>Cancel user?</p>
                                        </div>
                                        <div className="buttonContainer">
                                            <RedButton
                                                onClick={handleChange}
                                                startIcon={<PersonOffIcon />}
                                                size="small"
                                                value={false}
                                            >Cancel</RedButton>
                                        </div>
                                    </div>
                                }
                            </div>
                            : <p>No user found</p>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ConversationProfile;