import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import { baseURL } from "../../app/baseURL";
import ConversationProfile from "./components/conversationprofile/ConversationProfile";

const initialState = {
    //conversations: [],
    //status: "idle",
    //error: null,
    currentConversation: null
}

// export const fetchConversations = createAsyncThunk(
//     "conversations/fetchConversations",
//     async () => {
//         const response = await fetch(`${baseURL}/conversations`, {method: 'GET', redirect: 'follow'});
//         const conversations = await response.json();
//         return conversations;
//     }
// );

// export const sendMessageFromAdmin = createAsyncThunk(
//     "conversations/sendMessageFromAdmin",
//     async (msgObj) => {
//         console.log('from sendmessagefromadmin');
//         //hier message naar backend sturen. backend moet bericht via Telegram afleveren en bericht wegschrijven in DB.
//         const raw = JSON.stringify({
//             "chatId": msgObj.chatId,
//             "text": msgObj.text
//         })
//         const response = await fetch(`${baseURL}/conversations/sendMessage`, {
//             headers: {
//                 "Content-Type": 'application/json'
//              }, 
//              method: 'POST', 
//              body: raw,
//              redirect: 'follow', })

//         const sentMessage = await response.json();
//         console.log(sentMessage)
//     }
// );

// export const updateTelegramUserVerifiedStatus = createAsyncThunk(
//     "conversations/updateTelegramUserVerifiedStatus",
//     async(telegramUserObject) => {
//         const raw = JSON.stringify({
//             userId: telegramUserObject.id,
//             verifiedStatus: telegramUserObject.verifiedStatus
//         });

//         const response = await fetch(`${baseURL}/conversations/updateVerifiedStatus`, {
//             method: 'PUT', 
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: raw,
//             redirect: 'follow'
//         });

//         const data = await response.json();
//         console.log(data);
//         return data;
//     }
// );

// export const addMessageToConversation = createAsyncThunk(
//     "conversations/addMessageToConversation",
//     async(messageDetails) => {
//         console.log(messageDetails);
//         //return messageDetails;
//     }
// );

const conversationsSlice = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        conversationSelected(state, action) {
            state.currentConversation = action.payload;
        }
    },
    // extraReducers(builder) {
    //     builder
    //         .addCase(fetchConversations.fulfilled, (state, action) => {
    //             state.status = "succeeded";
    //             state.conversations = action.payload;
    //         })
    //         .addCase(updateTelegramUserVerifiedStatus.fulfilled, (state,action) => {
    //             state.status = "succeeded";
    //             const existingConversation = state.conversations.find(conversation => conversation.chatid === action.payload.id);
    //             if(existingConversation){
    //                 existingConversation.isverified = action.payload.is_verified;
    //             }
    //         })
    //         .addCase(addMessageToConversation.fulfilled, (state,action) => {
    //             /*
    //             const existingConversation = state.conversations.find((conversation) => {
    //                 return conversation.chatid === action.payload.conversationId;
    //             });
    //             existingConversation.messages.push(action.payload.messageDetails);
    //             */
    //         });
    //     }
});

export const { conversationSelected } = conversationsSlice.actions;

// export const selectAllConversations = state => state.conversations;

export default conversationsSlice.reducer;

