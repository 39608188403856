import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

export const rtkCompaniesSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCompanies: builder.query({
            query: () => '/companies',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Companies', id })), 'Companies']
                    : ['Companies']
            }
        }),
        addCompany: builder.mutation({
            query: (companyObject) => ({
                url: '/companies',
                method: 'POST',
                body: companyObject
            }),
            invalidatesTags: ['Companies']
        }),
        updateCompany: builder.mutation({
            query: (companyObject) => ({
                url: `/companies/${companyObject.id}`,
                method: 'PUT',
                body: companyObject
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'Companies', id: arg.id }]
            }
        })
    })
});

export const {
    useGetCompaniesQuery,
    useAddCompanyMutation,
    useUpdateCompanyMutation
} = rtkCompaniesSlice;