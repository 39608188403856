import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useGetUsergroupsQuery } from "../../../rtkSettingsSlice";
import { dataTableColumns } from "./datatableColumns";
import "./usergroupsdatatable.scss";

const UsergroupsDataTable = () => {
    const navigate = useNavigate();

    const { data: usergroups, isSuccess } = useGetUsergroupsQuery()

    const MyDataTable = styled(DataGrid)({

        '.MuiDataGrid-row': {
            backgroundColor: '#47aa7710',

            '&:hover': {
                backgroundColor: '#E0EFE9',
                cursor: 'pointer',

                '.beforehover': {
                    opacity: '0'
                }
            }
        }
    });

    const rowClicked = (e) => {
        navigate(e.id);
    }

    return (
        <div className="usergroupsDataTableContainer">
            <MyDataTable
                rows={isSuccess ? usergroups : []}
                columns={dataTableColumns.usergroups}
                headerHeight={40}
                onRowClick={rowClicked}
            />
        </div>
    )
}

export default UsergroupsDataTable;