import { useSelector } from "react-redux";
import Sidebar from "../../../../components/sidebar/Sidebar";
import SubSidebarAdvancedFilter from "../../../../components/subsidebar/advancedfilter/SubSidebarAdvancedFilter";
import UsersDatatable from "../../components/datatable/UsersDataTable";
import UsersNavbar from "../../components/navbar/UsersNavbar";
import UsersSubSidebar from "../../components/subsidebar/UsersSubSidebar";
import "./usersmainpage.scss";

const UsersMainPage = () => {

    return (
        <div className="users">
            <Sidebar/>
            <UsersSubSidebar/>
            <div className="usersContainer">
                <UsersNavbar/>
                <div className="usersContainerContent">
                    <UsersDatatable/>
                </div>
            </div>
        </div>

    )
}

export default UsersMainPage;