import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllIntegrations, selectAllIntegrations, selectStatus } from "../../../settingsSlice";
import { dataTableColumns } from "./datatableColumns";
import "./integrationsdatatable.scss";
import { useGetIntegrationsQuery } from "../../../rtkSettingsSlice";

const IntegrationsDataTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const integrations = useSelector(selectAllIntegrations);
    const { data: integrations, isSuccess } = useGetIntegrationsQuery();
    //const status = useSelector(selectStatus);

    // useEffect( () => {
    //     if(status === "idle"){
    //         dispatch(fetchAllIntegrations());
    //     }
    // },[status, dispatch]);

    const MyDataTable = styled(DataGrid)({

        '.MuiDataGrid-row': {
            backgroundColor: '#47aa7710',

            '&:hover': {
                backgroundColor: '#E0EFE9',
                cursor: 'pointer',

                '.beforehover': {
                    opacity: '0'
                }
            }
        }
    });

    const rowClicked = (e) => {
        navigate(e.id);
    }

    return (
        <div className="datatableContainer">
            <MyDataTable
                rows={isSuccess ? integrations : []}
                columns={dataTableColumns.integrations}
                headerHeight={40}
                onRowClick={rowClicked}
            />
        </div>
    )
}

export default IntegrationsDataTable;