import { Button, Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InfoIcon from '@mui/icons-material/Info';

export const dataTableColumns = {
    integrations: [
        /*
        {
            field: 'checkbox',
            headerName: '',
            width: 60,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <Checkbox />
              </Box>
            )
          },
        */
          {
            field: 'id',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >ID</Typography>
              </Box>
            ),
            width: 80,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <p className="company__name">{`${params.row.id}`}</p>
              </Box>
            )
          },
          {
            field: 'description',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >Description</Typography>
              </Box>
            ),
            width: 450,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <p className="company__name">{`${params.row.description}`}</p>
              </Box>
            )
          },
          {
            field: 'integrationType',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >Type</Typography>
              </Box>
            ),
            width: 250,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <p className="company__name">{`${params.row.integrationType}`}</p>
              </Box>
            )
          },
          {
            field: 'actions',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >ACTIONS</Typography>
              </Box>
            ),
            width: 180,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
              <div className="sjalala">
                <div className="beforehover">
                </div>
                <div className="afterhover">
                  <Button sx={{ color: "#000", borderRadius: "3px", padding: "3px 10px" }} className="appie" color="secNormalGreen" size="small" variant="contained" startIcon={<InfoIcon />}>Details</Button>
                </div>
              </div>
            )
          },
    ]
}