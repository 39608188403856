import { createAsyncThunk, createSlice, current, nanoid } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../app/baseURL";

const initialState = {
    users: [],
    status: "idle",
    error: null,
    currentUser: {}
}

export const fetchAllUsers = createAsyncThunk(
    "users/fetchAllUsers",
    async () => {
        try {
            const response = await fetch(`${baseURL}/users`, {
                method: 'GET',
                redirect: 'follow',
            });

            const data = await response.json();
            return data;

        } catch (err) {
            console.log(err);
        }
    }
)

export const addNewUser = createAsyncThunk(
    "users/addNewUser",
    async (userObject) => {
        try {

            const response = await fetch(`${baseURL}/users`, {
                method: 'POST',
                redirect: 'follow',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userObject)
            });

            const data = await response.json();
            return data;
        } catch (err) {
            console.log(err);
        }

    }
);

export const updatedSingleUser = createAsyncThunk(
    "users/updatedSingleUser",
    async (userObject) => {
        try{

            const response = await fetch(`${baseURL}/users/${userObject.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userObject),
                redirect: 'follow'
            });
            
            const data = await response.json();
            console.log(data);
        return data;
        } catch (err) {
            console.log(err);
        }

    }
)

export const logOutCurrentUser = createAsyncThunk(
    "users/logOutCurrentUser",
    async (currentUser, { rejectWithValue }) => {
        console.log("lets log you out");
        console.log(currentUser.refreshToken);
        console.log(currentUser.accessToken);

        try {

            const response = await axios.post(
                `${baseURL}/auth/logout`,
                { token: currentUser.refreshToken },
                {
                    headers: {
                        "Authorization": `Bearer ${currentUser.accessToken}`
                    }
                }
            );

            console.log(response.data);
            return response.data

        } catch (err) {
            console.log("DEALING WITH AN ERROR");
            console.log(err);
            return rejectWithValue(err.response.data.message);
        }

    }
);

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        userAdded(state, action) {
            console.log(current(state));
            state.users.push(action.payload);
        },
        currentUserUpdated(state, action) {
            state.currentUser = { ...action.payload }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllUsers.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAllUsers.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.users = action.payload;
            })
            .addCase(fetchAllUsers.rejected, (state, action) => {
                console.log(current(state));
            })
            .addCase(addNewUser.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.users.push(action.payload);
            })
            .addCase(updatedSingleUser.fulfilled, (state, action) => {
                state.status = "succeeded";
                const { id, firstName, lastName, company, userRole, email, mobilePhone, telegramId } = action.payload;
                const existingUser = state.users.find(user => user.id === id);
                if (existingUser) {
                    existingUser.firstName = firstName;
                    existingUser.lastName = lastName;
                    existingUser.company = company;
                    existingUser.userRole = userRole;
                    existingUser.email = email;
                    existingUser.mobilePhone = mobilePhone;
                    existingUser.telegramId = telegramId;
                }
            })
            .addCase(logOutCurrentUser.pending, (state) => {
                state.status = "pending";
            })
            .addCase(logOutCurrentUser.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
                state.currentUser = {};
            })
            .addCase(logOutCurrentUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.currentUser = {};
            })
    }
});

export const { userAdded, currentUserUpdated } = usersSlice.actions;

export const selectAllUsers = (state) => {
    return state.users.users;
}

export const selectUserById = (state, userId) => {
    return state.users.users.find(user => user.id === userId);
}

export const selectCurrentUser = (state) => state.users.currentUser;

export default usersSlice.reducer;