import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { baseURL } from "../../app/baseURL";
import { formatISO } from "date-fns";

const initialState = {
    infoproducts: [],
    // communicationtasks: [],
    infoProductsStatus: "idle",
    // communicationTasksStatus: "idle",
    communicationTasksData: {
        fromDateFilter: formatISO(new Date()),
        untilDateFilter: formatISO(new Date())
    },
    error: null
}

export const fetchAllInfoproducts = createAsyncThunk(
    "infoproducts/fetchAllInfoproducts",
    async () => {
        const response = await fetch(`${baseURL}/informationproducts`, {
            method: 'GET',
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
);

export const addNewInfoProduct = createAsyncThunk(
    "infoproducts/addNewInfoProduct",
    async (infoProductObject) => {
        const response = await fetch(`${baseURL}/informationproducts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(infoProductObject),
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
)

export const updateSingleInfoProduct = createAsyncThunk(
    "infoproducts/updateSingleInfoProduct",
    async (infoProductObject) => {
        const response = await fetch(`${baseURL}/informationproducts/${infoProductObject.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(infoProductObject),
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
);

export const deleteSingleInfoProduct = createAsyncThunk(
    "infoproducts/deleteSingleInfoProduct",
    async (infoProductId) => {
        const response = await fetch(`${baseURL}/informationproducts/${infoProductId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        });

        if (response.status === 204) {
            return infoProductId;
        }
    }
)

// export const fetchAllCommunicationTasks = createAsyncThunk(
//     "infoproducts/fetchAllCommunicationTasks",
//     async () => {
//         const response = await fetch(`${baseURL}/informationproducts/communicationtasks`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             redirect: 'follow'
//         });

//         const data = await response.json();
//         if (response.status === 200) {
//             return data;
//         } else {
//             return [];
//         }
//     }
// )

const infoproductsSlice = createSlice({
    name: "infoproducts",
    initialState,
    reducers: {
        setFromDateFilter: (state, action) => {
            state.communicationTasksData.fromDateFilter = action.payload.fromDate;
        },
        setUntilDateFilter: (state, action) => {
            state.communicationTasksData.untilDateFilter = action.payload.untilDate;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllInfoproducts.pending, (state, action) => {
                state.infoProductsStatus = "loading";
            })
            .addCase(fetchAllInfoproducts.fulfilled, (state, action) => {
                state.infoProductsStatus = "succeeded";
                state.infoproducts = action.payload;
            })
            .addCase(addNewInfoProduct.pending, (state) => {
                state.infoProductsStatus = "loading";
            })
            .addCase(addNewInfoProduct.fulfilled, (state, action) => {
                state.infoProductsStatus = "succeeded";
                state.infoproducts.push(action.payload);
            })
            .addCase(updateSingleInfoProduct.pending, (state) => {
                state.infoProductsStatus = "loading";
            })
            .addCase(updateSingleInfoProduct.fulfilled, (state, action) => {
                state.infoProductsStatus = "succeeded";
                const filteredInfoProducts = state.infoproducts.filter(infoproduct => infoproduct.id !== action.payload.id);
                state.infoproducts = [...filteredInfoProducts, action.payload];
            })
            .addCase(deleteSingleInfoProduct.pending, (state) => {
                state.infoProductsStatus = "loading";
            })
            .addCase(deleteSingleInfoProduct.fulfilled, (state, action) => {
                state.infoProductsStatus = "succeeded";
                const filteredInfoProducts = state.infoproducts.filter(infoproduct => infoproduct.id !== action.payload);
                state.infoproducts = filteredInfoProducts;
            })
        // .addCase(fetchAllCommunicationTasks.pending, (state) => {
        //     state.communicationTasksStatus = "loading";
        // })
        // .addCase(fetchAllCommunicationTasks.fulfilled, (state, action) => {
        //     state.communicationTasksStatus = "succeeded";
        //     state.communicationtasks = action.payload;
        // })
    }
});

export const {
    setFromDateFilter,
    setUntilDateFilter
} = infoproductsSlice.actions;

export default infoproductsSlice.reducer;

export const selectAllInfoproducts = (state) => state.infoproducts.infoproducts;
export const selectInfoProductById = (state, infoProductId) => {
    return state.infoproducts.infoproducts.find(infoproduct => infoproduct.id === infoProductId);
}

// export const selectAllCommunicationTasks = (state) => state.infoproducts.communicationtasks;
export const selectCommunicationTasksData = (state) => state.infoproducts.communicationTasksData;
