const { useDispatch, useSelector } = require("react-redux");
const { selectBuyerFilter, selectGrowerFilter, selectFromDateFilter, selectUntilDateFilter, selectAllCasesAndFilters, setFilteredCases } = require("../receptionControlSlice");

const useApplyFilter = (filters) => {
    const dispatch = useDispatch();

    let filteredCases = [];
    if (filters.growerFilter && !filters.buyerFilter) {
        console.log('got a growerFilter, but no buyerFilter');
        const tempFilteredCases = filters.allCasesAndFilters?.cases.filter((singleCase) => {
            return singleCase.batchData.order_grower_code === filters.growerFilter;
        });

        filteredCases.push(...tempFilteredCases);

    } else if (!filters.growerFilter && filters.buyerFilter) {
        console.log('got a buyer filter, but no growerfilter');
        const tempFilteredCases = filters.allCasesAndFilters?.cases.filter((singleCase) => {
            return singleCase.batchData.order_buyer_code === filters.buyerFilter;
        });

        filteredCases.push(...tempFilteredCases);
    } else if (filters.growerFilter && filters.buyerFilter) {
        console.log('got both');
        const tempFilteredCases = filters.allCasesAndFilters?.cases.filter((singleCase) => {
            return singleCase.batchData.order_grower_code === filters.growerFilter && singleCase.batchData.order_buyer_code === filters.buyerFilter;
        });

        filteredCases.push(...tempFilteredCases);
    } else {
        filteredCases.push(...filters.allCasesAndFilters?.cases)
    }
    dispatch(setFilteredCases(filteredCases));

    return filteredCases;

}

export default useApplyFilter;