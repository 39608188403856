import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, MenuItem, MenuList, Typography } from "@mui/material";
import TableChartIcon from '@mui/icons-material/TableChart';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import "./settingssubsidebar.scss";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuSelected, selectSelectedMenu } from "../../settingsSlice";

const SettingsSubSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedMenu = useSelector(selectSelectedMenu);

    const handleMenuItemClicked = (e) => {
        dispatch(menuSelected(e.currentTarget.id));
        navigate(`${e.currentTarget.id}`);
    }

    return (
        <div className="subsidebar">
            <MenuList className="menuList" dense>
                <MenuItem className={`listItem datasets ${selectedMenu === "datasets" ? "selected" : ""}`} id="datasets" onClick={handleMenuItemClicked}>
                    <div className="iconBox datasets">
                        <TableChartIcon fontSize="medium" />
                    </div>
                    <div className="settingsTitle">
                        <p className="main">Datasets</p>
                        <p className="sub">The data you can use in the app</p>
                    </div>
                </MenuItem>
                <MenuItem className={`listItem integrations ${selectedMenu === "integrations" ? "selected" : ""}`} id="integrations" onClick={handleMenuItemClicked}>
                    <div className="iconBox integrations">
                        <CloudSyncOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="settingsTitle">
                        <p className="main">Integrations</p>
                        <p className="sub">Where to get your data from</p>
                    </div>
                </MenuItem>
                <MenuItem className={`listItem usergroups ${selectedMenu === "usergroups" ? "selected" : ""}`} id="usergroups" onClick={handleMenuItemClicked}>
                    <div className="iconBox usergroups">
                        <PeopleIcon fontSize="medium" />
                    </div>
                    <div className="settingsTitle">
                        <p className="main">Usergroups</p>
                        <p className="sub">Set user authorizations</p>
                    </div>
                </MenuItem>
                <MenuItem className={`listItem languages ${selectedMenu === "languages" ? "selected" : ""}`} id="languages" onClick={handleMenuItemClicked}>
                    <div className="iconBox languages">
                        <LanguageOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="settingsTitle">
                        <p className="main">Languages</p>
                        <p className="sub">Set the app language</p>
                    </div>
                </MenuItem>
                <MenuItem className={`listItem configuration ${selectedMenu === "configuration" ? "selected" : ""}`} id="configuration" onClick={handleMenuItemClicked}>
                    <div className="iconBox configuration">
                        <PermDataSettingIcon fontSize="medium" />
                    </div>
                    <div className="settingsTitle">
                        <p className="main">Configuration</p>
                        <p className="sub">Configure app settings</p>
                    </div>
                </MenuItem>
            </MenuList>
        </div>
    )
}

export default SettingsSubSidebar;