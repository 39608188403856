import { createTheme} from "@mui/material";
import { blue } from "@mui/material/colors";


const theme = createTheme({
    palette: {
        primary: {
            main: "#007a4c",
            light: "#47aa77",
            dark: "#004d22"
        },
        secondary: {
          main: "#93C11F"
        },
        blue: {
          main: blue[500]
        },
        telegramBlue: {
          main: "#1E96C8"
        },
        whatsappGreen: {
          main: "#1BD741"
        },
        darkGreen: {
          main: "#004d22",
          contrastText: '#FFF'
        },
        secNormalGreen: {
          main: "#93C11F"
        },
        secLightGreen: {
          main: "#C7F457"
        },
        secDarkGreen: {
          main: "#609000"
        },
        lightGreenButton: {
          main: '#c8e6c9'
        },
        whiteBtnDarkGreenText: {
          main: '#FFF',
          contrastText: '#004d22'
        }
    },
    components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "16px",
              //color: "#007a4c",
              //backgroundColor: "white"
              color: '#000000',
              backgroundColor: '#C7F457'
            },
            arrow: {
                fontSize: "16px",
                //color: "#FFF",
                color: "#C7F457"
            }
          }
        }
      }
});

export default theme;