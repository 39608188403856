import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSelectedMenu } from "../../../settingsSlice";
import "./settingscontent.scss";

const SettingsContent = () => {
    const selectedMenu = useSelector(selectSelectedMenu);
    
    return (
        <div className="content">
            Standaard settings content
        </div>
    )
}

export default SettingsContent;