import { Link, Outlet } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { selectRTKCurrentUser, setCredentials } from "../../features/auth/authSlice";
import { useRefreshMutation, useRefreshQuery } from "../../features/auth/authApiSlice";

const PersistLogin = () => {
    // const dispatch = useDispatch();
    // const [isLoading, setIsLoading] = useState(true);
    //const { refresh } = useRefreshToken();
    //const { currentUsertje } = useAuth();


    // useEffect(() => {
    //     let isMounted = true;

    //     const verifyRefreshToken = async () => {
    //         try {
    //             await refresh();
    //         } catch (err) {
    //             console.log(err);
    //         } finally {
    //             isMounted && setIsLoading(false);
    //         }
    //     }

    //     !currentUsertje?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    //     return () => isMounted = false;

    // }, []);

    const currentUser = useSelector(selectRTKCurrentUser);
    const effectRan = useRef(false);

    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()

    useEffect(() => {

        const verifyRefreshToken = async () => {
            console.log('verifying refresh token');
            try {
                const response = await refresh(currentUser?.accessToken);
                setTrueSuccess(true);
            }
            catch (err) {
                console.error(err);
            }
        }

        if (!currentUser) verifyRefreshToken()

    }, []);

    let content;
    if (isLoading) {
        content = <p>Loading...</p>
    } else if (isError) {
        content = (
            <p>
                {error.data?.message}
                <Link to="/login">Please login again</Link>
            </p>
        )
    } else if (isSuccess && trueSuccess) {
        content = <Outlet />
    } else if (currentUser && isUninitialized) {
        content = <Outlet />
    }

    return content
}

export default PersistLogin;