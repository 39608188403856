import { uniqBy } from "lodash";

//functie wordt gebruik in de rtkReceptionControlSlice bij onQueryStarted functie
export const uniqueSelectableGrowers = (cases) => {
    let selectableGrowers = [];
    cases.forEach((singleCase) => {
        const singleGrowerObject = {
            growerCode: singleCase.batchData.order_grower_code,
            growerName: singleCase.batchData.order_grower_name
        }
        selectableGrowers.push(singleGrowerObject);
    });
    const uniqueSelectableGrowers = uniqBy(selectableGrowers, (grower) => grower.growerCode);
    return uniqueSelectableGrowers;
}