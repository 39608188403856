import ReturnBar from "../../../../components/returnbar/ReturnBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import AddCompanyForm from "../../components/addcompanyform/AddCompanyForm";
import "./addnewcompany.scss";

const AddNewCompany = () => {
    return (
        <div className="newCompany">
            <Sidebar/>
            <ReturnBar/>
            <AddCompanyForm/>
        </div>
    )
}

export default AddNewCompany;