import { useDispatch, useSelector } from "react-redux";
import { currentUserUpdated, selectCurrentUser } from "../features/users/usersSlice";

const useAuth = () => {
    //custom hook to get and set currentUser
    const currentUsertje = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    // function to setUser
    const setCurrentUser = (userObject) => {
        dispatch(currentUserUpdated(userObject));
    }

    return { currentUsertje, setCurrentUser }
}

export default useAuth;