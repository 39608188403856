import { apiSlice } from "../api/apiSlice";
import { setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        refresh: builder.mutation({
            query: token => ({
                url: '/auth/refresh_token',
                body: token
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    console.log(data);
                    dispatch(setCredentials(data));
                } catch (err) {
                    console.log(err);
                }
            }
        })
    })
})

export const {
    useLoginMutation,
    useRefreshMutation
} = authApiSlice