import ShareIcon from '@mui/icons-material/Share';
import { TextField } from "@mui/material";
import "./communicationtasksnavbar.scss";
import { DatePicker } from "@mui/x-date-pickers";
import { formatISO, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { selectCommunicationTasks, selectCommunicationTasksData, setFromDateFilter, setUntilDateFilter } from "../../../infoproducts/infoproductsSlice";

const CommunicationTasksNavbar = () => {
    const dispatch = useDispatch();

    const communicationTasksData = useSelector(selectCommunicationTasksData);
    console.log(communicationTasksData);

    const fromDateFilterChanged = (newValue) => {
        dispatch(setFromDateFilter({ fromDate: formatISO(newValue) }));
    }

    const untilDateFilterChanged = (newValue) => {
        dispatch(setUntilDateFilter({ untilDate: formatISO(newValue) }));
    }

    return (
        <div className="communicationtasksnavbar">
            <div className="titleCounter">
                <ShareIcon fontSize="medium" />
                <span>5 Communication Tasks</span>
            </div>
            <div className="fromDatePicker">
                <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    sx={{ width: '150px' }}
                    value={parseISO(communicationTasksData.fromDateFilter)}
                    onChange={(newValue) => fromDateFilterChanged(newValue)}
                />
            </div>
            <div className="untilDatePicker">
                <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    sx={{ width: '150px' }}
                    value={parseISO(communicationTasksData.untilDateFilter)}
                    onChange={(newValue) => untilDateFilterChanged(newValue)}
                />
            </div>
        </div>
    )
}

export default CommunicationTasksNavbar;