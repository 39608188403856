import _ from "lodash";

export const applyFilter = (filterOptions) => {

    const filterOptionsArray = Object.entries(filterOptions);
    const usefulFiltersArray = filterOptionsArray.filter((filterOption) => {
        return filterOption[0] !== 'allCasesAndFilters' && filterOption[0] !== 'filteredCases' && filterOption[1] !== '';
    });

    let matchingCases = [];
    for (let i = 0; i < filterOptions.allCasesAndFilters.cases.length; i++) {
        const singleCase = filterOptions.allCasesAndFilters.cases[i];
        let checkedConditions = [];
        for (let j = 0; j < usefulFiltersArray.length; j++) {
            let condition = usefulFiltersArray[j];
            if (condition[0] === 'currentBuyerFilter') {
                if (singleCase.batchData.order_buyer_code === condition[1]) {
                    checkedConditions.push('match');
                } else {
                    checkedConditions.push('no match');
                }
            }
            if (condition[0] === 'currentGrowerFilter') {
                if (singleCase.batchData.order_grower_code === condition[1]) {
                    checkedConditions.push('match');
                } else {
                    checkedConditions.push('no match');
                }
            }
            if (condition[0] === 'currentEabStatusFilter') {
                let eabStatus;
                if (!singleCase.eabData) {
                    eabStatus = 'no-eab';
                } else if (singleCase.eabData && _.some(singleCase.caseDifferences, { 'comparison_type': 'eabToOrder' })) {
                    eabStatus = 'eab-error';
                } else {
                    eabStatus = 'eab-correct';
                }

                if (!condition[1].length || condition[1].includes(eabStatus)) {
                    checkedConditions.push('match');
                } else {
                    checkedConditions.push('no match');
                }
            }
            if (condition[0] === 'currentReceivementStatusFilter') {
                let totalBatchQty = singleCase.batchData?.order_qty_units * singleCase.batchData?.order_qty_per_unit;
                let totalReceivementQty = singleCase.receivementData?.receivement_qty_units * singleCase.receivementData?.receivement_qty_per_unit;
                let receivedStatus;
                if (!totalReceivementQty) {
                    receivedStatus = 'not-received';
                } else if (totalBatchQty === totalReceivementQty) {
                    receivedStatus = 'completely-received';
                } else if (totalBatchQty > totalReceivementQty) {
                    receivedStatus = 'partly-received';
                };


                if (!condition[1].length || condition[1].includes(receivedStatus)) {
                    checkedConditions.push('match');
                } else {
                    checkedConditions.push('no match');
                }
            }
            if (condition[0] === 'currentCaseStatusFilter') {
                if (!condition[1].length || condition[1].includes(singleCase.caseStatus)) {
                    checkedConditions.push('match');
                } else {
                    checkedConditions.push('no match');
                }
            }
            if (condition[0] === 'currentEabStatusSuperFilter') {
                let eabStatus;
                if (!singleCase.eabData) {
                    eabStatus = 'no-eab';
                } else if (singleCase.eabData && _.some(singleCase.caseDifferences, { 'comparison_type': 'eabToOrder' })) {
                    eabStatus = 'eab-error';
                } else {
                    eabStatus = 'eab-correct';
                }

                if (!condition[1].length || condition[1].includes(eabStatus)) {
                    checkedConditions.push('match');
                } else {
                    checkedConditions.push('no match');
                }
            }
        };


        if (!checkedConditions.includes('no match')) {
            matchingCases.push(singleCase);
        }

    }

    return matchingCases;
}