import { MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LightGreenButton, RedButton } from "../../../../../theme/buttons";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { selectIntegrationById } from "../../../settingsSlice";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import "./singleintegration.scss";
import { useDeleteSingleIntegrationMutation, useGetIntegrationsQuery } from "../../../rtkSettingsSlice";

const SingleIntegration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    //const thisIntegration = useSelector(state => selectIntegrationById(state, params.integrationId));
    const { thisIntegration } = useGetIntegrationsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            thisIntegration: data?.find((integration) => integration.id === params.integrationId)
        })
    })

    const [description, setDescription] = useState(thisIntegration?.description);
    const [integrationType, setIntegrationType] = useState(thisIntegration?.integrationType);
    const [endpoint, setEndpoint] = useState(thisIntegration?.endpoint);
    const [weekdays, setWeekdays] = useState(thisIntegration?.refreshSchedule[0].weekdays);
    const [timeslotFromTimeHours, setTimeslotFromTimeHours] = useState(thisIntegration?.refreshSchedule[0].timeslotFrom.slice(0, 2));
    const [timeslotFromTimeMinutes, setTimeslotFromTimeMinutes] = useState(thisIntegration?.refreshSchedule[0].timeslotFrom.slice(3, 5));
    const [timeslotUntilTimeHours, setTimeslotUntilTimeHours] = useState(thisIntegration?.refreshSchedule[0].timeslotUntil.slice(0, 2));
    const [timeslotUntilTimeMinutes, setTimeslotUntilTimeMinutes] = useState(thisIntegration?.refreshSchedule[0].timeslotUntil.slice(3, 5));
    const [frequencyQuantity, setFrequencyQuantity] = useState(thisIntegration?.refreshSchedule[0].frequencyQuantity);
    const [frequencyUnit, setFrequencyUnit] = useState(thisIntegration?.refreshSchedule[0].frequencyUnit);

    const descriptionChanged = (e) => setDescription(e.target.value);
    const integrationTypeChanged = (e) => setIntegrationType(e.target.value);
    const endpointChanged = (e) => setEndpoint(e.target.value);
    const weekdaysChanged = (e, newWeekDays) => setWeekdays(newWeekDays);
    const timeslotFromTimeHoursChanged = (e) => setTimeslotFromTimeHours(e.target.value);
    const timeslotFromTimeMinutesChanged = (e) => setTimeslotFromTimeMinutes(e.target.value);
    const timeslotUntilTimeHoursChanged = (e) => setTimeslotUntilTimeHours(e.target.value);
    const timeslotUntilTimeMinutesChanged = (e) => setTimeslotUntilTimeMinutes(e.target.value);
    const frequencyQuantityChanged = (e) => setFrequencyQuantity(e.target.value);
    const frequencyUnitChanged = (e) => setFrequencyUnit(e.target.value);

    const [deleteSingleIntegration] = useDeleteSingleIntegrationMutation()

    const formSubmitHandler = (e) => {
        e.preventDefault();

        const updatedIntegrationObject = {
            description,
            integrationType,
            endpoint,
            refreshSchedule: [
                {
                    weekdays,
                    frequencyQuantity,
                    frequencyUnit,
                    timeslotFrom: timeslotFromTimeHours + timeslotFromTimeMinutes,
                    timeslotUntil: timeslotUntilTimeHours + timeslotUntilTimeMinutes,
                    //for now we only work with one refresh cycle per schedule. Later might be changed to have several cycles
                    refreshCycleId: '1'

                }
            ]
        };

        console.log(updatedIntegrationObject);
    }

    const deleteIntegration = async (integrationId) => {
        try {
            await deleteSingleIntegration(integrationId);
        } catch (err) {
            console.log(err);
        } finally {
            navigate("../../integrations");
        }
    }

    return (
        <div className="singleIntegrationForm">
            <div className="singleIntegrationFormContainer">
                <div className="header">
                    <div className="title">
                        <h3>{thisIntegration?.description}</h3>
                    </div>
                    <div className="deleteBtn">
                        <RedButton
                            startIcon={<DeleteForeverIcon />}
                            size="small"
                            onClick={() => deleteIntegration(params.integrationId)}
                        >Delete</RedButton>
                    </div>
                </div>
                <div className="content">
                    <form onSubmit={formSubmitHandler}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Description</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" value={description} onChange={descriptionChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Type</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Select
                                        size="small"
                                        value={integrationType}
                                        onChange={integrationTypeChanged}
                                        sx={{ width: "100%", height: "30px" }}>
                                        <MenuItem value="powerautomate">MS Power Automate REST API</MenuItem>
                                        <MenuItem value="enduserapi" disabled>Match-Online EndUser API (to be developed)</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Endpoint</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" multiline minRows={3} value={endpoint} onChange={endpointChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        {/* //for now we only work with one refresh cycle per schedule. Later might be changed to have several cycles*/}
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Refresh Schedule</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <div className="refresh-schedule">
                                        <div className="weekdays">
                                            <div className="title">
                                                <p>On these days</p>
                                            </div>
                                            <ToggleButtonGroup
                                                value={weekdays}
                                                onChange={weekdaysChanged}
                                                className="weekdaysToggleButtonGroup"
                                            >
                                                <ToggleButton className="weekdayToggleButton" value="monday">M</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="tuesday">T</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="wednesday">W</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="thursday">T</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="friday">F</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="saturday">S</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="sunday">S</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                        <div className="timeslot">
                                            <div className="title">
                                                <p>Between</p>
                                            </div>
                                            <div className="container">
                                                <div className="fromTime">
                                                    <div className="hours">
                                                        <TextField size="small" placeholder="hh" value={timeslotFromTimeHours} onChange={timeslotFromTimeHoursChanged}></TextField>
                                                    </div>
                                                    <div className="label">
                                                        :
                                                    </div>
                                                    <div className="minutes">
                                                        <TextField size="small" placeholder="mm" value={timeslotFromTimeMinutes} onChange={timeslotFromTimeMinutesChanged}></TextField>
                                                    </div>
                                                </div>
                                                <div className="label">
                                                    and
                                                </div>
                                                <div className="untilTime">
                                                    <div className="hours">
                                                        <TextField size="small" placeholder="hh" value={timeslotUntilTimeHours} onChange={timeslotUntilTimeHoursChanged}></TextField>
                                                    </div>
                                                    <div className="label">
                                                        :
                                                    </div>
                                                    <div className="minutes">
                                                        <TextField size="small" placeholder="mm" value={timeslotUntilTimeMinutes} onChange={timeslotUntilTimeMinutesChanged}></TextField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frequency">
                                            <div className="title">
                                                <p>Refresh every</p>
                                            </div>
                                            <div className="container">
                                                <div className="quantity">
                                                    <TextField size="small" type="number" value={frequencyQuantity} onChange={frequencyQuantityChanged}></TextField>
                                                </div>
                                                <div className="unit">
                                                    <Select
                                                        size="small"
                                                        value={frequencyUnit}
                                                        onChange={frequencyUnitChanged}
                                                        sx={{ width: "100%" }}>
                                                        <MenuItem value="minutes">minutes</MenuItem>
                                                        <MenuItem value="hours">hours</MenuItem>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div className="cancelBtn">
                                <RedButton
                                    startIcon={<CancelIcon />}
                                    size="small"
                                    onClick={() => navigate("../")}
                                >Cancel</RedButton>
                            </div>
                            <div className="submitBtn">
                                <LightGreenButton
                                    type="submit"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                >Save</LightGreenButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleIntegration;