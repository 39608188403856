import "./conversationreplybox.scss";
import { Button, InputBase } from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendMessageFromAdmin } from "../../conversationsSlice";
import { useSendMessageFromAdminMutation } from "../../rtkConversationsSlice";

const ConversationReplyBox = (props) => {
    const dispatch = useDispatch();
    const [replyMessage, setReplyMessage] = useState("");

    const replyMessageChanged = e => setReplyMessage(e.target.value);

    const [sendMessageFromAdmin] = useSendMessageFromAdminMutation();

    const sendButtonClicked = async () => {
        console.log('button clicked');
        const msgObject = {
            chatId: props.chatId,
            text: replyMessage
        }
        try {
            //dispatch(sendMessageFromAdmin(msgObject));
            await sendMessageFromAdmin(msgObject);
            setReplyMessage("");
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="conversationReplyBox">
            <div className="inputField">
                <InputBase
                    variant="standard"
                    size="small"
                    multiline
                    fullWidth
                    minRows={2}
                    maxRows={2}
                    placeholder="Send message"
                    value={replyMessage}
                    onChange={replyMessageChanged}
                />
            </div>
            <div className="sendButton">
                <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={sendButtonClicked}
                >Send
                </Button>
            </div>
        </div>
    )
}

export default ConversationReplyBox;