import { createAsyncThunk, createSlice, current, nanoid } from "@reduxjs/toolkit"
import { baseURL } from "../../app/baseURL";

const initialState = {
    companies: [],
    status: "idle",
    error: null
}

export const fetchAllCompanies = createAsyncThunk(
    "companies/fetchAllCompanies",
    async () => {
        //fetch Companies from backend 
        const response = await fetch(`${baseURL}/companies`, {
            method: 'GET',
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
)

export const addNewCompany = createAsyncThunk(
    "companies/addNewCompany",
    async (companyObject) => {

        // volgens mij moet het gedeelte waar je ID creert eruit
        const finalCompanyObject = {
            id: nanoid(),
            ...companyObject
        }

        const response = await fetch(`${baseURL}/companies`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(finalCompanyObject),
            redirect: 'follow'
        })

        const data = await response.json();
        return data;

    }
);

export const updateSingleCompany = createAsyncThunk(
    "companies/updateSingleCompany",
    async (companyObject) => {
        console.log("got dispatched");
        const response = await fetch(`${baseURL}/companies/${companyObject.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(companyObject),
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
)

const companiesSlice = createSlice({
    name: "companies",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(addNewCompany.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.companies.push(action.payload);
            })
            .addCase(fetchAllCompanies.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAllCompanies.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.companies = action.payload;
            })
            .addCase(updateSingleCompany.fulfilled, (state, action) => {
                state.status = "succeeded";
                const { id, name, role, admin_number } = action.payload;
                const existingCompany = state.companies.find(company => company.id === id);
                if (existingCompany) {
                    existingCompany.name = name;
                    existingCompany.role = role;
                    existingCompany.admin_number = admin_number;
                }
            })
    }
});

export default companiesSlice.reducer;

export const selectAllCompanies = (state) => {
    return state.companies.companies;
}

export const selectCompanyById = (state, companyId) => {
    return state.companies.companies.find(company => company.id === companyId);
}