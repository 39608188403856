import { apiSlice } from "../api/apiSlice";
import { uniqueSelectableBuyers } from "./components/filters/buyerfilter/uniqueSelectableBuyers";
import { uniqueSelectableGrowers } from "./components/filters/growerfilter/uniqueSelectableGrowers";
import { applyFilter } from "./pages/main/applyFilter";
import { setAllCasesAndFilters, setCurrentCaseDetails, setFilteredCases, setGrowerFilterOptions } from "./receptionControlSlice";
import _ from "lodash";

export const rtkReceptionControlSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCases: builder.query({
            query: (filterOptions) => {
                const standardURL = `/receivement_control/cases?fromDate=${filterOptions.fromDate}&untilDate=${filterOptions.untilDate}`;
                if (!filterOptions.growerCode) {
                    return standardURL;
                } else {
                    return standardURL + `&growerCode=${filterOptions.growerCode}`;
                }
            },
            transformResponse: (response, meta, arg) => {
                const uniqueGrowers = uniqueSelectableGrowers(response);
                const uniqueBuyers = uniqueSelectableBuyers(response);
                const finalResponse = {
                    cases: response,
                    filters: {
                        growers: uniqueGrowers,
                        buyers: uniqueBuyers
                    }
                }
                return finalResponse;
            },
            async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAllCasesAndFilters(data));
                    const currentState = getState();
                    const filteredCases = applyFilter(currentState.receptioncontrol.filterInformation);
                    dispatch(setFilteredCases(filteredCases))
                } catch (err) {
                    console.log(err);
                }
            },
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.cases.map(({ caseId }) => ({ type: 'Cases' })), 'Cases']
                    : ['Cases']
            },
            keepUnusedDataFor: 5
        }),
        getSingleCaseById: builder.query({
            query: (caseId) => `/receivement_control/cases/${caseId}`,
            providesTags: (result, error, arg) => {
                return ['Cases']
            },
            async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
                try {
                    const { data } = await queryFulfilled;
                    console.log(data);
                    let eabStatus = {
                        dataReceived: false,
                        differences: true,
                        colorCode: 'red',
                        compareTo: 'order'
                    };

                    if (!data.eabData) {
                        eabStatus.dataReceived = false;
                        eabStatus.colorCode = 'red';
                    } else {
                        eabStatus.dataReceived = true;
                        eabStatus.compareTo = 'order';
                        if (_.some(data.caseDifferences, { 'comparison_type': 'eabToOrder' })) {
                            eabStatus.differences = true;
                            eabStatus.colorCode = 'greenAndRed';
                        } else {
                            eabStatus.differences = false;
                            eabStatus.colorCode = 'green';
                        }
                    }


                    let receivedStatus = {
                        dataReceived: false,
                        differences: true,
                        colorCode: 'red',
                        compareTo: ''
                    }
                    if (!data.receivementData) {
                        receivedStatus.dataReceived = false;
                        receivedStatus.colorCode = 'red';
                    } else if (data.receivementData && !data.eabData) {
                        receivedStatus.dataReceived = true;
                        receivedStatus.compareTo = 'order';
                        if (_.some(data.caseDifferences, { 'comparison_type': 'receivementToOrder' })) {
                            receivedStatus.differences = true;
                            receivedStatus.colorCode = 'greenAndRed';
                        } else {
                            receivedStatus.differences = false;
                            receivedStatus.colorCode = 'green';
                        }
                    } else if (data.receivementData && data.eabData) {
                        receivedStatus.dataReceived = true;
                        receivedStatus.compareTo = 'eab';
                        if (_.some(data.caseDifferences, { 'comparison_type': 'receivementToEab' })) {
                            receivedStatus.differences = true;
                            receivedStatus.colorCode = 'greenAndRed';
                        } else {
                            receivedStatus.differences = false;
                            receivedStatus.colorCode = 'green';
                        }
                    };



                    const currentState = getState();
                    const currentCaseDetails = {
                        eabStatus,
                        receivedStatus
                    };
                    dispatch(setCurrentCaseDetails(currentCaseDetails));
                } catch (err) {
                    console.log(err);
                }
            }
        }),
        getBatches: builder.query({
            query: (options) => `/receivement_control/batches?fromDate=${options.fromDate}&untilDate=${options.untilDate}`
        }),
        getCaseComments: builder.query({
            query: (caseId) => `/receivement_control/cases/${caseId}/comments`,
            providesTags: (result, error, arg) => {
                console.log(result);
                return result
                    ? [...result.map(({ id }) => ({ type: 'Casecomments' })), 'Casecomments']
                    : ['Casecomments']
            },
            transformResponse: (response) => {
                const commentsOrderedById = _.sortBy(response, r => r.id);
                return commentsOrderedById;
            }
        }),
        addCaseComment: builder.mutation({
            query: (commentObj) => ({
                url: `/receivement_control/cases/${commentObj.caseId}/comments`,
                method: 'POST',
                body: commentObj
            }),
            invalidatesTags: ['Cases', 'Casecomments']
        }),
        addCases: builder.mutation({
            query: (period) => ({
                url: `receivement_control/cases?fromDate=${period.fromDate}&untilDate=${period.untilDate}`,
                method: 'POST'
            })
        })
    })
});

export const {
    useGetCasesQuery,
    useLazyGetCasesQuery,
    useGetSingleCaseByIdQuery,
    useGetCaseCommentsQuery,
    useAddCaseCommentMutation,
    useGetBatchesQuery,
    useAddCasesMutation
} = rtkReceptionControlSlice;