import { apiSlice } from "../api/apiSlice";

export const rtkSettingsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIntegrations: builder.query({
            query: () => '/integrations',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Integrations', id })), 'Integrations']
                    : ['Integrations']
            }
        }),
        addIntegration: builder.mutation({
            query: (integrationObject) => ({
                url: '/integrations',
                method: 'POST',
                body: integrationObject
            }),
            invalidatesTags: ['Integrations']
        }),
        deleteSingleIntegration: builder.mutation({
            query: (integrationId) => ({
                url: `/integrations/${integrationId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Integrations']
        }),
        getSingleIntegrationDataResults: builder.query({
            query: (integrationId) => `/integrations/${integrationId}/dataresults`,
        }),
        getDatasets: builder.query({
            query: () => '/datasets',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Datasets', id })), 'Datasets']
                    : ['Datasets']
            }
        }),
        addDataset: builder.mutation({
            query: (datasetObject) => ({
                url: '/datasets',
                method: 'POST',
                body: datasetObject
            }),
            invalidatesTags: ['Datasets']
        }),
        deleteSingleDataset: builder.mutation({
            query: (datasetId) => ({
                url: `datasets/${datasetId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Datasets']
        }),
        getDatasetResults: builder.query({
            query: (datasetId) => `/datasets/results/${datasetId}`,
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'DatasetResults', id })), 'DatasetResults']
                    : ['DatasetResults']
            }
        }),
        getUsergroups: builder.query({
            query: () => '/usergroups',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Usergroups' })), 'Usergroups']
                    : ['Usergroups']
            }
        }),
        addUsergroup: builder.mutation({
            query: (usergroupObject) => ({
                url: '/usergroups',
                method: 'POST',
                body: usergroupObject
            }),
            invalidatesTags: ['Usergroups']
        }),
        updateUsergroup: builder.mutation({
            query: (usergroupObject) => ({
                url: `/usergroups/${usergroupObject.id}`,
                method: 'PUT',
                body: usergroupObject
            }),
            invalidatesTags: ['Usergroups']
        }),
        deleteUsergroup: builder.mutation({
            query: (usergroupId) => ({
                url: `/usergroups/${usergroupId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Usergroups']
        })

    })
})

export const {
    useGetIntegrationsQuery,
    useAddIntegrationMutation,
    useDeleteSingleIntegrationMutation,
    useLazyGetSingleIntegrationDataResultsQuery,
    useGetDatasetsQuery,
    useAddDatasetMutation,
    useDeleteSingleDatasetMutation,
    useLazyGetDatasetResultsQuery,
    useGetUsergroupsQuery,
    useAddUsergroupMutation,
    useUpdateUsergroupMutation,
    useDeleteUsergroupMutation
} = rtkSettingsSlice;