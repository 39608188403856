import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllInfoproducts, selectAllInfoproducts } from "../../infoproductsSlice";
import { dataTableColumns } from "./dataTableColumns";
import "./infoproductsdatatable.scss";
import { useGetInfoproductsQuery } from "../../rtkInfoproductsSlice";

const InfoProductsDataTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const infoproducts = useSelector(selectAllInfoproducts);
    const { data: infoproducts, isSuccess } = useGetInfoproductsQuery()
    //const status = useSelector(state => state.infoproducts.infoProductsStatus);
    // console.log(status);

    // useEffect(() => {
    //     if (status === "idle") {
    //         dispatch(fetchAllInfoproducts());
    //     }
    // }, [status, dispatch]);



    const MyDataTable = styled(DataGrid)({

        '.MuiDataGrid-row': {
            backgroundColor: '#47aa7710',

            '&:hover': {
                backgroundColor: '#E0EFE9',
                cursor: 'pointer',

                '.beforehover': {
                    opacity: '0'
                }
            }
        }

    });

    const rowClicked = (e) => {
        navigate(e.id);
    }

    return (
        <div className="datatable">
            <MyDataTable
                rows={isSuccess ? infoproducts : []}
                columns={dataTableColumns.infoproducts}
                headerHeight={40}
                onRowClick={rowClicked}
            />
        </div>
    )
}

export default InfoProductsDataTable;