import { useDispatch, useSelector } from "react-redux";
import "./buyerfilter.scss";
import { selectAllCasesAndFilters, selectBuyerFilter, selectFilterInformation, selectGrowerFilter, setBuyerFilter, setFilterInformation } from "../../../receptionControlSlice";
import { Button, MenuItem, Select } from "@mui/material";
import { applyFilter } from "../../../pages/main/applyFilter";

const BuyerFilter = () => {
    const dispatch = useDispatch();
    const filterInformation = useSelector(selectFilterInformation);

    const currentBuyerFilterChanged = (e) => {
        const newFilterObject = {
            ...filterInformation,
            currentBuyerFilter: e.target.value
        };
        const newFilteredCases = applyFilter(newFilterObject);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
    }

    const resetBtnClicked = () => {
        console.log('reset btn clicked');
        const newFilterObject = {
            ...filterInformation,
            currentBuyerFilter: ''
        };
        const newFilteredCases = applyFilter(newFilterObject);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
    }


    return (
        <div className="buyerFilterContainer">
            <div className="labelContainer">
                <label>Buyer Filter</label>
            </div>
            <div className="inputContainer">

                <Select
                    size="small"
                    value={filterInformation.currentBuyerFilter}
                    defaultValue=''
                    onChange={currentBuyerFilterChanged}
                    displayEmpty
                    sx={{
                        width: "275px",
                        // height: '30px'
                    }}
                >
                    {filterInformation.allCasesAndFilters?.filters?.buyers?.length && filterInformation.allCasesAndFilters.filters.buyers.map((buyer) => {
                        return (
                            <MenuItem key={buyer.buyerCode} value={buyer.buyerCode}>{buyer.buyerCode} - {buyer.buyerName}</MenuItem>
                        )
                    })}

                </Select>
                <span className="resetBtn" onClick={resetBtnClicked}>X</span>
            </div>
        </div>
    )
}

export default BuyerFilter;