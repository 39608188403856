import "./usersnavbar.scss";
import PeopleIcon from '@mui/icons-material/People';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box } from "@mui/system";
import { LightGreenButton, WhiteBtnDarkGreenText, LightGreenButtonAdd, DarkGreenButton } from "../../../../theme/buttons";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { selectAllUsers, userAdded } from "../../usersSlice";

const UsersNavbar = () => {
    const navigate = useNavigate();

    const users = useSelector(selectAllUsers);

    const addButtonClicked = (e) => {
        navigate("/users/new");
    }

    return (
        <div className="navbar">
            <div className="titleCounter">
                <PeopleIcon fontSize="medium" />
                <span>{users.length} Contacts</span>
            </div>
            <div className="searchbox">
                <TextField variant="outlined" size="small" placeholder="Search" margin="none" sx={{ width: '300px' }} />
            </div>
            <div className="buttonGroup">
                <DarkGreenButton
                    onClick={addButtonClicked}
                    startIcon={<AddCircleOutlinedIcon />}
                    size="small"
                >Add User</DarkGreenButton>
            </div>
        </div>
    )
}

export default UsersNavbar;