import { MenuItem, Select, Step, StepLabel, Stepper, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LightGreenButton, RedButton } from "../../../../../theme/buttons";
import { fetchAllIntegrations, selectAllIntegrations, selectDatasetById, selectStatus } from "../../../settingsSlice";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./singledatasetconfig.scss";
import { useGetDatasetsQuery, useGetIntegrationsQuery } from "../../../rtkSettingsSlice";

const SingleDatasetConfig = () => {
    const dispatch = useDispatch();
    const steps = ['Basic data', 'Refresh Schedule', 'Finish'];
    const params = useParams();
    // const thisDataset = useSelector(state => selectDatasetById(state, params.datasetId));
    const { thisDataset } = useGetDatasetsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            thisDataset: data?.find((dataset) => dataset.id === params.datasetId)
        })
    })
    // const integrations = useSelector(selectAllIntegrations);
    const { data: integrations, isSuccess } = useGetIntegrationsQuery();
    const status = useSelector(selectStatus);
    console.log(thisDataset);

    // useEffect(() => {
    //     if (status === "idle") {
    //         dispatch(fetchAllIntegrations());
    //     }
    // }, [status, dispatch]);

    const [activeStep, setActiveStep] = useState(0);
    const [description, setDescription] = useState(thisDataset?.description);
    const [datasetType, setDatasetType] = useState(thisDataset?.datasetType);
    const [tableName, setTableName] = useState(thisDataset?.tableName);
    const [source, setSource] = useState(thisDataset?.source);
    console.log(source);
    //temparary value false
    const [datasetActive, setDatasetActive] = useState(false);
    const [weekdays, setWeekdays] = useState(thisDataset?.refreshSchedule[0].weekdays);
    const [frequencyQuantity, setFrequencyQuantity] = useState(thisDataset?.refreshSchedule[0].frequencyQuantity);
    const [frequencyUnit, setFrequencyUnit] = useState(thisDataset?.refreshSchedule[0].frequencyUnit);
    const [timeslotFromTimeHours, setTimeslotFromTimeHours] = useState(thisDataset?.refreshSchedule[0].timeslotFrom.slice(0, 2));
    const [timeslotFromTimeMinutes, setTimeslotFromTimeMinutes] = useState(thisDataset?.refreshSchedule[0].timeslotFrom.slice(3, 5));
    const [timeslotUntilTimeHours, setTimeslotUntilTimeHours] = useState(thisDataset?.refreshSchedule[0].timeslotUntil.slice(0, 2));
    const [timeslotUntilTimeMinutes, setTimeslotUntilTimeMinutes] = useState(thisDataset?.refreshSchedule[0].timeslotUntil.slice(3, 5));

    const descriptionChanged = (e) => setDescription(e.target.value);
    const datasetTypeChanged = (e) => setDatasetType(e.target.value);
    const tableNameChanged = (e) => setTableName(e.target.value);
    const sourceChanged = (e) => setSource(e.target.value);
    const datasetActiveChanged = (e) => setDatasetActive(e.target.value);
    const weekdaysChanged = (e, newWeekDays) => setWeekdays(newWeekDays);
    const frequencyQuantityChanged = (e) => setFrequencyQuantity(e.target.value);
    const frequencyUnitChanged = (e) => setFrequencyUnit(e.target.value);
    const timeslotFromTimeHoursChanged = (e) => setTimeslotFromTimeHours(e.target.value);
    const timeslotFromTimeMinutesChanged = (e) => setTimeslotFromTimeMinutes(e.target.value);
    const timeslotUntilTimeHoursChanged = (e) => setTimeslotUntilTimeHours(e.target.value);
    const timeslotUntilTimeMinutesChanged = (e) => setTimeslotUntilTimeMinutes(e.target.value);

    const nextBtnHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const previousBtnHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return (
        <div className="singleDataSetConfig">
            <div className="singleDatasetConfigContainer">
                <div className="stepper">
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </div>
                <div className="stepContentContainer">
                    <form className="stepContentForm">
                        {activeStep === 0 &&
                            <div className="stepContentFormFields">
                                <div className="field-input">
                                    <div className="field-input__header">
                                        <label>Description</label>
                                    </div>
                                    <div className="field-input__wrapper">
                                        <div className="field-input__container">
                                            <TextField size="small" variant="outlined" className="field-input__input" value={description} onChange={descriptionChanged} />
                                        </div>
                                    </div>
                                </div>
                                <div className="field-input">
                                    <div className="field-input__header">
                                        <label>Type</label>
                                    </div>
                                    <div className="field-input__wrapper">
                                        <div className="field-input__container">
                                            <Select
                                                size="small"
                                                value={datasetType}
                                                onChange={datasetTypeChanged}
                                                sx={{ width: "100%", height: "30px" }}>
                                                <MenuItem value="table">Table</MenuItem>
                                                <MenuItem value="view" disabled>View (to be developed)</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {datasetType === "table" &&
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Table name</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <TextField size="small" variant="outlined" className="field-input__input" value={tableName} onChange={tableNameChanged}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {isSuccess &&
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Source</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={source}
                                                    onChange={sourceChanged}
                                                    sx={{ width: "100%", height: "30px" }}>
                                                    {integrations.map((integration) => {
                                                        return (
                                                            <MenuItem key={integration.id} id={integration.id} value={integration.id}>{integration.description}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="field-input">
                                    <div className="field-input__header">
                                        <label>Active</label>
                                    </div>
                                    <div className="field-input__wrapper">
                                        <div className="field-input__container">
                                            <Select
                                                size="small"
                                                value={datasetActive}
                                                onChange={datasetActiveChanged}
                                                sx={{ width: "100%", height: "30px" }}>
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeStep === 1 &&
                            <div className="stepContentFormFields">
                                <div className="field-input">
                                    <div className="field-input__header">
                                        <label>Refresh Schedule</label>
                                    </div>
                                    <div className="field-input__wrapper">
                                        <div className="field-input__container">
                                            <div className="refresh-schedule">
                                                <div className="weekdays">
                                                    <div className="title">
                                                        <p>On these days</p>
                                                    </div>
                                                    <ToggleButtonGroup
                                                        value={weekdays}
                                                        onChange={weekdaysChanged}
                                                        className="weekdaysToggleButtonGroup"
                                                    >
                                                        <ToggleButton className="weekdayToggleButton" value="monday">M</ToggleButton>
                                                        <ToggleButton className="weekdayToggleButton" value="tuesday">T</ToggleButton>
                                                        <ToggleButton className="weekdayToggleButton" value="wednesday">W</ToggleButton>
                                                        <ToggleButton className="weekdayToggleButton" value="thursday">T</ToggleButton>
                                                        <ToggleButton className="weekdayToggleButton" value="friday">F</ToggleButton>
                                                        <ToggleButton className="weekdayToggleButton" value="saturday">S</ToggleButton>
                                                        <ToggleButton className="weekdayToggleButton" value="sunday">S</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                                <div className="timeslot">
                                                    <div className="title">
                                                        <p>Between</p>
                                                    </div>
                                                    <div className="container">
                                                        <div className="fromTime">
                                                            <div className="hours">
                                                                <TextField size="small" placeholder="hh" value={timeslotFromTimeHours} onChange={timeslotFromTimeHoursChanged}></TextField>
                                                            </div>
                                                            <div className="label">
                                                                :
                                                            </div>
                                                            <div className="minutes">
                                                                <TextField size="small" placeholder="mm" value={timeslotFromTimeMinutes} onChange={timeslotFromTimeMinutesChanged}></TextField>
                                                            </div>
                                                        </div>
                                                        <div className="label">
                                                            and
                                                        </div>
                                                        <div className="untilTime">
                                                            <div className="hours">
                                                                <TextField size="small" placeholder="hh" value={timeslotUntilTimeHours} onChange={timeslotUntilTimeHoursChanged}></TextField>
                                                            </div>
                                                            <div className="label">
                                                                :
                                                            </div>
                                                            <div className="minutes">
                                                                <TextField size="small" placeholder="mm" value={timeslotUntilTimeMinutes} onChange={timeslotUntilTimeMinutesChanged}></TextField>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="frequency">
                                                    <div className="title">
                                                        <p>Refresh every</p>
                                                    </div>
                                                    <div className="container">
                                                        <div className="quantity">
                                                            <TextField size="small" type="number" value={frequencyQuantity} onChange={frequencyQuantityChanged}></TextField>
                                                        </div>
                                                        <div className="unit">
                                                            <Select
                                                                size="small"
                                                                value={frequencyUnit}
                                                                onChange={frequencyUnitChanged}
                                                                sx={{ width: "100%" }}>
                                                                <MenuItem value="minutes">minutes</MenuItem>
                                                                <MenuItem value="hours">hours</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="stepContentFormButtons">
                            {activeStep !== 0 &&
                                <div className="cancelBtn">
                                    <RedButton
                                        startIcon={<NavigateBeforeIcon />}
                                        size="small"
                                        onClick={previousBtnHandler}
                                    >Previous</RedButton>
                                </div>
                            }
                            {activeStep !== steps.length - 1 &&
                                <div className="nextBtn">
                                    <LightGreenButton
                                        startIcon={<NavigateNextIcon />}
                                        size="small"
                                        onClick={nextBtnHandler}
                                    >Next</LightGreenButton>
                                </div>
                            }
                            {activeStep === steps.length - 1 &&
                                <div className="submitBtn">
                                    <LightGreenButton
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        size="small"
                                    >Save</LightGreenButton>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleDatasetConfig;