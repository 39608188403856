import { useSelector } from "react-redux";
import { selectAllConversations, selectConversationById } from "../../conversationsSlice";
import SingleMessage from "../singlemessage/SingleMessage";
import "./conversationmessages.scss";
import ConversationReplyBox from "../conversationreplybox/ConversationReplyBox";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useGetConversationsQuery } from "../../rtkConversationsSlice";
import { Button, Tab, Tabs } from "@mui/material";
import ConversationQuickActions from "../conversationquickactions/ConversationQuickActions";

const ConversationMessages = () => {
    //const conversations = useSelector(selectAllConversations);
    const { data: conversations, isSuccess } = useGetConversationsQuery();
    const selectedConversation = useSelector(state => state.conversations.currentConversation);
    const thisConversation = conversations?.find((conversation) => {
        return conversation.chatid === selectedConversation;
    });
    const scrollRef = useRef();

    const [tabValue, setTabValue] = useState(0);
    console.log(tabValue);

    const tabValueChanged = (event, newValue) => setTabValue(newValue);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [thisConversation])



    return (
        <div className="conversationMessagesWrapper">
            <div className="conversationMessages">
                {thisConversation && thisConversation.messages.map((message) => {
                    return (
                        <div
                            ref={scrollRef}
                            key={message.id}
                            className={message.chat_id === message.sender_id ? 'userMessage' : 'botMessage'}
                        >

                            <SingleMessage
                                key={message.id}
                                message={message}
                            />
                        </div>
                    )
                })}
            </div>
            <div className="conversationActions">
                <div className="conversationTabs">
                    <Tabs value={tabValue} onChange={tabValueChanged}>
                        <Tab label="Message" value={0} />
                        <Tab label="Quick Actions" value={1} />
                    </Tabs>
                </div>
                {thisConversation && tabValue === 0 &&
                    <ConversationReplyBox
                        chatId={thisConversation.chatid}
                    />
                }
                {thisConversation && tabValue === 1 &&
                    <ConversationQuickActions
                        chatId={thisConversation.chatid}
                    />
                }
            </div>
        </div>
    )
}

export default ConversationMessages;