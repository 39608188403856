import { rgbToHex, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

export const WhiteBtnDarkGreenText = styled(Button)({
    backgroundColor: 'white',
    color: '#004d22',
    border: '1px solid',
    borderRadius: '3px',
    boxShadow: 'none',
    padding: "7px 15px",
    fontFamily: 'Raleway',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#004d22',
        color: 'white',
        border: '1px solid',
        borderRadius: '3px',
        boxShadow: 'none',
        padding: "7px 15px",
        fontFamily: 'Raleway',
        textTransform: 'capitalize',
        fontWeight: 'bold',
    }
});

export const LightGreenButton = styled(Button)({
    backgroundColor: '#93C11F',
    color: '#000',
    border: '1px solid',
    borderColor: '#93C11F',
    borderRadius: '3px',
    boxShadow: 'none',
    padding: "7px 15px",
    fontFamily: 'Raleway',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#93C11F',
        color: '#000',
        border: '1px solid',
        borderColor: '#93C11F',
        borderRadius: '3px',
        boxShadow: 'none',
        padding: "7px 15px",
        fontFamily: 'Raleway',
        textTransform: 'capitalize',
        fontWeight: 'bold',
    }
});

export const DarkGreenButton = styled(Button)({
    backgroundColor: '#004d22',
    color: '#FFF',
    border: '1px solid',
    borderColor: '#004d22',
    borderRadius: '3px',
    boxShadow: 'none',
    padding: "7px 15px",
    fontFamily: 'Raleway',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#004d22',
        color: '#FFF',
        border: '1px solid',
        borderColor: '#004d22',
        borderRadius: '3px',
        boxShadow: 'none',
        padding: "7px 15px",
        fontFamily: 'Raleway',
        textTransform: 'capitalize',
        fontWeight: 'bold',
    }
});

export const RedButton = styled(Button)({
    backgroundColor: '#960606',
    color: '#FFF',
    border: '1px solid',
    borderColor: '#960606',
    borderRadius: '3px',
    boxShadow: 'none',
    padding: "7px 15px",
    fontFamily: 'Raleway',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#960606',
        color: '#FFFFFF',
        border: '1px solid',
        borderColor: '#960606',
        borderRadius: '3px',
        boxShadow: 'none',
        padding: "7px 15px",
        fontFamily: 'Raleway',
        textTransform: 'capitalize',
        fontWeight: 'bold',
    }
});