import { MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LightGreenButton } from "../../../../theme/buttons";
import SaveIcon from '@mui/icons-material/Save';
import { selectAllCompanies, selectCompanyById, updateSingleCompany } from "../../companiesSlice";
import { useGetCompaniesQuery, useUpdateCompanyMutation } from "../../rtkCompaniesSlice";
import "./singlecompanyform.scss";

const SingleCompanyForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { thisCompany } = useGetCompaniesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            thisCompany: data?.find((company) => company.id === params.companyId)
        })
    });
    console.log(thisCompany);
    // const thisCompany = useSelector(state => selectCompanyById(state, params.companyId));

    const [updateCompany, { isSuccess }] = useUpdateCompanyMutation();

    const [companyName, setCompanyName] = useState(thisCompany?.name);
    const [adminNumber, setAdminNumber] = useState(thisCompany?.admin_number);
    const [companyRole, setCompanyRole] = useState(thisCompany?.role);

    const companyNameChanged = (e) => setCompanyName(e.target.value);
    const adminNumberChanged = (e) => setAdminNumber(e.target.value);
    const companyRoleChanged = (e) => setCompanyRole(e.target.value);

    const saveButtonClicked = async (e) => {
        e.preventDefault();
        const editedCompanyObject = {
            id: thisCompany.id,
            name: companyName,
            role: companyRole,
            adminNumber: adminNumber
        };

        try {
            // dispatch(updateSingleCompany(editedCompanyObject));
            await updateCompany(editedCompanyObject);
        } catch (err) {
            console.log(err);
        } finally {
            navigate(-1);
        }
    }

    return (
        <div className="singleCompanyForm">
            <div className="singleCompanyFormContainer">
                <div className="header">
                    <h3>{params.companyId}</h3>
                </div>
                <div className="content">
                    <form onSubmit={saveButtonClicked}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Company Name:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" value={companyName} onChange={companyNameChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Admin. Nr.:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" value={adminNumber} onChange={adminNumberChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Company Role:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Select
                                        size="small"
                                        value={companyRole}
                                        onChange={companyRoleChanged}
                                        sx={{ width: "100%", height: "30px" }}>
                                        <MenuItem value="Agent">Agent</MenuItem>
                                        <MenuItem value="Supplier">Supplier</MenuItem>
                                        <MenuItem value="Buyer">Buyer</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="submitBtn">
                            <LightGreenButton
                                type="submit"
                                startIcon={<SaveIcon />}
                                size="small"
                            >Save</LightGreenButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleCompanyForm;