import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import { userAdded } from "./usersSlice";

export const rtkUsersSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => '/users',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Users', id })), 'Users']
                    : ['Users']
            }
        }),
        addUser: builder.mutation({
            query: (userObject) => ({
                url: '/users',
                method: 'POST',
                body: userObject
            }),
            invalidatesTags: ['Users']
        }),
        updateUser: builder.mutation({
            query: (userObject) => ({
                url: `/users/${userObject.id}`,
                method: 'PUT',
                body: userObject
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'Users', id: arg.id }]
            }
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Users', 'Conversations']
        })
    })
})

export const {
    useGetUsersQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation
} = rtkUsersSlice;



