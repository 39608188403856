import { Avatar, Box, Button, Checkbox, styled, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const dataTableColumns = {
    users: [
        {
            field: 'checkbox',
            headerName: '',
            width: 60,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <Checkbox />
              </Box>
            )
          },
          {
            field: 'avatar',
            headerName: '',
            width: 60,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <Avatar
                  sx={{ fontSize: "12px" }}
                >{params.row.shortCode}</Avatar>
              </Box>
            )
          },
          {
            field: 'fullName',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >FULL NAME</Typography>
              </Box>
            ),
            width: 300,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box
                sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <p className="fullName">{`${params.row.firstName} ${params.row.lastName}`}</p>
                <p>{params.row.email}</p>
              </Box>
            )
          },
          {
            field: 'phone',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >PHONE</Typography>
              </Box>
            ),
            width: 200,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <Box className="user__phone">
                  <span>{params.row.phone}</span>
                </Box>
                <Box className="user__apps">
                  {params.row.telegramId &&
                  <TelegramIcon color='telegramBlue'/>
                  }
                  {params.row.whatsappId &&
                  <WhatsAppIcon color="whatsappGreen"/>
                  }
                </Box>
              </Box>
            )
          },
          {
            field: 'location',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >LOCATION</Typography>
              </Box>
            ),
            width: 200,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box className="company__location">
                <p className="company__city">{`${params.row.companyCity}`}</p>
                <div className="company__country">
                  {params.row.companyCountry === "The Netherlands" &&
                    <Avatar
                      sx={{ height: 20, width: 20 }}
                      src={`https://en.unesco.org/creativity/sites/creativity/files/country/flags/netherlands.png`}
                    >NL</Avatar>
                  }
                  {params.row.companyCountry === "Germany" &&
                    <Avatar
                      sx={{ height: 20, width: 20 }}
                      src={`https://en.unesco.org/creativity/sites/creativity/files/country/flags/germany.png`}
                    >NL</Avatar>
                  }
                  {params.row.companyCountry === "Spain" &&
                    <Avatar
                      sx={{ height: 20, width: 20 }}
                      src={`https://en.unesco.org/creativity/sites/creativity/files/country/flags/spain.png`}
                    >NL</Avatar>
                  }
                  <span>{params.row.companyCountry}</span>
                </div>
              </Box>
            )
          },
          {
            field: 'company',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >COMPANY</Typography>
              </Box>
            ),
            width: 250,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Box>
                <p className="company__name">{`${params.row.companyName}`}</p>
              </Box>
            )
          },
          {
            field: 'active',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >ACTIVE</Typography>
              </Box>
            ),
            width: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
              <Box>
                {params.row.userActive 
                ? <p className="user__active">Active</p>
                : <p className="user__notactive">Not Active</p>
                }
              </Box>
            )
          },
          {
            field: 'role',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >ROLE</Typography>
              </Box>
            ),
            width: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
              <Box className="company__role">
                {params.row.companyRole === "Agent" ? <p className="company__role__agent">Agent</p> 
                : params.row.companyRole === "Seller" ? <p className="company__role__seller">Seller</p> 
                : params.row.companyRole === "Buyer" ? <p className="company__role__buyer">Buyer</p> 
                : <p className="company__role__unknown">Unknown</p>
                }
              </Box>
            )
          },
          {
            field: 'userType',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >USER TYPE</Typography>
              </Box>
            ),
            width: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
              <Box className="user__type">
                {params.row.userType === "SuperUser" ? <p className="user__type__superuser">SuperUser</p> 
                : params.row.userType === "KeyUser" ? <p className="user__type__keyuser">KeyUser</p> 
                : params.row.userType === "EndUser" ? <p className="user__type__enduser">EndUser</p> 
                : <p className="user__type__unknown">Unknown</p>
                }
              </Box>
            )
          },
          {
            field: 'actions',
            renderHeader: (params) => (
              <Box
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >ACTIONS</Typography>
              </Box>
            ),
            width: 180,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
              <div className="sjalala">
                <div className="beforehover">
                </div>
                <div className="afterhover">
                  <Button sx={{ color: "#000", borderRadius: "3px", padding: "3px 10px" }} className="appie" color="secNormalGreen" size="small" variant="contained" startIcon={<InfoIcon />}>Details</Button>
                </div>
              </div>
            )
          },
    ],
    companies: [
      {
        field: 'checkbox',
        headerName: '',
        width: 60,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <Checkbox />
          </Box>
        )
      },
      {
        field: 'id',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >ID</Typography>
          </Box>
        ),
        width: 250,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="company__name">{`${params.row.id}`}</p>
          </Box>
        )
      },
      {
        field: 'adminnr',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Admin Nr</Typography>
          </Box>
        ),
        width: 250,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="company__name">{`${params.row.admin_number}`}</p>
          </Box>
        )
      },
      {
        field: 'name',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >COMPANY NAME</Typography>
          </Box>
        ),
        width: 250,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="company__name">{`${params.row.name}`}</p>
          </Box>
        )
      },
      {
        field: 'role',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >ROLE</Typography>
          </Box>
        ),
        width: 100,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <Box className="company__role">
            {params.row.role === "Agent" ? <p className="company__role__agent">Agent</p> 
            : params.row.role === "Supplier" ? <p className="company__role__seller">Supplier</p> 
            : params.row.role === "Buyer" ? <p className="company__role__buyer">Buyer</p> 
            : <p className="company__role__unknown">Unknown</p>
            }
          </Box>
        )
      },
      {
        field: 'actions',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >ACTIONS</Typography>
          </Box>
        ),
        width: 180,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <div className="sjalala">
            <div className="beforehover">
            </div>
            <div className="afterhover">
              <Button sx={{ color: "#000", borderRadius: "3px", padding: "3px 10px" }} className="appie" color="secNormalGreen" size="small" variant="contained" startIcon={<InfoIcon />}>Details</Button>
            </div>
          </div>
        )
      }
    ],
    connectors: [

    ]
}