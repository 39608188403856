import { useDispatch, useSelector } from "react-redux";
import "./growerfilter.scss";
import { selectAllCasesAndFilters, selectFilterInformation, selectGrowerFilter, setFilterInformation, setGrowerFilter } from "../../../receptionControlSlice";
import { Button, MenuItem, Select } from "@mui/material";
import { applyFilter } from "../../../pages/main/applyFilter";

const GrowerFilter = () => {
    const dispatch = useDispatch();
    const filterInformation = useSelector(selectFilterInformation);

    const currentGrowerFilterChanged = (e) => {
        const newFilterObject = {
            ...filterInformation,
            currentGrowerFilter: e.target.value
        };
        console.log(newFilterObject);
        const newFilteredCases = applyFilter(newFilterObject);
        console.log(newFilteredCases);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
        // dispatch(setGrowerFilter(e.target.value));
    }

    const resetBtnClicked = () => {
        console.log('reset btn clicked');
        const newFilterObject = {
            ...filterInformation,
            currentGrowerFilter: ''
        };
        const newFilteredCases = applyFilter(newFilterObject);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
        // dispatch(setGrowerFilter(''));
    }


    return (
        <div className="growerFilterContainer">
            <div className="labelContainer">
                <label>Grower Filter</label>
            </div>
            <div className="inputContainer">
                <Select
                    size="small"
                    value={filterInformation.currentGrowerFilter}
                    defaultValue=''
                    onChange={currentGrowerFilterChanged}
                    displayEmpty
                    sx={{
                        width: "275px",
                        // height: "30px"
                    }}
                >
                    {filterInformation.allCasesAndFilters?.filters?.growers?.length && filterInformation.allCasesAndFilters.filters.growers.map((grower) => {
                        return (
                            <MenuItem key={grower.growerCode} value={grower.growerCode}>{grower.growerName}</MenuItem>
                        )
                    })}

                </Select>
                <span className="resetBtn" onClick={resetBtnClicked}>X</span>
            </div>
        </div>
    )
}

export default GrowerFilter;