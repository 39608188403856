import { ThemeProvider } from "@mui/material";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import UsersMainPage from "./features/users/pages/main/UsersMainPage";
import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Login from "./pages/login/Login";
import SingleItem from "./pages/singleItem/SingleItem";
import theme from "./theme/theme";
import { store } from "./app/store";
import { Provider } from "react-redux";
import ConversationsMainPage from "./features/conversations/pages/main/ConversationsMainPage";
import CompaniesMainPage from "./features/companies/pages/main/CompaniesMainPage";
import AddNewCompany from "./features/companies/pages/new/AddNewCompany";
import SingleCompany from "./features/companies/pages/single/SingleCompany";
import AddNewUser from "./features/users/pages/new/AddNewUser";
import SingleUser from "./features/users/pages/single/SingleUser";
import RequireAuth from "./features/auth/RequireAuth";
import Layout from "./components/layout/Layout";
import PersistLogin from "./components/persistlogin/PersisLogin";
import Unauthorized from "./pages/unauthorized/Unauthorized";
import SettingsMainPage from "./features/settings/main/pages/SettingsMainPage";
import AddNewIntegration from "./features/settings/integrations/pages/new/AddNewIntegration";
import SingleIntegration from "./features/settings/integrations/pages/single/SingleIntegration";
import DatasetsMainPage from "./features/settings/datasets/pages/main/DatasetsMainPage";
import IntegrationsMainPage from "./features/settings/integrations/pages/main/IntegrationsMainPage";
import LanguagesMainPage from "./features/settings/languages/pages/main/LanguagesMainPage";
import AddNewDataset from "./features/settings/datasets/pages/new/AddNewDataset";
import SingleDataset from "./features/settings/datasets/pages/single/SingleDataset";
import InfoProductsMainPage from "./features/infoproducts/pages/main/InfoProductsMainPage";
import AddNewInfoProduct from "./features/infoproducts/pages/new/AddNewInfoProduct";
import SingleInfoProduct from "./features/infoproducts/pages/single/SingleInfoProduct";
import CommunicationTasksMainPage from "./features/communicationtasks/pages/main/CommunicationTasksMainPage";
import UsergroupsMainPage from "./features/settings/usergroups/pages/main/UsergroupsMainPage";
import AddNewUsergroup from "./features/settings/usergroups/pages/new/AddNewUsergroup";
import SingleUsergroup from "./features/settings/usergroups/pages/single/SingleUsergroup";
import ReceptionControlMainPage from "./features/receptioncontrol/pages/main/ReceptionControlMainPage";
import ReceptionControlSingleBatch from "./features/receptioncontrol/pages/single/ReceptionControlSingleBatch";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { nl } from "date-fns/locale";

function App() {

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
            <BrowserRouter>
              <Routes>

                <Route path="/" element={<Layout />}>
                  {/* public routes */}
                  <Route element={<Login />}></Route>
                  <Route path="login" element={<Login />}></Route>
                  <Route path="unauthorized" element={<Unauthorized />}></Route>
                  {/* WEBSHOP */}

                  {/* protected routes */}
                  <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowedRoles={['superuser', 'agent']} />}>
                      <Route index element={<Home />}></Route>
                      <Route path="conversations" element={<ConversationsMainPage />}></Route>
                      {/* USERS */}
                      <Route path="users">
                        <Route index element={<UsersMainPage />}></Route>
                        <Route path="new" element={<AddNewUser />}></Route>
                        <Route path=":userId" element={<SingleUser />}></Route>
                      </Route>

                      {/* COMPANIES */}
                      <Route path="companies">
                        <Route index element={<CompaniesMainPage />}></Route>
                        <Route path="new" element={<AddNewCompany />}></Route>
                        <Route path=":companyId" element={<SingleCompany />}></Route>
                      </Route>

                      {/* INFOPRODUCTS */}
                      <Route path="infoproducts">
                        <Route index element={<InfoProductsMainPage />}></Route>
                        <Route path="new" element={<AddNewInfoProduct />}></Route>
                        <Route path=":infoproductId" element={<SingleInfoProduct />}></Route>
                      </Route>

                      {/* COMMUNICATION TASKS */}
                      <Route path="communicationtasks">
                        <Route index element={<CommunicationTasksMainPage />}></Route>
                      </Route>

                    </Route>

                    {/* RECEPTION CONTROL */}
                    <Route element={
                      <RequireAuth
                        allowedRoles={['superuser', 'agent']}
                        allowedMenu={'rec_ctrl'}
                      />}>
                      <Route path="receptioncontrol">
                        <Route index element={<ReceptionControlMainPage />}></Route>
                        <Route path=":caseId" element={<ReceptionControlSingleBatch />}></Route>
                      </Route>
                    </Route>


                    <Route element={<RequireAuth allowedRoles={['superuser']} />}>
                      {/* SETTINGS */}
                      <Route path="settings" element={<SettingsMainPage />}>
                        <Route path="datasets">
                          <Route index element={<DatasetsMainPage />}></Route>
                          <Route path="new" element={<AddNewDataset />}></Route>
                          <Route path=":datasetId" element={<SingleDataset />}></Route>
                        </Route>
                        <Route path="integrations">
                          <Route index element={<IntegrationsMainPage />}></Route>
                          <Route path="new" element={<AddNewIntegration />}></Route>
                          <Route path=":integrationId" element={<SingleIntegration />}></Route>
                        </Route>
                        <Route path="usergroups">
                          <Route index element={<UsergroupsMainPage />}></Route>
                          <Route path="new" element={<AddNewUsergroup />}></Route>
                          <Route path=":usergroupId" element={<SingleUsergroup />}></Route>
                        </Route>
                        <Route path="languages">
                          <Route index element={<LanguagesMainPage />}></Route>
                        </Route>
                        <Route path="configuration">
                          <Route index element={<UsergroupsMainPage />}></Route>
                          <Route path="new" element={<AddNewUsergroup />}></Route>
                          <Route path=":sectionId" element={<SingleUsergroup />}></Route>
                        </Route>
                      </Route>



                    </Route>
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
