import "./usersdatatable.scss";
import { styled } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { dataTableColumns } from "./dataTableColumns";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../usersSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGetUsersQuery } from "../../rtkUsersSlice";

const UsersDatatable = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  //const users = useSelector(selectAllUsers);

  const {
    data: users,
    isSuccess
  } = useGetUsersQuery();


  // const status = useSelector(state => state.users.status);


  // useEffect( () => {
  //   if(status === "idle"){
  //     dispatch(fetchAllUsers());
  //   }
  // },[status, dispatch]);

  const rowClicked = (e) => {
    navigate(e.id);
  }

  const MyDataTable = styled(DataGrid)({

    '.MuiDataGrid-row': {
      backgroundColor: '#47aa7710',

      '&:hover': {
        backgroundColor: '#E0EFE9',
        cursor: 'pointer',

        '.beforehover': {
          opacity: '0'
        }
      }
    }

  });


  return (
    <div className="datatable">
      <MyDataTable
        rows={isSuccess ? users : []}
        columns={dataTableColumns.users}
        headerHeight={40}
        onRowClick={rowClicked}
      />
    </div>
  );

}

export default UsersDatatable;