import { useDispatch, useSelector } from "react-redux";
import "./receivementstatusfilter.scss";
import { selectFilterInformation, setFilterInformation, setReceivementStatusFilter } from "../../../receptionControlSlice";
import { Box, Checkbox, Menu, MenuItem, OutlinedInput, Select } from "@mui/material";
import { applyFilter } from "../../../pages/main/applyFilter";

const ReceivementStatusFilter = () => {
    const dispatch = useDispatch();
    const filterInformation = useSelector(selectFilterInformation);

    const receivementStatusFilterOptions = [
        {
            fieldValue: 'not-received',
            fieldDescription: 'Not Received',
            fieldColor: 'orange'
        },
        {
            fieldValue: 'partly-received',
            fieldDescription: 'Partly Received',
            fieldColor: 'rgba(255, 0, 0, 0.618)'
        },
        {
            fieldValue: 'completely-received',
            fieldDescription: 'Completely Received',
            fieldColor: 'lightgreen'
        }
    ]

    const currentReceivementStatusFilterChanged = (e) => {
        console.log(e.target.value);
        dispatch(setReceivementStatusFilter(e.target.value));
        const newFilterObject = {
            ...filterInformation,
            currentReceivementStatusFilter: e.target.value
        };
        const newFilteredCases = applyFilter(newFilterObject);
        console.log(newFilteredCases);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
    }

    const resetBtnClicked = () => {
        const newFilterObject = {
            ...filterInformation,
            currentReceivementStatusFilter: []
        };
        const newFilteredCases = applyFilter(newFilterObject);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
    }

    return (
        <div className="receivementStatusFilterContainer">
            <div className="labelContainer">
                <label>Receivement Status Filter</label>
            </div>
            <div className="inputContainer">
                <Select
                    size="small"
                    value={filterInformation.currentReceivementStatusFilter}
                    onChange={currentReceivementStatusFilterChanged}
                    sx={{
                        width: '275px',
                        // height: '30px'
                    }}
                    multiple
                    input={<OutlinedInput label="tag" />}
                    renderValue={(selected) => {
                        let descriptionArray = [];
                        selected.forEach((item) => {
                            const matchingDescription = receivementStatusFilterOptions.find((option) => {
                                return option.fieldValue === item;
                            });
                            descriptionArray.push(matchingDescription.fieldDescription);
                        });
                        return descriptionArray.join(', ');
                    }}
                >
                    {receivementStatusFilterOptions.map((option) => {
                        return (
                            <MenuItem key={option.fieldValue} value={option.fieldValue}>
                                <Checkbox checked={filterInformation.currentReceivementStatusFilter.indexOf(option.fieldValue) > -1} />
                                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                    <Box sx={{ width: '15px', height: '15px', backgroundColor: option.fieldColor, borderRadius: '50px' }}></Box>
                                    <div>
                                        <label>{option.fieldDescription}</label>
                                    </div>
                                </Box>
                            </MenuItem>
                        )
                    })}
                    {/* <MenuItem value="not-received">
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box sx={{ width: '15px', height: '15px', backgroundColor: 'orange', borderRadius: '50px' }}></Box>
                            <div>
                                <label>Not Received</label>
                            </div>
                        </Box>
                    </MenuItem>
                    <MenuItem value="partly-received">
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box sx={{ width: '15px', height: '15px', backgroundColor: 'rgba(255, 0, 0, 0.618)', borderRadius: '50px' }}></Box>
                            <div>
                                <label>Partly Received</label>
                            </div>
                        </Box>
                    </MenuItem>
                    <MenuItem value="completely-received">
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box sx={{ width: '15px', height: '15px', backgroundColor: 'lightgreen', borderRadius: '50px' }}></Box>
                            <div>
                                <label>Completely Received</label>
                            </div>
                        </Box>
                    </MenuItem> */}
                </Select>
                <span className="resetBtn" onClick={resetBtnClicked}>X</span>
            </div>
        </div>
    )
}

export default ReceivementStatusFilter;