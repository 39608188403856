import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../../app/baseURL";

const initialState = {
    selectedMenu: "",
    integrations: [],
    datasets: [],
    status: "idle",
    error: null
};

export const addNewIntegration = createAsyncThunk(
    "settings/addNewIntegration",
    async (integrationObject) => {
        const response = await fetch(`${baseURL}/integrations`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(integrationObject),
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
);

export const fetchAllIntegrations = createAsyncThunk(
    "settings/fetchAllIntegrations",
    async () => {
        const response = await fetch(`${baseURL}/integrations`, {
            method: "GET",
            headers: {
                "Content-Type": "appliation/json"
            },
            redirect: "follow"
        });

        const data = await response.json();
        return data;
    }
);

export const updateSingleIntegration = createAsyncThunk(
    "settings/updateSingleIntegration",
    async (integrationObject) => {
        console.log(integrationObject);
    }
)

export const addNewDataset = createAsyncThunk(
    "settings/addNewDataset",
    async (datasetObject) => {
        const response = await fetch(`${baseURL}/datasets`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datasetObject),
            redirect: 'follow'
        });

        const data = await response.json();
        return data;
    }
);

export const fetchAllDatasets = createAsyncThunk(
    "settings/fetchAllDatasets",
    async () => {
        const response = await fetch(`${baseURL}/datasets`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        });

        const data = await response.json();
        return data;
    }
);

export const fetchDatasetResults = createAsyncThunk(
    "settings/fetchDatasetResults",
    async (datasetId) => {
        console.log(datasetId);
        const response = await fetch(`${baseURL}/datasets/results/${datasetId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        });

        const data = await response.json();
        console.log(data);
        const myObject = {
            dataset: datasetId,
            results: data
        }

        return myObject;


        
    }
);

export const deleteSingleDataset = createAsyncThunk(
    "settings/deleteSingleDataset",
    async (datasetId) => {
        const response = await fetch(`${baseURL}/datasets/${datasetId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        });

        if(response.status === 204){
            return datasetId;
        }
    }
)

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        menuSelected (state,action) {
            state.selectedMenu = action.payload;
        }
    },
    extraReducers (builder) {
        builder
            .addCase(addNewIntegration.fulfilled, (state,action) => {
                state.integrations.push(action.payload);
            })
            .addCase(fetchAllIntegrations.fulfilled, (state,action) => {
                state.integrations = action.payload;
            })
            .addCase(addNewDataset, (state, action) => {
                state.datasets.push(action.payload);
            })
            .addCase(fetchAllDatasets.fulfilled, (state,action) => {
                state.datasets = action.payload;
            })
            .addCase(fetchDatasetResults.fulfilled, (state,action) => {
                const thisDataset = state.datasets.find( (dataset) => {
                    return dataset.id === action.payload.dataset;
                });
                thisDataset.results = action.payload.results;
            })
            .addCase(deleteSingleDataset.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteSingleDataset.fulfilled, (state,action) => {
                state.status = "succeeded";
                state.datasets = state.datasets.filter(dataset => dataset.id !== action.payload);
            })
    }
});

export const { menuSelected } = settingsSlice.actions;

export const selectSelectedMenu = state => state.settings.selectedMenu;
export const selectStatus = state => state.settings.status;
export const selectAllIntegrations = state => state.settings.integrations;
export const selectIntegrationById = (state, integrationId) => {
    return state.settings.integrations.find(integration => integration.id === integrationId);
}
export const selectAllDatasets = state => state.settings.datasets;
export const selectDatasetById = (state, datasetId) => {
    return state.settings.datasets.find(dataset => dataset.id === datasetId);
}

export default settingsSlice.reducer;