import Sidebar from "../../../../components/sidebar/Sidebar";
import CommunicationTasksDataTable from "../../components/datatable/CommunicationTasksDataTable";
import CommunicationTasksNavbar from "../../components/navbar/CommunicationTasksNavbar";
import "./communicationtasksmainpage.scss";


const CommunicationTasksMainPage = () => {
    return (
        <div className="communicationtasksMainPage">
            <Sidebar />
            <div className="communicationtasksMainPageContainer">
                <CommunicationTasksNavbar/>
                <CommunicationTasksDataTable/>
            </div>
        </div>
    )
}

export default CommunicationTasksMainPage;