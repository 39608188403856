import { useParams } from "react-router-dom";
import ReturnBar from "../../../../components/returnbar/ReturnBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import "./receptioncontrolsinglebatch.scss";
import { Button } from "@mui/material";
import { useState } from "react";
import { useGetCasesByPeriodQuery, useGetSingleCaseByIdQuery } from "../../rtkReceptionControlSlice";
import SingleBatchComments from "../../components/comments/SingleBatchComments";
import _default from "react-redux/es/components/connect";
import _ from "lodash"
import { useSelector } from "react-redux";
import { selectCurrentCaseDetails } from "../../receptionControlSlice";

const ReceptionControlSingleBatch = () => {
    const params = useParams();

    const caseDetails = useSelector(selectCurrentCaseDetails);

    const { data: thisCase, isSuccess } = useGetSingleCaseByIdQuery(params.caseId);

    return (
        <div className="receptionControlSingleBatch">
            <Sidebar />
            <ReturnBar />
            {isSuccess &&
                <div className="singleBatchContainer">
                    <div className="caseStatus">
                        <span>Status:</span>
                        <span className={`${thisCase.caseStatus}`}>{thisCase.caseStatus}</span>
                    </div>
                    <div className="singleBatchData">
                        <div className="header">
                            <div className="labelItem">
                                <p>Data field</p>
                            </div>
                            <div className="orderData">
                                <p>Order data</p>
                            </div>
                            <div className="eabData">
                                <div className="titleContainer">
                                    <span>EAB data</span>
                                    <div className={`statusCirlce ${caseDetails.eabStatus.colorCode}`}>
                                    </div>
                                </div>
                            </div>
                            <div className="receivementData">
                                <div className="titleContainer">
                                    <span>Receivement data</span>
                                    <div className={`statusCirlce ${caseDetails.receivedStatus.colorCode}`}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="batchIdData">
                            <div className="labelItem">
                                <p>BatchID</p>
                            </div>
                            <div className="orderData">
                                <p>MOL: {thisCase.batchData?.order_mol_batch_id}</p>
                            </div>
                            <div className="eabData">
                                <p>LVS: {thisCase.eabData?.eab_lvs_batch_id}</p>
                            </div>
                            <div className="receivementData">
                                <p>MOL: {thisCase.receivement_data?.BATCH_ID}</p>
                            </div>
                        </div>
                        <div className="supplyLetterData">
                            <div className="labelItem">
                                <p>Supply Letter</p>
                            </div>
                            <div className="orderData">
                                <p>N/A</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_number}-{thisCase.eabData?.eab_letter}</p>
                            </div>
                            <div className="receivementData">
                                <p>{thisCase.receivementData?.receivement_supplyletter_number}-{thisCase.receivementData?.receivement_supplyletter_letter}</p>
                            </div>
                        </div>
                        <div className="supplierData">
                            <div className="labelItem">
                                <p>Supplier</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_supplier_code} - {thisCase.batchData?.order_supplier_name}</p>
                            </div>
                            <div className="eabData">
                                <p>N/A</p>
                            </div>
                            <div className="receivementData">
                                <p>N/A</p>
                            </div>
                        </div>
                        <div className="growerData">
                            <div className="labelItem">
                                <p>Grower</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_grower_code} - {thisCase.batchData?.order_grower_name}</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_grower_code} - {thisCase.eabData?.eab_grower_name}</p>
                            </div>
                            <div className="receivementData">
                                <p>N/A</p>
                            </div>
                        </div>
                        <div className="buyerData">
                            <div className="labelItem">
                                <p>Buyer</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_buyer_code} - {thisCase.batchData?.order_buyer_name}</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_buyer_code} - {thisCase.eabData?.eab_buyer_name}</p>
                            </div>
                            <div className="receivementData">
                                <p>N/A</p>
                            </div>
                        </div>
                        <div className="productData">
                            <div className="labelItem">
                                <p>Product</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_product_description}</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_product_description}</p>
                            </div>
                            <div className="receivementData">
                                <p>N/A</p>
                            </div>
                        </div>
                        <div className="packageData">
                            <div className="labelItem">
                                <p>Package</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_package_code}</p>
                            </div>
                            <div className={`eabData ${_.some(thisCase.caseDifferences, { 'field_name': 'eab_package_code' }) && 'red'}`}>
                                <p>{thisCase.eabData?.eab_package_code}</p>
                            </div>
                            <div className="receivementData">
                                <p>{thisCase.batchData?.order_package_code}</p>
                            </div>
                        </div>
                        <div className="quantityData">
                            <div className="labelItem">
                                <p>Quantity</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_qty_units} x {thisCase.batchData?.order_qty_per_unit}</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_qty_units} x {thisCase.eabData?.eab_qty_per_unit}</p>
                            </div>
                            <div className={`receivementData ${_.some(thisCase.caseDifferences, { 'field_name': 'receivement_qty_units' }) && 'red'}`}>
                                <p>{thisCase.receivementData?.receivement_qty_units} x {thisCase.receivementData?.receivement_qty_per_unit}</p>
                            </div>
                        </div>
                        <div className="priceData">
                            <div className="labelItem">
                                <p>Price</p>
                            </div>
                            <div className="orderData">
                                <p>{thisCase.batchData?.order_price}</p>
                            </div>
                            <div className={`eabData ${_.some(thisCase.caseDifferences, { 'field_name': 'eab_price' }) && 'red'}`}>
                                <p>{thisCase.eabData?.eab_price}</p>
                            </div>
                            <div className="receivementData">
                                <p>N/A</p>
                            </div>
                        </div>
                        <div className="trolleyData">
                            <div className="labelItem">
                                <p>Trolley Type</p>
                            </div>
                            <div className="orderData">
                                <p>N/A</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_trolley_type}</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.receivementData?.receivement_trolley_type}</p>
                            </div>
                        </div>
                        <div className="trolleyQtyData">
                            <div className="labelItem">
                                <p>Qty. Trolleys</p>
                            </div>
                            <div className="orderData">
                                <p>N/A</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_qty_trolleys}</p>
                            </div>
                            <div className="receivementData">
                                <p>{thisCase.receivementData?.receivement_qty_trolleys}</p>
                            </div>
                        </div>
                        <div className="shelveQtyData">
                            <div className="labelItem">
                                <p>Qty. Shelves</p>
                            </div>
                            <div className="orderData">
                                <p>N/A</p>
                            </div>
                            <div className="eabData">
                                <p>{thisCase.eabData?.eab_qty_shelves}</p>
                            </div>
                            {/* <div className="receivementData"> */}
                            <div className={`receivementData ${_.some(thisCase.caseDifferences, { 'field_name': 'receivement_qty_shelves' }) && 'red'}`}>
                                <p>{thisCase.receivementData?.receivement_qty_shelves}</p>
                            </div>
                        </div>
                    </div>
                    <div className="singleBatchComments">
                        <SingleBatchComments caseId={thisCase.caseId} caseStatus={thisCase.caseStatus} />
                    </div>
                </div>
            }
        </div>
    )
}

export default ReceptionControlSingleBatch;