import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { batchesDataTableColumns } from "./batchesDataTableColumns";
import "./receptioncontrolbatchesdatatable.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFilteredCases } from "../../receptionControlSlice";
import { Box, IconButton, Typography } from "@mui/material";
import { useAddCaseCommentMutation } from "../../rtkReceptionControlSlice";
import { selectRTKCurrentUser } from "../../../auth/authSlice";
import { useState } from "react";

const ReceptionControlBatchesDataTable = ({ cases }) => {



    const navigate = useNavigate();

    const [rowToClose, setRowToClose] = useState('');
    const currentUser = useSelector(selectRTKCurrentUser);

    const rowClicked = (e) => {
        navigate(e.id.toString());
    }

    const infoBtnClicked = (rowId) => {
        navigate(rowId);
    }

    const [addComment] = useAddCaseCommentMutation();

    const thumbsUpBtnClicked = (rowId) => {

        const addCommentObj = {
            caseId: rowId,
            userEmail: currentUser.email,
            messageContent: 'Quickly closed from table view',
            newStatus: 'closed'
        };

        try {
            addComment(addCommentObj);
        } catch (err) {
            console.log(err);
        }

    }




    const actionButtons = {
        field: 'actions',
        renderHeader: (params) => (
            <Box
            >
                <Typography
                    sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                >ACTIONS</Typography>
            </Box>
        ),
        width: 140,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
            return (
                // <div className="sjalala">
                //     <div className="beforehover">
                //     </div>
                //     <div className="afterhover">
                //         {/* <Button sx={{ color: "#000", borderRadius: "3px", padding: "3px 10px" }} className="appie" color="secNormalGreen" size="small" variant="contained" startIcon={<InfoIcon />}>Details</Button> */}
                //         <IconButton sx={{ color: "000" }} className="appie" color="secNormalGreen" onClick={(e) => rowClicked(e)}><InfoIcon /></IconButton>
                //         {/* <IconButton sx={{ color: "000" }} className="appie" color="secNormalGreen"><ThumbUpAltIcon /></IconButton> */}
                //     </div>
                // </div>
                <>
                    <div>
                        <IconButton
                            sx={{ color: "000" }}
                            className="appie"
                            color="secNormalGreen"
                            onClick={() => {
                                return infoBtnClicked(params.id)
                            }}
                        ><InfoIcon /></IconButton>
                    </div>
                    <div>
                        <IconButton
                            sx={{ color: "000" }}
                            className="appie"
                            color="secDarkGreen"
                            onClick={() => {
                                return thumbsUpBtnClicked(params.id)
                            }}
                        ><ThumbUpAltIcon /></IconButton>
                    </div>
                </>
            )
        }
    }


    const MyDataTable = styled(DataGrid)({

        '.MuiDataGrid-row': {
            backgroundColor: '#47aa7710',

            '&:hover': {
                backgroundColor: '#E0EFE9',
                cursor: 'pointer',

                '.beforehover': {
                    opacity: '0'
                }
            }
        }

    });



    return (
        <div className="receptionControlBatchesDataTable">
            <MyDataTable
                rows={cases.length ? cases : []}
                columns={batchesDataTableColumns.cases.concat(actionButtons)}
                headerHeight={40}
                // rowHeight={45}
                getRowId={(row) => row.caseId}
            // onRowClick={rowClicked}
            />
        </div>
    )
}

export default ReceptionControlBatchesDataTable;