import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReturnBar from "../../../../../components/returnbar/ReturnBar";
import SingleDatasetConfig from "../../components/singledatasetconfig/SingleDatasetConfig";
import SingleDatasetResults from "../../components/singledatasetresults/SingleDatasetResults";
import { RedButton } from "../../../../../theme/buttons";
import "./singledataset.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteSingleDataset } from "../../../settingsSlice";
import { useDeleteSingleDatasetMutation } from "../../../rtkSettingsSlice";

const SingleDataset = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(0);

    const [deleteSingleDataset] = useDeleteSingleDatasetMutation();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const deleteDataset = async (datasetId) => {

        try {
            //dispatch(deleteSingleDataset(datasetId));
            await deleteSingleDataset(datasetId).unwrap();
        } catch (err) {

        } finally {
            navigate("../../datasets");
        }

    }



    return (
        <div className="singleDatasetWrapper">
            <ReturnBar />
            <div className="singleDatasetContainer">
                <div className="header">
                    <div className="title">
                        <h3>Edit Dataset</h3>
                    </div>
                    <div className="deleteBtn">
                        <RedButton
                            startIcon={<DeleteForeverIcon />}
                            size="small"
                            onClick={() => deleteDataset(params.datasetId)}
                        >Delete</RedButton>
                    </div>
                </div>
                <div className="content">
                    <div className="tabMenu">
                        <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary">
                            <Tab label="Results" value={0} className="singleTab" />
                            <Tab label="Config" value={1} className="singleTab" />
                        </Tabs>
                    </div>
                    {tabValue === 0 &&
                        <div className="dataresultsContainer">
                            <SingleDatasetResults />
                        </div>
                    }
                    {tabValue === 1 &&
                        <div className="configurationContainer">
                            <SingleDatasetConfig />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SingleDataset;