import { Autocomplete, Button, Chip, Menu, MenuItem, Select, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchAllDatasets, fetchDatasetResults, selectAllDatasets, selectDatasetById } from "../../../settings/settingsSlice";
import { fetchAllUsers, selectAllUsers, selectCurrentUser, selectUserById } from "../../../users/usersSlice";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveIcon from '@mui/icons-material/Save';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import PinIcon from '@mui/icons-material/Pin';
import AbcIcon from '@mui/icons-material/Abc';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import "./addinfoproductform.scss";
import { DarkGreenButton, LightGreenButton, RedButton } from "../../../../theme/buttons";
import { addNewInfoProduct } from "../../infoproductsSlice";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../app/baseURL";
import { useGetUsersQuery } from "../../../users/rtkUsersSlice";
import { useGetDatasetsQuery } from "../../../settings/rtkSettingsSlice";
import { useAddInfoProductMutation } from "../../rtkInfoproductsSlice";
import { selectRTKCurrentUser } from "../../../auth/authSlice";

const AddInfoProductForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const steps = ['Basic data', 'Conditions', 'Message Content'];
    //const users = useSelector(selectAllUsers);
    const { data: users, isSuccess } = useGetUsersQuery()
    //const usersStatus = useSelector(state => state.users.status);
    // const datasets = useSelector(selectAllDatasets);
    const { data: datasets, isSuccess: datasetsLoaded } = useGetDatasetsQuery();
    // const settingsStatus = useSelector(state => state.settings.status);
    const currentUser = useSelector(selectRTKCurrentUser);

    const [activeStep, setActiveStep] = useState(0);
    const [description, setDescription] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState("");
    const [selectedTrigger, setSelectedTrigger] = useState("");
    const [selectedMediaType, setSelectedMediaType] = useState("");
    const [selectedCreationSchedule, setSelectedCreationSchedule] = useState("");
    const [availableConditions, setAvailableConditions] = useState([]);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [newConditionFieldName, setNewConditionFieldName] = useState("");
    const [newConditionFieldType, setNewConditionFieldType] = useState("");
    const [newConditionExampleValue, setNewConditionExampleValue] = useState("");
    const [newConditionOperator, setNewConditionOperator] = useState("");
    const [newConditionValue, setNewConditionValue] = useState("");
    const [messageContent, setMessageContent] = useState("");
    const [textEditorConditionField, setTextEditorConditionField] = useState("");
    const [smartphoneMessageContent, setSmartphonMessageContent] = useState("");

    const descriptionChanged = (e) => setDescription(e.target.value);
    const selectedDatasetChanged = (e) => setSelectedDataset(e.target.value);
    const selectedUsersChanged = (event, newvalue) => setSelectedUsers(newvalue);
    const selectedTriggerChanged = (e) => setSelectedTrigger(e.target.value);
    const selectedMediaTypeChanged = (e) => setSelectedMediaType(e.target.value);
    const selectedCreationScheduleChanged = (e) => setSelectedCreationSchedule(e.target.value);
    const newConditionFieldNameChanged = (e) => {
        setNewConditionFieldName(e.target.value);
        const conditionFieldDetails = availableConditions.find((condition) => {
            return condition.fieldName === e.target.value;
        });
        setNewConditionFieldType(conditionFieldDetails.fieldtype);
        setNewConditionExampleValue(conditionFieldDetails.exampleValue);
    }
    const newConditionOperatorChanged = (e) => setNewConditionOperator(e.target.value);
    const newConditionValueChanged = (e) => setNewConditionValue(e.target.value);
    const messageContentChanged = (e) => {

        const editorText = e.target.value;
        const regex = /<<[a-zA-Z0-9_]*>>/gm;
        const smartphoneText = editorText.replaceAll(regex, (string) => {
            const fieldName = availableConditions.find(condition => condition.fieldName === string.slice(2, -2));
            return fieldName.exampleValue;
        });

        setMessageContent(editorText);
        setSmartphonMessageContent(smartphoneText);
    };

    const textEditorConditionFieldChanged = (e) => {
        setTextEditorConditionField(e.target.value);
    }

    const [addInfoProduct] = useAddInfoProductMutation();

    // useEffect(() => {
    //     if (usersStatus === "idle") {
    //         dispatch(fetchAllUsers());
    //     }
    // }, [usersStatus, dispatch]);

    // useEffect(() => {
    //     if (settingsStatus === "idle") {
    //         dispatch(fetchAllDatasets());
    //     }
    // }, [settingsStatus, dispatch]);

    useEffect(() => {

        if (selectedDataset) {

            const fetchDatasetResults = async () => {

                const response = await fetch(`${baseURL}/datasets/results/${selectedDataset}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        //need to add accessToken here
                        "Authorization": `Bearer ${currentUser.accessToken}`
                    },
                    redirect: "follow"
                });

                const data = await response.json();
                console.log(data);

                const thisDataset = datasets.find(dataset => dataset.id === selectedDataset);
                let tempAvailableConditionsArray = [];
                for (const [key, value] of Object.entries(data[0])) {
                    if (key !== "id") {
                        const matchingElement = thisDataset.selectedFields.find((field) => {
                            return _.camelCase(field.key) === key;
                        });
                        const newObject = {
                            fieldName: matchingElement.key,
                            fieldtype: matchingElement.value,
                            exampleValue: value
                        }

                        tempAvailableConditionsArray.push(newObject);

                    }
                }
                setAvailableConditions(tempAvailableConditionsArray);
            };

            fetchDatasetResults();
        }


    }, [selectedDataset]);

    const nextBtnHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const previousBtnHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const newConditionSaveBtnClicked = (e) => {
        console.log("save Button clicked");
        const newConditionObject = {
            fieldName: newConditionFieldName,
            fieldType: newConditionFieldType,
            operator: newConditionOperator,
            conditionValue: newConditionValue
        };
        setSelectedConditions(prevArray => [...prevArray, newConditionObject]);
        setNewConditionFieldName("");
        setNewConditionFieldType("");
        setNewConditionExampleValue("");
        setNewConditionOperator("");
        setNewConditionValue("");
    }

    const addFieldToTextArea = (e) => {
        let wordArray = [messageContent, `<<${textEditorConditionField}>>`];
        setMessageContent(wordArray.join(""));
        const thisField = availableConditions.find(condition => condition.fieldName === textEditorConditionField);
        let smartPhoneWordArray = [smartphoneMessageContent, thisField.exampleValue];
        setSmartphonMessageContent(smartPhoneWordArray.join(""));

    }

    const saveInformationProduct = async (e) => {
        e.preventDefault();
        console.log("let's save this informationproduct");
        const informationProductObject = {
            description,
            selectedUsers,
            selectedDataset,
            selectedTrigger,
            selectedMediaType,
            selectedCreationSchedule,
            selectedConditions,
            messageContent
        };

        try {
            await addInfoProduct(informationProductObject).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            setDescription("")
            navigate("../../infoproducts");
        }
    }

    const deleteSelectedCondition = (deletedcondition) => {
        console.log('clicked selected condition delete button')
        const updatedConditionArray = selectedConditions.filter((selectedCondition) => {
            return selectedCondition.fieldName !== deletedcondition.fieldName;
        });
        setSelectedConditions(updatedConditionArray);
    }

    return (
        <div className="addInfoProductForm">
            <div className="addInfoProductFormContainer">
                <div className="header">
                    <h3>New Infoproduct</h3>
                </div>
                <div className="content">
                    <div className="stepper">
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </div>
                    <div className="stepContentContainer">
                        <form className="stepContentForm" onSubmit={saveInformationProduct}>
                            {activeStep === 0 &&
                                <div className="stepContentFormFields">

                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Description:</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <TextField size="small" variant="outlined" className="field-input__input" value={description} onChange={descriptionChanged}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Selected Users:</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Autocomplete
                                                    multiple
                                                    id="tags-standard"
                                                    options={isSuccess ? users : []}
                                                    onChange={(event, newvalue) => selectedUsersChanged(event, newvalue)}
                                                    value={selectedUsers}
                                                    getOptionLabel={(option) => {
                                                        return (
                                                            `${option.firstName} ${option.lastName}`
                                                        )
                                                    }
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Dataset</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={selectedDataset}
                                                    onChange={selectedDatasetChanged}
                                                    sx={{ width: "100%", height: "30px" }}
                                                >
                                                    {datasetsLoaded && datasets.map((dataset) => {
                                                        return (
                                                            <MenuItem key={dataset.id} id={dataset.id} value={dataset.id}>{dataset.description}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Media Type</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={selectedMediaType}
                                                    onChange={selectedMediaTypeChanged}
                                                    sx={{ width: "100%", height: "30px" }}
                                                >
                                                    <MenuItem key='telegram' id="telegram" value="telegram">Telegram</MenuItem>
                                                    <MenuItem key='sms' id="sms" value="sms" disabled>SMS (available later)</MenuItem>
                                                    <MenuItem key='whatsapp' id="whatsapp" value="whatsapp" disabled>WhatsApp (available later)</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Trigger</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={selectedTrigger}
                                                    onChange={selectedTriggerChanged}
                                                    sx={{ width: "100%", height: "30px" }}
                                                >
                                                    <MenuItem key='newrow' id="newrow" value="newrow">New Row</MenuItem>
                                                    <MenuItem key='updatedrow' id="updatedrow" value="updatedrow" disabled>Updated Row (available later)</MenuItem>
                                                    <MenuItem key='deletedrow' id="deletedrow" value="deletedrow" disabled>Deleted Row (available later)</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Creation Schedule</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={selectedCreationSchedule}
                                                    onChange={selectedCreationScheduleChanged}
                                                    sx={{ width: "100%", height: "30px" }}
                                                >
                                                    <MenuItem key='instantly' id="instantly" value="instantly">Instantly</MenuItem>
                                                    <MenuItem key='customschedule' id="customschedule" value="customschedule" disabled>Custom Schedule (available later)</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeStep === 1 &&
                                <div className="conditionsContainer">
                                    <label>New Condition</label>
                                    <div className="newCondition">
                                        <div className="fieldNameAndExampleValue">
                                            <div className="fieldName">
                                                <div className="label">
                                                    <p>Fieldname:</p>
                                                </div>
                                                <div className="input">
                                                    <Select
                                                        size="small"
                                                        value={newConditionFieldName}
                                                        onChange={newConditionFieldNameChanged}
                                                        sx={{ width: "280px", height: '40px' }}
                                                    >
                                                        {availableConditions.map((condition) => {
                                                            return (
                                                                <MenuItem key={condition.fieldName} id={condition.fieldName} value={condition.fieldName} disabled={condition.fieldtype === 'datetime'}>
                                                                    <Box className="newConditionMenuItem" sx={{ display: 'flex', gap: '10px' }}>
                                                                        {condition.fieldtype === 'datetime'
                                                                            ? <div><CalendarMonthIcon fontSize="small" /> </div>
                                                                            : condition.fieldtype === 'real'
                                                                                ? <div><EuroSymbolIcon fontSize="small" /></div>
                                                                                : condition.fieldtype === 'number'
                                                                                    ? <div><PinIcon fontSize="small" /></div>
                                                                                    : condition.fieldtype === 'string'
                                                                                        ? <div><AbcIcon fontSize="small" /></div>
                                                                                        : condition.fieldtype === 'boolean'
                                                                                            ? <div><ToggleOnIcon fontSize="small" /></div>
                                                                                            : ''}
                                                                        <div>
                                                                            <p>{condition.fieldName}</p>
                                                                        </div>
                                                                    </Box>
                                                                </MenuItem>
                                                            )
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                            {newConditionFieldName &&
                                                <div className="exampleValue">
                                                    <div className="label">
                                                        <p>Example value:</p>
                                                    </div>
                                                    <div className="dataField">
                                                        <p>{newConditionExampleValue}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="operator">
                                            <div className="label">
                                                <p>Operator:</p>
                                            </div>
                                            <div className="input">
                                                {newConditionFieldType !== 'string' &&
                                                    <Select
                                                        size="small"
                                                        value={newConditionOperator}
                                                        onChange={newConditionOperatorChanged}
                                                        sx={{ width: "250px", height: '40px' }}
                                                    >
                                                        <MenuItem value="equal"><p>is equal to</p></MenuItem>
                                                        <MenuItem value="notequal"><p>is not equal to</p></MenuItem>
                                                        <MenuItem value="lessthanorequal"><p>is less than or equal to</p></MenuItem>
                                                        <MenuItem value="lessthan"><p>is less than</p></MenuItem>
                                                        <MenuItem value="biggerthanorequal"><p>is bigger than or equal to</p></MenuItem>
                                                        <MenuItem value="biggerthan"><p>is bigger than</p></MenuItem>
                                                    </Select>
                                                }
                                                {newConditionFieldType === 'string' &&
                                                    <Select
                                                        size="small"
                                                        value={newConditionOperator}
                                                        onChange={newConditionOperatorChanged}
                                                        sx={{ width: "250px", height: '40px' }}
                                                    >
                                                        <MenuItem value="equal"><p>is equal to</p></MenuItem>
                                                        <MenuItem value="notequal"><p>is not equal to</p></MenuItem>
                                                    </Select>
                                                }
                                            </div>
                                        </div>
                                        <div className="conditionValue">
                                            <div className="label">
                                                <p>Condition value:</p>
                                            </div>
                                            <div className="input">
                                                <TextField size="small" sx={{ width: '250px', height: '35px' }} value={newConditionValue} onChange={newConditionValueChanged}></TextField>
                                            </div>
                                        </div>
                                        <div className="saveButton">
                                            <RedButton size="small" onClick={newConditionSaveBtnClicked}>Save</RedButton>
                                        </div>
                                    </div>
                                    {selectedConditions.length > 0 &&
                                        <div className="selectedConditions">
                                            <label>Selected Conditions</label>
                                            {selectedConditions.map((condition) => {
                                                return (
                                                    <div key={condition.fieldName} className="singleCondition">
                                                        <div className="fieldName">
                                                            <div className="label">
                                                                <p>Fieldname:</p>
                                                            </div>
                                                            <div className="value">
                                                                <Box className="newConditionMenuItem" sx={{ display: 'flex', gap: '10px' }}>
                                                                    {condition.fieldType === 'datetime'
                                                                        ? <div><CalendarMonthIcon fontSize="small" /> </div>
                                                                        : condition.fieldType === 'real'
                                                                            ? <div><EuroSymbolIcon fontSize="small" /></div>
                                                                            : condition.fieldType === 'number'
                                                                                ? <div><PinIcon fontSize="small" /></div>
                                                                                : condition.fieldType === 'string'
                                                                                    ? <div><AbcIcon fontSize="small" /></div>
                                                                                    : condition.fieldType === 'boolean'
                                                                                        ? <div><ToggleOnIcon fontSize="small" /></div>
                                                                                        : ''}
                                                                    <div>
                                                                        <p>{condition.fieldName}</p>
                                                                    </div>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div className="operator">
                                                            <div className="label">
                                                                <p>Operator:</p>
                                                            </div>
                                                            <div className="value">
                                                                <p>{condition.operator === 'equal'
                                                                    ? 'is equal to'
                                                                    : condition.operator === 'notequal'
                                                                        ? 'is not equal to'
                                                                        : condition.operator === 'lessthanorequal'
                                                                            ? 'is less than or equal to'
                                                                            : condition.operator === 'lessthan'
                                                                                ? 'is less than'
                                                                                : condition.operator === 'biggerthanorequal'
                                                                                    ? 'is bigger than or equal to'
                                                                                    : condition.operator === 'biggerthan'
                                                                                        ? 'is bigger than'
                                                                                        : condition.operator}</p>
                                                            </div>
                                                        </div>
                                                        <div className="conditionValue">
                                                            <div className="label">
                                                                <p>Condition Value:</p>
                                                            </div>
                                                            <div className="value">
                                                                <p>{condition.conditionValue}</p>
                                                            </div>
                                                        </div>
                                                        <div className="deleteButton">
                                                            <RedButton
                                                                size="small"
                                                                onClick={() => deleteSelectedCondition(condition)}
                                                            >Delete</RedButton>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            }
                            {activeStep === 2 &&
                                <div className="informationProductContent">
                                    <div className="input">
                                        <label>Message Content</label>
                                        <div className="textEditorHeader">
                                            <div className="textEditorHeaderSelectField">
                                                <Select
                                                    size="small"
                                                    value={textEditorConditionField}
                                                    onChange={textEditorConditionFieldChanged}
                                                    sx={{ width: "280px", height: '40px' }}
                                                >
                                                    {availableConditions.map((condition) => {
                                                        return (
                                                            <MenuItem key={condition.fieldName} id={condition.fieldName} value={condition.fieldName} disabled={condition.fieldtype === 'datetime'}>
                                                                <Box className="newConditionMenuItem" sx={{ display: 'flex', gap: '10px' }}>
                                                                    {condition.fieldtype === 'datetime'
                                                                        ? <div><CalendarMonthIcon fontSize="small" /> </div>
                                                                        : condition.fieldtype === 'real'
                                                                            ? <div><EuroSymbolIcon fontSize="small" /></div>
                                                                            : condition.fieldtype === 'number'
                                                                                ? <div><PinIcon fontSize="small" /></div>
                                                                                : condition.fieldtype === 'string'
                                                                                    ? <div><AbcIcon fontSize="small" /></div>
                                                                                    : ''}
                                                                    <div>
                                                                        <p>{condition.fieldName}</p>
                                                                    </div>
                                                                </Box>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className="textEditorHeaderAddButton">
                                                <Button variant="contained" onClick={addFieldToTextArea}>Add field</Button>
                                            </div>
                                        </div>
                                        <div className="textEditorContent">
                                            <TextField multiline fullWidth minRows={15} value={messageContent} onChange={messageContentChanged}></TextField>
                                        </div>
                                    </div>
                                    <div className="example">
                                        <div className="smartphoneContainer">
                                            <div className="smartphoneFrame">
                                                <div className="smartphoneContent">
                                                    <Box className="smartphoneSingleMessage">
                                                        {<Box className="messageText"><pre>{smartphoneMessageContent}</pre></Box>}
                                                    </Box>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="stepContentFormButtons">
                                {activeStep !== 0 &&
                                    <div className="cancelBtn">
                                        <RedButton
                                            startIcon={<NavigateBeforeIcon />}
                                            size="small"
                                            onClick={previousBtnHandler}
                                        >Previous</RedButton>
                                    </div>
                                }
                                {activeStep !== steps.length - 1 &&
                                    <div className="nextBtn">
                                        <LightGreenButton
                                            startIcon={<NavigateNextIcon />}
                                            size="small"
                                            onClick={nextBtnHandler}
                                        >Next</LightGreenButton>
                                    </div>
                                }
                                {activeStep === steps.length - 1 &&
                                    <div className="submitBtn">
                                        <LightGreenButton
                                            type="submit"
                                            startIcon={<SaveIcon />}
                                            size="small"
                                        >Save</LightGreenButton>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddInfoProductForm;