import "./companiesnavbar.scss";
import { TextField } from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { DarkGreenButton } from "../../../../theme/buttons";
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";


const CompaniesNavbar = () => {

    const navigate = useNavigate();

    const addButtonClicked = (e) => {
        console.log("hoi");
        navigate("/companies/new");

    }

    return (
        <div className="navbar">
            <div className="titleCounter">
                <BusinessIcon fontSize="medium" />
                <span>4 Companies</span>
            </div>
            <div className="searchbox">
                <TextField variant="outlined" size="small" placeholder="Search" margin="none" sx={{ width: '300px' }} />
            </div>
            <div className="buttonGroup">
                <DarkGreenButton
                    onClick={addButtonClicked}
                    startIcon={<AddCircleOutlinedIcon />}
                    size="small"
                >Add Company</DarkGreenButton>
            </div>
        </div>
    )
}

export default CompaniesNavbar;