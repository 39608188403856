import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, grower: '' },
    reducers: {
        setCredentials: (state, action) => {
            state.currentUser = action.payload;
            state.token = action.payload.accessToken;
        },
        logout: (state, action) => {
            state.user = null;
            state.token = null;
        },
        setGrower: (state, action) => {
            state.grower = action.payload.grower;
        }
    }
})

export const { setCredentials, logout, setGrower } = authSlice.actions;

export default authSlice.reducer;

export const selectRTKCurrentUser = (state) => state.auth.currentUser;
export const selectRTKCurrentToken = (state) => state.auth.token;