import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import _ from "lodash";
import { format } from "date-fns"
import { nl } from "date-fns/locale"


export const batchesDataTableColumns = {
    cases: [
        // {
        //     field: 'checkbox',
        //     headerName: '',
        //     width: 60,
        //     filterable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => (
        //         <Box>
        //             <Checkbox />
        //         </Box>
        //     )
        // },
        {
            field: 'date',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Date</Typography>
                </Box>
            ),
            width: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const orderDate = params.row.batchData.order_date;
                const orderYear = orderDate.slice(0, 4);
                const orderMonth = orderDate.slice(4, 6);
                const orderDay = orderDate.slice(6, 8);
                const newDate = new Date(orderYear, parseInt(orderMonth) - 1, orderDay);
                return (
                    <Box>
                        <p className="orderdate_daylabel">{`${format(newDate, 'EEEE')}`}</p>
                        <p className="orderdate_value">{`${format(newDate, 'dd MMM yyyy')}`}</p>
                    </Box>
                )
            }
        },
        {
            field: 'id',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >ID</Typography>
                </Box>
            ),
            width: 70,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{`${params.row.caseMolBatchId}`}</p>
                </Box>
            )
        },
        {
            field: 'description',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Description</Typography>
                </Box>
            ),
            width: 240,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="product__description">{`${params.row.batchData.order_product_description}`}</p>
                </Box>
            )
        },
        {
            field: 'supplier',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Supplier</Typography>
                </Box>
            ),
            width: 240,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    {/* <p className="product__description">{`${params.row.ORDER_SUPPLIER_CODE} - ${params.row.ORDER_SUPPLIER_NAME}`}</p> */}
                    <p className="product__description">{`${params.row.batchData.order_supplier_code}`}</p>
                    <p className="product__description">{`${params.row.batchData.order_supplier_name}`}</p>
                </Box>
            )
        },
        {
            field: 'grower',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Grower</Typography>
                </Box>
            ),
            width: 240,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    {/* <p className="product__description">{`${params.row.ORDER_GROWER_CODE} - ${params.row.ORDER_GROWER_NAME}`}</p> */}
                    <p className="product__description">{`${params.row.batchData.order_grower_code}`}</p>
                    <p className="product__description">{`${params.row.batchData.order_grower_name}`}</p>
                </Box>
            )
        },
        {
            field: 'buyer',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Buyer</Typography>
                </Box>
            ),
            width: 240,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    {/* <p className="product__description">{`${params.row.ORDER_BUYER_CODE} - ${params.row.ORDER_BUYER_NAME}`}</p> */}
                    <p className="product__description">{`${params.row.batchData.order_buyer_code}`}</p>
                    <p className="product__description">{`${params.row.batchData.order_buyer_name}`}</p>
                </Box>
            )
        },
        {
            field: 'quantity',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Quantity</Typography>
                </Box>
            ),
            width: 80,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="product__description">{`${params.row.batchData.order_qty_units} x ${params.row.batchData.order_qty_per_unit}`}</p>
                </Box>
            )
        },
        {
            field: 'package',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Package</Typography>
                </Box>
            ),
            width: 80,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="product__description">{`${params.row.batchData.order_package_code}`}</p>
                </Box>
            )
        },
        {
            field: 'price',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Price</Typography>
                </Box>
            ),
            width: 80,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="product__description">{`${parseFloat(params.row.batchData.order_price).toFixed(3)}`}</p>
                </Box>
            )
        },
        {
            field: 'eabstatus',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >EAB</Typography>
                </Box>
            ),
            width: 100,
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                let color;
                if (!params.row.eabData) {
                    color = 'orange';
                } else if (params.row.eabData && _.some(params.row.caseDifferences, { 'comparison_type': 'eabToOrder' })) {
                    color = 'rgba(255, 0, 0, 0.618)';
                } else {
                    color = 'lightgreen';
                }
                return (
                    <Box sx={{ backgroundColor: color, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className={`eabData`}>{`${params.row.eabData ? `${params.row.eabData.eab_number}-${params.row.eabData.eab_letter}` : 'NO EAB'}`}</p>
                    </Box>
                )
            }
        },
        {
            field: 'receptionstatus',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Received</Typography>
                </Box>
            ),
            width: 100,
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                let totalBatchQty = params.row.batchData?.order_qty_units * params.row.batchData?.order_qty_per_unit;
                let totalReceivementQty = params.row.receivementData?.receivement_qty_units * params.row.receivementData?.receivement_qty_per_unit;
                let receivedStatus;
                if (!totalReceivementQty) {
                    receivedStatus = 'orange';
                } else if (totalBatchQty === totalReceivementQty) {
                    receivedStatus = 'lightgreen';
                } else if (totalBatchQty > totalReceivementQty) {
                    receivedStatus = 'rgba(255, 0, 0, 0.618)';
                }


                return (
                    <Box sx={{ backgroundColor: receivedStatus, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className="receivedStatus">{totalReceivementQty > 0 ? totalReceivementQty : 0} / {totalBatchQty > 0 ? totalBatchQty : 0} </p>
                    </Box>
                )
            }
        },
        {
            field: 'approvalstatus',
            renderHeader: (params) => (
                <Box
                >
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
                    >Approved</Typography>
                </Box>
            ),
            width: 100,
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                let color;
                if (params.row.caseStatus === 'inprogress') {
                    color = 'orange';
                } else if (params.row.caseStatus === 'open') {
                    color = 'rgba(255, 0, 0, 0.618)';
                } else {
                    color = 'lightgreen';
                }
                return (
                    <Box sx={{ backgroundColor: color, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className="receivedStatus">{params.row.caseStatus}</p>
                    </Box>
                )
            }
        },
        // {
        //     field: 'actions',
        //     renderHeader: (params) => (
        //         <Box
        //         >
        //             <Typography
        //                 sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
        //             >ACTIONS</Typography>
        //         </Box>
        //     ),
        //     width: 140,
        //     filterable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     align: 'center',
        //     headerAlign: 'center',
        //     renderCell: (params) => (
        //         <div className="sjalala">
        //             <div className="beforehover">
        //             </div>
        //             <div className="afterhover">
        //                 <Button sx={{ color: "#000", borderRadius: "3px", padding: "3px 10px" }} className="appie" color="secNormalGreen" size="small" variant="contained" startIcon={<InfoIcon />}>Details</Button>
        //             </div>
        //         </div>
        //     )
        // },
    ]
}