import "./singlemessage.scss";

const SingleMessage = ({ message }) => {

    const msgDate = new Date(message.date * 1000);
    const displayTime = msgDate.getHours().toString().padStart(2,"0") + ":" + msgDate.getMinutes().toString().padStart(2,"0");

    return (
        <div className="singleMessage">
            <div className="msgText">
                <p>{message.text}</p>
            </div>
            <div className="msgTime">
                <p>{displayTime}</p>
            </div>
        </div>
    )
}

export default SingleMessage;