import { Box, Modal, Typography } from "@mui/material";
import { format, parseISO } from "date-fns"

const handleMouseEnter = (e) => {
    console.log(e.target.textContent);
}

export const dataTableColumns = {
    communicationtasks: [
        {
            field: 'id',
            width: 70,
            headerName: 'Task ID',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{`${params.row.id}`}</p>
                </Box>
            )
        },
        {
            field: 'created_at',
            width: 180,
            headerName: 'Created At',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{`${format(parseISO(params.row.createdAt), 'EEE dd MMM yyyy HH:mm')}`}</p>
                </Box>
            )
        },
        {
            field: 'delivered_at',
            width: 180,
            headerName: 'Delivered At',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{params.row.deliveredAt ? `${format(parseISO(params.row.deliveredAt), 'EEE dd MMM yyyy HH:mm')}` : '---'}</p>
                </Box>
            )
        },
        {
            field: 'receiverName',
            width: 140,
            headerName: 'Receiver Name',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{`${params.row.userData.fullName}`}</p>
                </Box>
            )
        },
        {
            field: 'receiverCompany',
            width: 250,
            headerName: 'Company',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{`${params.row.userData.companyName} (${params.row.userData.companyAdminNumber})`}</p>
                </Box>
            )
        },
        // {
        //     field: 'informationproductId',
        //     width: 120,
        //     headerName: 'Infoproduct ID',
        //     filterable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => (
        //         <Box>
        //             <p className="company__name">{`${params.row.informationproductId}`}</p>
        //         </Box>
        //     )
        // },
        // {
        //     field: 'sourceId',
        //     width: 120,
        //     headerName: 'Source ID',
        //     filterable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => (
        //         <Box>
        //             <p className="company__name">{`${params.row.sourceId}`}</p>
        //         </Box>
        //     )
        // },
        // {
        //     field: 'mediaType',
        //     width: 120,
        //     headerName: 'Media Type',
        //     filterable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => (
        //         <Box>
        //             <p className="company__name">{`${params.row.mediaType}`}</p>
        //         </Box>
        //     )
        // },
        // {
        //     field: 'receiverId',
        //     width: 120,
        //     headerName: 'Receiver ID',
        //     filterable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => (
        //         <Box>
        //             <p className="company__name">{`${params.row.receiverId}`}</p>
        //         </Box>
        //     )
        // },
        {
            field: 'messageContent',
            width: 400,
            headerName: 'Message Content',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box>
                    <p className="company__name">{`${params.row.messageContent}`}</p>
                </Box>
            )
        },
        {
            field: 'sendStatus',
            width: 150,
            headerName: 'Status',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Box>
                        {params.row.sendStatus === true
                            ? <Typography variant="p" className="company__name" sx={{ color: 'white', backgroundColor: 'darkgreen', padding: '2px 10px', borderRadius: '5px' }}>Delivered</Typography>
                            : <Typography variant="p" className="company__name" sx={{ color: 'white', backgroundColor: 'darkred', padding: '2px 10px', borderRadius: '5px' }}>Open</Typography>
                        }
                    </Box>
                )
            }
        }
    ]
}