import "./languagesmainpage.scss";

const LanguagesMainPage = () => {
    return (
        <div className="languagesmainpage">
            Languages Main Page
        </div>
    )
}

export default LanguagesMainPage;