import { Button, Input } from "@mui/material";
import Sidebar from "../../../../components/sidebar/Sidebar";
import "./receptioncontrolmainpage.scss"
import { useState } from "react";
import ReceptionControlBatchesDataTable from "../../components/batchesdatatable/ReceptionControlBatchesDataTable";
import { useAddCasesMutation, useGetBatchesQuery, useGetCasesQuery, useLazyGetCasesQuery } from "../../rtkReceptionControlSlice";
import { format, formatISO, parseISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCasesAndFilters, selectBuyerFilter, selectFilterInformation, selectFilteredCases, selectFromDateFilter, selectGrowerFilter, selectSelectedGrowerFilter, selectUntilDateFilter, setAllCasesAndFilters, setFilteredCases, setFromDateFilter, setGrowerFilter, setUntilDateFilter } from "../../receptionControlSlice";
import { setGrower } from "../../../auth/authSlice";
import GrowerFilter from "../../components/filters/growerfilter/GrowerFilter";
import useUniqueGrowers from "../../hooks/useUniqueGrowers";
import { uniqueSelectableGrowers } from "../../components/filters/growerfilter/uniqueSelectableGrowers";
import BuyerFilter from "../../components/filters/buyerfilter/BuyerFilter";
import useApplyFilter from "../../hooks/useApplyFilter";
import { applyFilter } from "./applyFilter";
import EABStatusFilter from "../../components/filters/eabstatusfilter/EABStatusFilter";
import ReceivementStatusFilter from "../../components/filters/receivementstatusfilter/ReceivementStatusFilter";
import CaseStatusFilter from "../../components/filters/casestatusfilter/CaseStatusFilter";
import EABStatusSuperFilter from "../../components/filters/eabstatusfilter/EABStatusSuperFilter";

const ReceptionControlMainPage = () => {
    const dispatch = useDispatch();

    const fromDateFilter = useSelector(selectFromDateFilter);
    const untilDateFilter = useSelector(selectUntilDateFilter);
    const filteredCases = useSelector(selectFilteredCases);
    const filterInformation = useSelector(selectFilterInformation);

    const [trigger] = useLazyGetCasesQuery();

    const fromDateFilterChanged = (newValue) => {
        dispatch(setFromDateFilter({ fromDate: formatISO(newValue) }));
        console.log('might have to update state');
    }

    const untilDateFilterChanged = (newValue) => {
        dispatch(setUntilDateFilter({ untilDate: formatISO(newValue) }));
    }

    const { data: casesAndFilters, isSuccess, isFetching, isUninitialized } = useGetCasesQuery({
        fromDate: format(parseISO(fromDateFilter), 'yyyyMMdd'),
        untilDate: format(parseISO(untilDateFilter), 'yyyyMMdd')
    },
        {
            pollingInterval: 90000
        });



    const [triggerBackend] = useAddCasesMutation();

    const refreshCasesdBtnClicked = () => {
        console.log('got clicked');
        console.log('just a button to start process on backend which will create/update case-records for each batch in current selected period');
        console.log('now we send a POST request to start this process, but later should be a cron job on the backend that will initiate the function');
        const formattedFromDate = format(parseISO(fromDateFilter), 'yyyyMMdd');
        const formattedUntilDate = format(parseISO(untilDateFilter), 'yyyyMMdd');
        triggerBackend({ fromDate: formattedFromDate, untilDate: formattedUntilDate });
    }


    return (
        <div className="receptionControlMainPage">
            <Sidebar />
            <div className="receptionControlMainPageContainer">
                <div className="filterContainer">
                    <div className="dateFilterContainer">
                        <div className="fromDateContainer">
                            <div className="labelContainer">
                                <label>From Date</label>
                            </div>
                            <div className="inputContainer">
                                <DatePicker slotProps={{ textField: { size: 'small' } }} value={parseISO(fromDateFilter)} onChange={(newValue) => fromDateFilterChanged(newValue)} />
                            </div>
                        </div>
                        <div className="untilDateContainer">
                            <div className="labelContainer">
                                <label>Until Date</label>
                            </div>
                            <div className="inputContainer">
                                <DatePicker slotProps={{ textField: { size: 'small' } }} value={parseISO(untilDateFilter)} onChange={(newValue) => untilDateFilterChanged(newValue)} />
                            </div>
                        </div>
                    </div>
                    <div className="relationFilterContainer">
                        <GrowerFilter />
                        <BuyerFilter />
                    </div>
                    <div className="statusFilterContainer">
                        <div className="left">
                            {/* <EABStatusSuperFilter /> */}
                            <EABStatusFilter />
                            <ReceivementStatusFilter />
                        </div>
                        <div className="right">
                            <CaseStatusFilter />
                        </div>
                    </div>
                    <div className="actionButtonsContainer">
                        {/* <div><Button variant="contained" onClick={getCasesBtnClicked}>Get Cases For Period</Button></div> */}
                        {/* <div><Button variant="contained" onClick={filterCasesBtnClicked}>Filter Cases</Button></div> */}
                        {/* <div><Button variant="contained" onClick={refreshCasesdBtnClicked}>Refresh Cases</Button></div> */}
                        {/* {isFetching && <div><p>Fetching data....</p></div>} */}
                    </div>
                </div>
                <ReceptionControlBatchesDataTable cases={filteredCases} />
            </div>
        </div >
    )
}

export default ReceptionControlMainPage;