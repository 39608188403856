import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LightGreenButton, RedButton } from "../../../../theme/buttons";
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { fetchAllCompanies } from "../../../companies/companiesSlice";
import { selectUserById, updatedSingleUser } from "../../usersSlice";
import "./singleuserform.scss";
import { selectAllCompanies, useGetCompaniesQuery } from "../../../companies/rtkCompaniesSlice";
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from "../../rtkUsersSlice";
import { useGetUsergroupsQuery } from "../../../settings/rtkSettingsSlice";

const SingleUserForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    //const thisUser = useSelector(state => selectUserById(state, params.userId));

    const { thisUser } = useGetUsersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            thisUser: data?.find((user) => user.id === params.userId)
        })
    })

    const usergroupsResult = useGetUsergroupsQuery();
    console.log(thisUser);

    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();

    const { data: companies, isSuccess } = useGetCompaniesQuery();
    console.log(companies);


    //const status = useSelector(state => state.companies.status);

    // useLayoutEffect(() => {
    //     if (status === "idle") {
    //         dispatch(fetchAllCompanies());
    //     }
    // }, [status, dispatch]);

    const [firstName, setFirstName] = useState(thisUser?.firstName);
    const [lastName, setLastName] = useState(thisUser?.lastName);
    const [company, setCompany] = useState(thisUser?.company.id);
    const [userRole, setUserRole] = useState(thisUser?.userRole);
    const [usergroup, setUsergroup] = useState(thisUser?.usergroup?.id)
    const [email, setEmail] = useState(thisUser?.email);
    const [mobilePhone, setMobilePhone] = useState(thisUser?.mobilePhone);

    const firstNameChanged = (e) => setFirstName(e.target.value);
    const lastNameChanged = (e) => setLastName(e.target.value);
    const companyChanged = (event, values) => setCompany(values.id);
    const userRoleChanged = (e) => setUserRole(e.target.value);
    const usergroupChanged = (e) => setUsergroup(e.target.value);
    const emailChanged = (e) => setEmail(e.target.value);
    const mobilePhoneChanged = (e) => setMobilePhone(e.target.value);

    const saveButtonClicked = async (e) => {
        e.preventDefault();
        const editedUserObject = {
            id: thisUser.id,
            firstName: firstName,
            lastName: lastName,
            companyId: company,
            userRole: userRole,
            usergroup,
            email: email,
            mobilePhone: mobilePhone,
            telegramId: thisUser.telegramId
        }

        console.log('save button was clicked');
        console.log(thisUser);

        try {
            // dispatch(updatedSingleUser(editedUserObject));
            await updateUser(editedUserObject).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            navigate(-1);
        }

    }

    const deleteThisUser = async (thisUserId) => {
        console.log('delete button got clicked');
        console.log(thisUserId);
        try {
            await deleteUser(thisUserId).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            navigate("../../users");
        }
    }

    return (
        <div className="singleUserForm">
            <div className="singleUserFormContainer">
                <div className="header">
                    <div className="title">
                        <h3>{params.userId}</h3>
                    </div>
                    <div className="deleteBtn">
                        <RedButton
                            startIcon={<DeleteForeverIcon />}
                            size="small"
                            onClick={() => deleteThisUser(thisUser.id)}
                        >Delete</RedButton>
                    </div>
                </div>
                <div className="content">
                    <form onSubmit={saveButtonClicked}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>First Name:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={firstNameChanged} value={firstName}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Last Name:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={lastNameChanged} value={lastName}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Company:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Autocomplete
                                        size="small"
                                        disablePortal
                                        onChange={companyChanged}
                                        //options={companies}
                                        options={isSuccess ? companies : []}
                                        value={thisUser?.company}
                                        isOptionEqualToValue={(option, value) => {
                                            console.log(option);
                                            console.log(value);
                                            return option?.id === value?.id;
                                        }}
                                        getOptionLabel={(option) => {
                                            return option.name
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )
                                        }}
                                        renderInput={function (params) {
                                            return <TextField {...params} className="field-input__input" variant="outlined" size="small" sx={{ width: "100%", height: "30px" }} />
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>User Role:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Select
                                        size="small"
                                        value={userRole}
                                        onChange={userRoleChanged}
                                        sx={{ width: "100%", height: "30px" }}
                                    >
                                        <MenuItem value="superuser">Super User</MenuItem>
                                        <MenuItem value="agent">Agent</MenuItem>
                                        <MenuItem value="enduser">End User</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {userRole === 'agent' &&
                            <div className="field-input">
                                <div className="field-input__header">
                                    <label>Usergroup:</label>
                                </div>
                                <div className="field-input__wrapper">
                                    <div className="field-input__container">
                                        <Select
                                            size="small"
                                            value={usergroupsResult.isSuccess ? usergroup : ""}
                                            onChange={usergroupChanged}
                                            sx={{ width: "100%", height: "30px" }}
                                        >
                                            {usergroupsResult.isSuccess && usergroupsResult.data.map((usergroup) => {
                                                console.log(usergroup);
                                                return <MenuItem key={usergroup.id} value={usergroup.id}>{usergroup.description}</MenuItem>
                                            })}
                                            {/* <MenuItem value="superuser">Super User</MenuItem>
                                        <MenuItem value="agent">Agent</MenuItem>
                                        <MenuItem value="enduser">End User</MenuItem> */}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Email:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={emailChanged} value={email}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Mobile Phone:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={mobilePhoneChanged} value={mobilePhone}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="submitBtn">
                            <LightGreenButton
                                type="submit"
                                startIcon={<SaveIcon />}
                                size="small"
                            >Save</LightGreenButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleUserForm;