import { Box, Button, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export const dataTableColumns = {
    infoproducts: [
      {
        field: 'id',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >ID</Typography>
          </Box>
        ),
        width: 50,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="company__name">{`${params.row.id}`}</p>
          </Box>
        )
      },
      {
        field: 'description',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Description</Typography>
          </Box>
        ),
        width: 400,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="infoproduct__description">{`${params.row.description}`}</p>
          </Box>
        )
      },
      {
        field: 'users',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Users</Typography>
          </Box>
        ),
        width: 200,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            {params.row.selectedUsers.map( (user) => {
              return <p key={user.id} className="infoproduct__user">{`${user.firstName} ${user.lastName}`}</p>
            })}
          </Box>
        )
      },
      {
        field: 'mediaType',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Media Type</Typography>
          </Box>
        ),
        width: 120,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="infoproduct__mediatype">{`${params.row.mediaType}`}</p>
          </Box>
        )
      },
      {
        field: 'creationSchedule',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Schedule</Typography>
          </Box>
        ),
        width: 120,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="infoproduct__schedule">{`${params.row.creationSchedule}`}</p>
          </Box>
        )
      },
      {
        field: 'trigger',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Trigger</Typography>
          </Box>
        ),
        width: 120,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <p className="infoproduct__trigger">{`${params.row.trigger}`}</p>
          </Box>
        )
      },
      {
        field: 'messageContent',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >Message</Typography>
          </Box>
        ),
        width: 400,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Box>
            <pre className="infoproduct__messagecontent">{`${params.row.messageContent.slice(0,50)}....`}</pre>
          </Box>
        )
      },
      {
        field: 'actions',
        renderHeader: (params) => (
          <Box
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '12px', color: '#004d22' }}
            >ACTIONS</Typography>
          </Box>
        ),
        width: 180,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <div className="sjalala">
            <div className="beforehover">
            </div>
            <div className="afterhover">
              <Button sx={{ color: "#000", borderRadius: "3px", padding: "3px 10px" }} className="appie" color="secNormalGreen" size="small" variant="contained" startIcon={<InfoIcon />}>Details</Button>
            </div>
          </div>
        )
      }
    ]
}