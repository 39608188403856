import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { DarkGreenButton } from "../../../../../theme/buttons";
import { selectAllIntegrations } from "../../../settingsSlice";
import IntegrationsDataTable from "../../components/datatable/IntegrationsDataTable";

import "./integrationsmainpage.scss";
import { useGetIntegrationsQuery } from "../../../rtkSettingsSlice";

const IntegrationsMainPage = () => {
    // const integrations = useSelector(selectAllIntegrations);
    // const { data: integrations } = useGetIntegrationsQuery();
    const navigate = useNavigate();

    const addButtonClicked = () => {
        navigate("new");
    }
    return (
        <div className="integrationsmainpage">
            <div className="header">
                <div className="title">
                    <h3>Integrations</h3>
                </div>
                <div className="newButton">
                    <DarkGreenButton
                        onClick={addButtonClicked}
                        startIcon={<AddCircleOutlinedIcon />}
                        size="small"
                    >Add Integration</DarkGreenButton>
                </div>
            </div>
            <IntegrationsDataTable />
        </div>
    )
}

export default IntegrationsMainPage;