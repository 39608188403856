import ReturnBar from "../../../../components/returnbar/ReturnBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import AddInfoProductForm from "../../components/addinfoproductform/AddInfoProductsForm";
import "./addnewinfoproduct.scss";

const AddNewInfoProduct = () => {
    return (
        <div className="newInfoProduct">
            <Sidebar/>
            <ReturnBar/>
            <AddInfoProductForm/>
        </div>
    )
}

export default AddNewInfoProduct