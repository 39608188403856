import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns"
import { selectCommunicationTasksData } from "../../../infoproducts/infoproductsSlice";
import { dataTableColumns } from "./dataTableColumns";
import { useGetAllCommunicationTasksQuery } from "../../../infoproducts/rtkInfoproductsSlice";
import "./communicationtasksdatatable.scss";

const CommunicationTasksDataTable = () => {

    const communicationTasksData = useSelector(selectCommunicationTasksData);
    const { data: communicationTasks, isSuccess } = useGetAllCommunicationTasksQuery({
        fromDate: format(parseISO(communicationTasksData.fromDateFilter), "yyyy-MM-dd"),
        untilDate: format(parseISO(communicationTasksData.untilDateFilter), "yyyy-MM-dd")
    });

    const MyDataTable = styled(DataGrid)({

        '.MuiDataGrid-row': {
            backgroundColor: '#47aa7710',

            '&:hover': {
                backgroundColor: '#E0EFE9',
                cursor: 'pointer',

                '.beforehover': {
                    opacity: '0'
                }
            }
        }

    });

    return (
        <div className="communicationtasksdatatable">
            {isSuccess && communicationTasks.length > 0
                ? <MyDataTable
                    rows={communicationTasks}
                    columns={dataTableColumns.communicationtasks}
                    headerHeight={40}
                    rowHeight={30}
                />
                : <div>No records.</div>
            }
        </div>
    )
}

export default CommunicationTasksDataTable;