import { Button, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";
import { LightGreenButton, RedButton } from "../../../../../theme/buttons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./addnewintegration.scss";
import { addNewIntegration } from "../../../settingsSlice";
import ReturnBar from "../../../../../components/returnbar/ReturnBar";
import { useAddIntegrationMutation } from "../../../rtkSettingsSlice";

const AddNewIntegration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const [integrationType, setIntegrationType] = useState("powerautomate");
    const [endpoint, setEndpoint] = useState("");
    const [securityToken, setSecurityToken] = useState("");
    const [weekdays, setWeekdays] = useState(["monday", "tuesday", "wednesday", "thursday", "friday"]);
    const [frequencyQuantity, setFrequencyQuantity] = useState(5);
    const [frequencyUnit, setFrequencyUnit] = useState("minutes");
    const [timeslotFromTimeHours, setTimeslotFromTimeHours] = useState("00");
    const [timeslotFromTimeMinutes, setTimeslotFromTimeMinutes] = useState("00");
    const [timeslotUntilTimeHours, setTimeslotUntilTimeHours] = useState("00");
    const [timeslotUntilTimeMinutes, setTimeslotUntilTimeMinutes] = useState("00");

    const descriptionChanged = (e) => setDescription(e.target.value);
    const integrationTypeChanged = (e) => setIntegrationType(e.target.value);
    const endpointChanged = (e) => setEndpoint(e.target.value);
    const securityTokenChanged = (e) => setSecurityToken(e.target.value);
    const weekdaysChanged = (e, newWeekDays) => setWeekdays(newWeekDays);
    const frequencyQuantityChanged = (e) => setFrequencyQuantity(e.target.value);
    const frequencyUnitChanged = (e) => setFrequencyUnit(e.target.value);
    const timeslotFromTimeHoursChanged = (e) => setTimeslotFromTimeHours(e.target.value);
    const timeslotFromTimeMinutesChanged = (e) => setTimeslotFromTimeMinutes(e.target.value);
    const timeslotUntilTimeHoursChanged = (e) => setTimeslotUntilTimeHours(e.target.value);
    const timeslotUntilTimeMinutesChanged = (e) => setTimeslotUntilTimeMinutes(e.target.value);

    const [addIntegration] = useAddIntegrationMutation();

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        const newIntegrationObject = {
            description,
            integrationType,
            endpoint,
            securityToken,
            refreshSchedule: [
                {
                    weekdays,
                    frequencyQuantity,
                    frequencyUnit,
                    timeslotFrom: timeslotFromTimeHours + timeslotFromTimeMinutes,
                    timeslotUntil: timeslotUntilTimeHours + timeslotUntilTimeMinutes

                }
            ]
        };

        console.log(newIntegrationObject);

        try {
            // dispatch(addNewIntegration(newIntegrationObject))
            await addIntegration(newIntegrationObject).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            setDescription("")
            setEndpoint("");

            navigate("../../integrations");

        }
    }

    return (
        <div className="addIntegrationForm">
            <div className="addIntegrationFormContainer">
                <div className="header">
                    <h3>New Integration</h3>
                </div>
                <div className="content">
                    <form onSubmit={formSubmitHandler}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Description</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={descriptionChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Type</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Select
                                        size="small"
                                        value={integrationType}
                                        onChange={integrationTypeChanged}
                                        sx={{ width: "100%", height: "30px" }}>
                                        <MenuItem value="powerautomate">MS Power Automate REST API</MenuItem>
                                        <MenuItem value="enduserapi" disabled>Match-Online EndUser API (to be developed)</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Endpoint</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" multiline minRows={3} onChange={endpointChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Security Token</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" type="password" onChange={securityTokenChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Refresh Schedule</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <div className="refresh-schedule">
                                        <div className="weekdays">
                                            <div className="title">
                                                <p>On these days</p>
                                            </div>
                                            <ToggleButtonGroup
                                                value={weekdays}
                                                onChange={weekdaysChanged}
                                                className="weekdaysToggleButtonGroup"
                                            >
                                                <ToggleButton className="weekdayToggleButton" value="monday">M</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="tuesday">T</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="wednesday">W</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="thursday">T</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="friday">F</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="saturday">S</ToggleButton>
                                                <ToggleButton className="weekdayToggleButton" value="sunday">S</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                        <div className="timeslot">
                                            <div className="title">
                                                <p>Between</p>
                                            </div>
                                            <div className="container">
                                                <div className="fromTime">
                                                    <div className="hours">
                                                        <TextField size="small" placeholder="hh" onChange={timeslotFromTimeHoursChanged}></TextField>
                                                    </div>
                                                    <div className="label">
                                                        :
                                                    </div>
                                                    <div className="minutes">
                                                        <TextField size="small" placeholder="mm" onChange={timeslotFromTimeMinutesChanged}></TextField>
                                                    </div>
                                                </div>
                                                <div className="label">
                                                    and
                                                </div>
                                                <div className="untilTime">
                                                    <div className="hours">
                                                        <TextField size="small" placeholder="hh" onChange={timeslotUntilTimeHoursChanged}></TextField>
                                                    </div>
                                                    <div className="label">
                                                        :
                                                    </div>
                                                    <div className="minutes">
                                                        <TextField size="small" placeholder="mm" onChange={timeslotUntilTimeMinutesChanged}></TextField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frequency">
                                            <div className="title">
                                                <p>Refresh every</p>
                                            </div>
                                            <div className="container">
                                                <div className="quantity">
                                                    <TextField size="small" type="number" value={frequencyQuantity} onChange={frequencyQuantityChanged}></TextField>
                                                </div>
                                                <div className="unit">
                                                    <Select
                                                        size="small"
                                                        value={frequencyUnit}
                                                        onChange={frequencyUnitChanged}
                                                        sx={{ width: "100%" }}>
                                                        <MenuItem value="minutes">minutes</MenuItem>
                                                        <MenuItem value="hours">hours</MenuItem>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div className="cancelBtn">
                                <RedButton
                                    startIcon={<CancelIcon />}
                                    size="small"
                                    onClick={() => navigate("../")}
                                >Cancel</RedButton>
                            </div>
                            <div className="submitBtn">
                                <LightGreenButton
                                    type="submit"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                >Save</LightGreenButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddNewIntegration;