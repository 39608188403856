import { Box, Button, MenuItem, Modal, Select } from "@mui/material";
import "./conversationquickactions.scss";
import { useState } from "react";
import { useDeleteMenuFromChatMutation, useGetMenuFromChatQuery, useLazyGetMenuFromChatQuery, useSendMenuToChatMutation } from "../../rtkConversationsSlice";

const ConversationQuickActions = ({ chatId }) => {

    const [selectedAction, setSelectedAction] = useState("");
    const [currentMenu, setCurrentMenu] = useState([]);
    const [getMenuModalOpen, setGetMenuModalOpen] = useState(false);

    const selectedActionChanged = (e) => setSelectedAction(e.target.value);
    const handleGetMenuModalOpen = () => setGetMenuModalOpen(true);
    const handleGetMenuModalClose = () => setGetMenuModalOpen(false);


    const [sendMenuToChat] = useSendMenuToChatMutation();
    const [trigger, lastArg] = useLazyGetMenuFromChatQuery();
    const [deleteMenuFromChat] = useDeleteMenuFromChatMutation();

    const handleExecBtnClicked = async (e) => {
        console.log('got clicked');

        if (selectedAction === 'activate') {
            console.log('send activation message')
            console.log(chatId);
        }
        if (selectedAction === 'consultcurrentmenu') {
            console.log('check current menu of this Chat')
            console.log(chatId);
            try {
                const menuFromChat = await trigger(chatId).unwrap();
                setCurrentMenu(menuFromChat);
                handleGetMenuModalOpen();
            } catch (err) {
                console.log(err);
            }
        }

        if (selectedAction === 'sendmenubtn') {
            console.log("send menu button")
            console.log(chatId);
            try {
                await sendMenuToChat(chatId);
            } catch (err) {
                console.log(err);
            }

        }

        if (selectedAction === 'deletemenubtn') {
            console.log("delete menu button")
            console.log(chatId);
            try {
                await deleteMenuFromChat(chatId);
            } catch (err) {
                console.log(err);
            }

        }


    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div className="conversationQuickActions">
            <div className="dropDownMenu">
                <Select
                    size="small"
                    value={selectedAction}

                    onChange={selectedActionChanged}
                    sx={{
                        // width: "100%",
                        width: "400px",
                        height: "30px"
                    }}
                >
                    <MenuItem value="activate">Send account activated confirmation message</MenuItem>
                    <MenuItem value="consultcurrentmenu">Show current Menu options of this Chat</MenuItem>
                    <MenuItem value="sendmenubtn">Send Menu Button to Chat</MenuItem>
                    <MenuItem value="deletemenubtn">Delete Menu Button from Chat</MenuItem>
                </Select>
            </div>
            <div className="execBtn">
                <Button
                    size="small"
                    variant="contained"
                    onClick={handleExecBtnClicked}
                >Execute</Button>
            </div>
            <Modal
                open={getMenuModalOpen}
                onClose={handleGetMenuModalClose}
            >
                <Box sx={style}>
                    {currentMenu.map((menuItem) => {
                        return (
                            <p key={menuItem.command}>{menuItem.command} - {menuItem.description}</p>
                        )
                    })}
                </Box>
            </Modal>
        </div>
    )
}

export default ConversationQuickActions;