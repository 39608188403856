import "./login.scss";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { currentUserUpdated } from "../../features/users/usersSlice";
import { useDispatch } from "react-redux";
import { baseURL } from "../../app/baseURL";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { setCredentials } from "../../features/auth/authSlice";

const Login = () => {
    const [login, { isLoading }] = useLoginMutation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("false");
    const [success, setSuccess] = useState(false);

    const usernameChanged = (e) => setUsername(e.target.value);
    const passwordChanged = (e) => setPassword(e.target.value);

    useEffect(() => {
        setErrorMsg("");
    }, [username, password]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const credentialsObj = {
                username,
                password
            };

            const userData = await login(credentialsObj).unwrap();
            dispatch(setCredentials(userData));
            navigate("/");

        } catch (err) {
            setErrorMsg(err.response.data.message);
        }
    }



    return (
        <div className="login">
            <form onSubmit={handleFormSubmit}>
                <div className="loginContainer">
                    <TextField className="login-field" variant="outlined" label="Username" size="small" type="email" onChange={usernameChanged}></TextField>
                    <TextField className="login-field" variant="outlined" label="Password" size="small" type="password" onChange={passwordChanged}></TextField>
                    <Button className="login-btn" variant="contained" type="submit" sx={{ bgcolor: 'primary.main' }}>Login</Button>
                    {errorMsg &&
                        <p>{errorMsg}</p>
                    }
                </div>
            </form>
        </div>
    );

}

export default Login;