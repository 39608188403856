import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import companiesReducer from "../features/companies/companiesSlice";
import usersReducer from "../features/users/usersSlice";
import conversationsReducer from "../features/conversations/conversationsSlice";
import settingsReducer from "../features/settings/settingsSlice";
import infoproductsReducer from "../features/infoproducts/infoproductsSlice";
import receptionControlReducer from "../features/receptioncontrol/receptionControlSlice";
import { apiSlice } from "../features/api/apiSlice";
import authReducer from "../features/auth/authSlice";

export const store = configureStore({
  reducer: {
    //companies: companiesReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    users: usersReducer,
    conversations: conversationsReducer,
    settings: settingsReducer,
    receptioncontrol: receptionControlReducer,
    infoproducts: infoproductsReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
});