import "./adduserform.scss";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { LightGreenButton } from "../../../../theme/buttons";
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCompanies, selectAllCompanies } from "../../../companies/companiesSlice";
import { useLayoutEffect, useState, useEffect } from "react";
import { addNewUser, selectCurrentUser } from "../../usersSlice";
import { useNavigate } from "react-router-dom";
import { useGetCompaniesQuery } from "../../../companies/rtkCompaniesSlice";
import { useAddUserMutation } from "../../rtkUsersSlice";
import { useGetUsergroupsQuery } from "../../../settings/rtkSettingsSlice";

const AddUserForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);
    //const companies = useSelector(selectAllCompanies);
    const companiesResult = useGetCompaniesQuery();
    const usergroupsResult = useGetUsergroupsQuery();
    console.log(usergroupsResult.data);
    //const status = useSelector(state => state.companies.status);
    const [addUser, addUserStatusCodes] = useAddUserMutation();

    // useLayoutEffect(() => {
    //     if (status === "idle") {
    //         dispatch(fetchAllCompanies());
    //     }
    // }, [status, dispatch]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [userRole, setUserRole] = useState("enduser");
    const [usergroup, setUsergroup] = useState("");
    console.log(usergroup);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");

    const firstNameChanged = (e) => setFirstName(e.target.value);
    const lastNameChanged = (e) => setLastName(e.target.value);
    const companyChanged = (e, newCompany) => setCompany(newCompany.id);
    const userRoleChanged = (e) => setUserRole(e.target.value);
    const usergroupChanged = (e) => setUsergroup(e.target.value);
    const emailChanged = (e) => setEmail(e.target.value);
    const passwordChanged = (e) => setPassword(e.target.value);
    const mobilePhoneChanged = (e) => setMobilePhone(e.target.value);

    const saveButtonClicked = async (e) => {
        e.preventDefault();
        const userObject = {
            firstName,
            lastName,
            company,
            userRole,
            usergroup,
            email,
            password,
            mobilePhone
        };

        try {
            //dispatch(addNewUser(userObject));
            await addUser(userObject);

        } catch (err) {
            console.log(err);
        } finally {
            setFirstName("");
            setLastName("");
            setCompany("");
            setEmail("");
            setPassword("");
            setMobilePhone("");

            navigate("../../users");
        }

    }


    return (
        <div className="addUserForm">
            <div className="addUserFormContainer">
                <div className="header">
                    <h3>New User</h3>
                </div>
                <div className="content">
                    <form onSubmit={saveButtonClicked}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>User Role:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Select
                                        size="small"
                                        value={userRole}
                                        onChange={userRoleChanged}
                                        sx={{ width: "100%", height: "30px" }}
                                    >
                                        <MenuItem value="superuser">Super User</MenuItem>
                                        <MenuItem value="agent">Agent</MenuItem>
                                        <MenuItem value="enduser">End User</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {userRole === 'agent' &&
                            <div className="field-input">
                                <div className="field-input__header">
                                    <label>Usergroup:</label>
                                </div>
                                <div className="field-input__wrapper">
                                    <div className="field-input__container">
                                        <Select
                                            size="small"
                                            value={usergroup}
                                            onChange={usergroupChanged}
                                            sx={{ width: "100%", height: "30px" }}
                                        >
                                            {usergroupsResult.isSuccess && usergroupsResult.data.map((usergroup) => {
                                                return <MenuItem key={usergroup.id} value={usergroup.id}>{usergroup.description}</MenuItem>
                                            })}
                                            {/* <MenuItem value="superuser">Super User</MenuItem>
                                        <MenuItem value="agent">Agent</MenuItem>
                                        <MenuItem value="enduser">End User</MenuItem> */}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>First Name:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={firstNameChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Last Name:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={lastNameChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Company:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Autocomplete
                                        size="small"
                                        disablePortal
                                        onChange={companyChanged}
                                        options={companiesResult.isSuccess ? companiesResult.data : []}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )
                                        }}
                                        renderInput={function (params) {
                                            return <TextField {...params} className="field-input__input" variant="outlined" size="small" sx={{ width: "100%", height: "30px" }} />
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Email:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={emailChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        {userRole !== 'enduser' &&
                            <div className="field-input">
                                <div className="field-input__header">
                                    <label>Password:</label>
                                </div>
                                <div className="field-input__wrapper">
                                    <div className="field-input__container">
                                        <TextField size="small" variant="outlined" type="password" className="field-input__input" onChange={passwordChanged}></TextField>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Mobile Phone:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={mobilePhoneChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="submitBtn">
                            <LightGreenButton
                                type="submit"
                                startIcon={<SaveIcon />}
                                size="small"
                            >Save</LightGreenButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUserForm;