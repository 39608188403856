import { createSlice } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";

const initialState = {
    untilDateFilter: formatISO(new Date()),
    fromDateFilter: formatISO(new Date()),
    filterInformation: {
        currentGrowerFilter: '',
        currentBuyerFilter: '',
        currentEabStatusFilter: [],
        currentEabStatusSuperFilter: [],
        currentReceivementStatusFilter: [],
        currentCaseStatusFilter: ['open', 'inprogress'],
        allCasesAndFilters: [],
        filteredCases: []
    },
    currentCaseDetails: {
        receivedStatus: {
            dataReceived: false,
            differences: false,
            compareTo: '',
            colorCode: 'red'
        },
        eabStatus: {
            dataReceived: false,
            differences: false,
            compareTo: '',
            colorCode: 'red'
        }
    }
}

const receptionControlSlice = createSlice({
    name: 'receptioncontrol',
    initialState,
    reducers: {
        setFromDateFilter: (state, action) => {
            state.fromDateFilter = action.payload.fromDate;
        },
        setUntilDateFilter: (state, action) => {
            state.untilDateFilter = action.payload.untilDate
        },
        setGrowerFilter: (state, action) => {
            state.filterInformation.currentGrowerFilter = action.payload;
        },
        setBuyerFilter: (state, action) => {
            state.filterInformation.currentBuyerFilter = action.payload;
        },
        setEabStatusSuperFilter: (state, action) => {
            state.filterInformation.currentEabStatusSuperFilter = action.payload;
        },
        setReceivementStatusFilter: (state, action) => {
            state.filterInformation.currentReceivementStatusFilter = action.payload;
        },
        setCaseStatusFilter: (state, action) => {
            state.filterInformation.currentCaseStatusFilter = action.payload;
        },
        setEabStatusFilter: (state, action) => {
            state.filterInformation.currentEabStatusFilter = action.payload;
        },
        setAllCasesAndFilters: (state, action) => {
            state.filterInformation.allCasesAndFilters = action.payload;
        },
        setFilteredCases: (state, action) => {
            state.filterInformation.filteredCases = action.payload;
        },
        setFilterInformation: (state, action) => {
            state.filterInformation = action.payload;
        },
        setCurrentCaseDetails: (state, action) => {
            state.currentCaseDetails = action.payload;
        }

    }
})

export const {
    setFromDateFilter,
    setUntilDateFilter,
    setGrowerFilter,
    setBuyerFilter,
    setEabStatusSuperFilter,
    setReceivementStatusFilter,
    setCaseStatusFilter,
    setEabStatusFilter,
    setAllCasesAndFilters,
    setFilteredCases,
    setFilterInformation,
    setCurrentCaseDetails
} = receptionControlSlice.actions;

export default receptionControlSlice.reducer;

export const selectFromDateFilter = (state) => state.receptioncontrol.fromDateFilter;
export const selectUntilDateFilter = (state) => state.receptioncontrol.untilDateFilter;
export const selectGrowerFilter = (state) => state.receptioncontrol.filterInformation.currentGrowerFilter;
export const selectBuyerFilter = (state) => state.receptioncontrol.filterInformation.currentBuyerFilter;
export const selectAllCasesAndFilters = (state) => state.receptioncontrol.filterInformation.allCasesAndFilters;
export const selectFilteredCases = (state) => state.receptioncontrol.filterInformation.filteredCases;
export const selectFilterInformation = (state) => state.receptioncontrol.filterInformation;
export const selectCurrentCaseDetails = (state) => state.receptioncontrol.currentCaseDetails;