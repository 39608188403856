import "./companiesdatatable.scss";
import { styled } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { dataTableColumns } from "./dataTableColumns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllCompanies } from "../../companiesSlice";
import { useNavigate } from "react-router-dom";
import { useGetCompaniesQuery } from "../../rtkCompaniesSlice";

const CompaniesDatatable = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const {
    data: companies,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetCompaniesQuery();
  console.log(companies);

  // const companies = useSelector(state => state.companies.companies);
  // const status = useSelector(state => state.companies.status);

  // useEffect( () => {
  //   if(status === "idle"){
  //     dispatch(fetchAllCompanies());
  //   }
  // },[status, dispatch]);

  const MyDataTable = styled(DataGrid)({

    '.MuiDataGrid-row': {
      backgroundColor: '#47aa7710',

      '&:hover': {
        backgroundColor: '#E0EFE9',
        cursor: 'pointer',

        '.beforehover': {
          opacity: '0'
        }
      }
    }

  });

  const rowClicked = (e) => {
    navigate(e.id);
  }

  let content;

  return (
    <div className="datatable">
        <MyDataTable
          rows={isSuccess ? companies : []}
          columns={dataTableColumns.companies}
          headerHeight={40}
          onRowClick={rowClicked}
        />
    </div>
  )
}

export default CompaniesDatatable;