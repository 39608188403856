import "./settingsmainpage.scss";
import Sidebar from "../../../../components/sidebar/Sidebar";
import SettingsSubSidebar from "../../components/subsidebar/SettingsSubSidebar";
import SettingsContent from "../components/content/SettingsContent";
import { useSelector } from "react-redux";
import { selectSelectedMenu } from "../../settingsSlice";
import { Outlet, useLocation } from "react-router-dom";

const SettingsMainPage = () => {
    const location = useLocation();
    const settingsHomePage = location.pathname.slice(1) === "settings";
    const selectedMenu = useSelector(state => state.selectedMenu);

    return (
        <div className="main">
            <Sidebar/>
            <SettingsSubSidebar/>
            {settingsHomePage
                ? <SettingsContent />
                : <Outlet/>
            }
        </div>
    )
}

export default SettingsMainPage;