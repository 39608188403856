import ConversationListItem from "../conversationlistitem/ConversationListItem";
import "./conversationmenubody.scss";
import { useGetConversationsQuery } from "../../rtkConversationsSlice";

const ConversationMenuBody = () => {
    const { data: conversations, isSuccess } = useGetConversationsQuery();

    return (
        <div className="conversationMenuBody">
            {isSuccess && conversations.map((conversation) => {
                return (
                    <ConversationListItem
                        key={conversation.chatid}
                        conversation={conversation}
                    />
                )
            })}
        </div>
    )
}

export default ConversationMenuBody;