import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { DarkGreenButton } from "../../../../../theme/buttons";
import "./usergroupsmainpage.scss";
import UsergroupsDataTable from "../../components/datatable/UsergroupsDataTable";
import { useNavigate } from "react-router-dom";

const UsergroupsMainPage = () => {

    const navigate = useNavigate();

    const addButtonClicked = () => {
        navigate("new");
    }

    return (
        <div className="usergroupsMainPage">
            <div className="header">
                <div className="title">
                    <h3>Usergroups</h3>
                </div>
                <div className="newButton">
                    <DarkGreenButton
                        onClick={addButtonClicked}
                        startIcon={<AddCircleOutlinedIcon />}
                        size="small"
                    >Add Usergroup</DarkGreenButton>
                </div>
            </div>
            <UsergroupsDataTable />
        </div>
    )
}

export default UsergroupsMainPage;