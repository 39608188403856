import { useLocation } from "react-router-dom";
import Sidebar from "../../../../components/sidebar/Sidebar";
import CompaniesDatatable from "../../components/datatable/CompaniesDataTable";
import CompaniesNavbar from "../../components/navbar/CompaniesNavbar";
import "./companiesmainpage.scss";

const CompaniesMainPage = () => {

    const location = useLocation();

    return (
        <div className="companiesContainer">
            <Sidebar/>
            <div className="companiesContent">
                <CompaniesNavbar/>
                <CompaniesDatatable/>
            </div>
        </div>
    )
}

export default CompaniesMainPage;