import FeedIcon from '@mui/icons-material/Feed';
import { TextField } from "@mui/material";
import { DarkGreenButton } from "../../../../theme/buttons";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import "./infoproductsnavbar.scss";
import { useNavigate } from "react-router-dom";

const InfoProductsNavbar = () => {
    const navigate = useNavigate();

    const addButtonClicked = (e) => navigate("/infoproducts/new");

    return (
        <div className="infoproductsnavbar">
            <div className="titleCounter">
                <FeedIcon fontSize="medium" />
                <span>3 Infoproducts</span>
            </div>
            <div className="searchbox">
                <TextField variant="outlined" size="small" placeholder="Search" margin="none" sx={{ width: '300px'}} />
            </div>
            <div className="buttonGroup">
                <DarkGreenButton
                    onClick={addButtonClicked}
                    startIcon={<AddCircleOutlinedIcon />}
                    size="small"
                >Add Infoproduct</DarkGreenButton>
            </div>
        </div>
    )
}

export default InfoProductsNavbar;