import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../users/usersSlice";
import useAuth from "../../hooks/useAuth";
import { selectRTKCurrentUser } from "./authSlice";

const RequireAuth = ({ allowedRoles, allowedMenu }) => {
    const user = useSelector(selectCurrentUser);
    const location = useLocation();
    const currentUsertje = useSelector(selectRTKCurrentUser);
    if (allowedMenu && currentUsertje?.userRole === 'agent') {
        return (
            currentUsertje?.allowedAccess?.includes(allowedMenu)
                ? <Outlet />
                : currentUsertje?.accessToken
                    ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                    : <Navigate to="/login" state={{ from: location }} replace />
        )

    } else {
        return (
            allowedRoles.includes(currentUsertje?.userRole)
                ? <Outlet />
                : currentUsertje?.accessToken
                    ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                    : <Navigate to="/login" state={{ from: location }} replace />
        )
    }
}

export default RequireAuth;
