import "./userssubsidebar.scss";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

const UsersSubSidebar = () => {
    return (
        <div className="subsidebar">
            <div className="subsidebarContainer">
                <div className="top">
                    <FilterListOutlinedIcon/>
                    <span>Advanced Filter</span>
                </div>
                <div className="center">
                    <ul>
                        <li>
                            Super Users
                        </li>
                        <li>
                            End Users
                        </li>
                    </ul>
                </div>
                <div className="bottom">
                    <div>
                        <AddCircleOutlinedIcon/>
                        <span>Add Filter</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersSubSidebar;