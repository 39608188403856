import { uniqBy } from "lodash";

//functie wordt gebruik in de rtkReceptionControlSlice bij onQueryStarted functie
export const uniqueSelectableBuyers = (cases) => {
    let selectableBuyers = [];
    cases.forEach((singleCase) => {
        const singleBuyerObject = {
            buyerCode: singleCase.batchData.order_buyer_code,
            buyerName: singleCase.batchData.order_buyer_name
        }
        selectableBuyers.push(singleBuyerObject);
    });
    const uniqueSelectableBuyers = uniqBy(selectableBuyers, (buyer) => buyer.buyerCode);
    return uniqueSelectableBuyers;
}