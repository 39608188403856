import "./singlecompany.scss";
import ReturnBar from "../../../../components/returnbar/ReturnBar"
import Sidebar from "../../../../components/sidebar/Sidebar"
import SingleCompanyForm from "../../components/singlecompanyform/SingleCompanyForm"

const SingleCompany = () => {
    return (
        <div className="singleCompany">
            <Sidebar/>
            <ReturnBar/>
            <SingleCompanyForm/>
        </div>
    )
}

export default SingleCompany;