import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarShortCode } from "../../../../utils";
import { conversationSelected, selectAllConversations } from "../../conversationsSlice";
import "./conversationlistitem.scss";
import VerifiedIcon from '@mui/icons-material/Verified';

const ConversationListItem = ({ conversation }) => {

    const dispatch = useDispatch();
    const selectedConversation = useSelector(state => state.conversations.currentConversation);
    const isSelected = selectedConversation === conversation.chatid;


    const userShortCode = getAvatarShortCode(conversation.firstname, conversation.lastname);

    const conversationListItemClicked = (event) => {
        dispatch(conversationSelected(event.currentTarget.id));
    }

    return (
        <div
            onClick={conversationListItemClicked}
            id={conversation.chatid}
            className={`conversationListItem ${isSelected ? "selected" : ""}`}
        >
            <div className="userAvatar">
                <Avatar
                    sx={{ fontSize: "14px" }}
                >{userShortCode}</Avatar>
            </div>
            <div className="userData">
                <div className="userName">
                    <h3>{conversation.firstname} {conversation.lastname}</h3>
                    {conversation.isverified &&
                        <VerifiedIcon
                            color="blue"
                            fontSize="small"
                        />
                    }
                </div>
                <p>Phone: {conversation.phonenumber}</p>
            </div>
        </div>
    )
}

export default ConversationListItem;