import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../../../app/baseURL";
import { fetchDatasetResults, selectDatasetById, selectStatus } from "../../../settingsSlice";
import { dataTableColumns } from "./datatableColumns";
import "./singledatasetresults.scss";
import { useGetDatasetResultsQuery, useGetDatasetsQuery, useLazyGetDatasetResultsQuery } from "../../../rtkSettingsSlice";
import { selectRTKCurrentUser } from "../../../../auth/authSlice";

const SingleDatasetResults = () => {
    // const dispatch = useDispatch();
    const params = useParams();
    //const thisDataset = useSelector(state => selectDatasetById(state, params.datasetId));
    const { thisDataset } = useGetDatasetsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            thisDataset: data?.find((dataset) => dataset.id === params.datasetId)
        })
    })

    // const status = useSelector(selectStatus);
    // const currentUser = useSelector(selectRTKCurrentUser);

    const [thisDatasetResults, setThisDatasetResults] = useState([]);
    const [dataTableColumns, setDataTableColumns] = useState([]);
    // const [requestStatus, setRequestStatus] = useState("idle");

    const [trigger, result] = useLazyGetDatasetResultsQuery({
        selectFromResult: ({ data, isSuccess }) => {
            if (isSuccess) {
                return data;
            }
        }
    });




    /*
    
    useEffect(() => {
        if (status === "idle") {
            dispatch(fetchDatasetResults(thisDataset.id));
        }
    }, [status, dispatch]);

    */

    const refreshBtnClicked = async () => {
        console.log("refreshButton clicked");

        const myDataObject = await trigger(thisDataset.id);

        let dataTableColumnArray = [];
        for (const [key, value] of Object.entries(myDataObject.data[0])) {
            const dataTableColumnObject = {
                field: key,
                headerName: key,
                width: 150
            };
            dataTableColumnArray.push(dataTableColumnObject);
        }

        setDataTableColumns(dataTableColumnArray);

        setThisDatasetResults(myDataObject.data);



        // setRequestStatus("pending");

        // try {
        //     const response = await fetch(`${baseURL}/datasets/results/${thisDataset?.id}`, {
        //         method: "GET",
        //         headers: {
        //             "Content-Type": "application/json",
        //             // need to add accesstoken to this request.
        //             "Authorization": `Bearer ${currentUser.accessToken}`
        //         },
        //         redirect: "follow"
        //     });

        //     const data = await response.json();


        //     if (data.length > 0) {

        //         console.log(data[0]);
        //         let dataTableColumnArray = [];
        //         for (const [key, value] of Object.entries(data[0])) {
        //             const dataTableColumnObject = {
        //                 field: key,
        //                 headerName: key,
        //                 width: 150
        //             };
        //             dataTableColumnArray.push(dataTableColumnObject);
        //         }



        //     }
        // } catch (err) {
        //     console.log(err);
        // } finally {
        //     setRequestStatus("idle");
        // }
    }

    return (
        <div className="resultsContainer">
            <div className="resultsHeader">
                <Button variant="contained" size="small" onClick={refreshBtnClicked}>Refresh Data</Button>
            </div>
            <div className="resultsContent">

                {thisDatasetResults.length > 0
                    ?
                    <DataGrid
                        rows={thisDatasetResults}
                        columns={dataTableColumns}
                        rowHeight={20}
                    />
                    :
                    <p>No data. Click on refresh.</p>
                }
            </div>
        </div>
    )
}

export default SingleDatasetResults;