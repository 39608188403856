import "./sidebar.scss";
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import PeopleIcon from '@mui/icons-material/People';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ShareIcon from '@mui/icons-material/Share';
import { Dialog, DialogTitle, List, ListItem, ListItemText, MenuItem, Tooltip } from "@mui/material";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PersonIcon from '@mui/icons-material/Person';
import RuleIcon from '@mui/icons-material/Rule';
import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import UserAccountDialog from "../dialog/UserAccountDialog";
import { logOutCurrentUser, selectCurrentUser } from "../../features/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../hooks/useLogout";
import { selectRTKCurrentUser } from "../../features/auth/authSlice";


const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const logout = useLogout();

    const currentLocation = location.pathname.slice(1);
    //const currentUser = useSelector(selectCurrentUser);
    const currentUser = useSelector(selectRTKCurrentUser);

    const [selectedMenu, setSelectedMenu] = useState(currentLocation || "welcome");

    //USER ACCOUNT DIALOG
    const [userAccountDialogOptions, setUserAccountDialogOptions] = useState(["logout from app", "profile details"]);
    const [userAccountDialogOpen, setUserAccountDialogOpen] = useState(false);
    const [userAccountDialogSelectedValue, setUserAccountDialogSelectedValue] = useState("");

    const openUserAccountDialog = () => {
        setUserAccountDialogOpen(true);
    }

    const closeUserAccountDialog = (value) => {
        setUserAccountDialogOpen(false);
        setUserAccountDialogSelectedValue(value);
    }

    useEffect(() => {
        if (userAccountDialogSelectedValue === "logout from app") {
            console.log("dispatch logout function")
            logout();
        }

        if (userAccountDialogSelectedValue === "profile details") {
            console.log("navigate to profile page");
        }
    }, [dispatch, userAccountDialogSelectedValue]);

    return (
        <div className="sidebar">
            <div className="top">
                <div>
                    <p>flower</p>
                    <p>msg</p>
                    {process.env.NODE_ENV !== 'production' && <p>DEV</p>}
                </div>
            </div>
            <div className="center">
                <ul>
                    <li>
                        <MenuItem className={`menu-item ${selectedMenu === "welcome" ? "selected" : ""}`} id="welcome" component={Link} to="/">
                            <Tooltip title="welcome" placement="right" arrow>
                                <HomeOutlinedIcon fontSize="large" />
                            </Tooltip>
                        </MenuItem>
                    </li>
                    <li>
                        <MenuItem className={`menu-item ${selectedMenu === "conversations" ? "selected" : ""}`} id="conversations" component={Link} to='/conversations'>
                            <Tooltip title="Conversations" placement="right" arrow>
                                <QuestionAnswerOutlinedIcon fontSize="large" />
                            </Tooltip>
                        </MenuItem>
                    </li>
                    <li>
                        <MenuItem className={`menu-item ${selectedMenu === "users" ? "selected" : ""}`} id="users" component={Link} to="/users">
                            <Tooltip title="Users" placement="right" arrow>
                                <PeopleOutlinedIcon fontSize="large" />
                            </Tooltip>
                        </MenuItem>
                    </li>
                    <li>
                        <MenuItem className={`menu-item ${selectedMenu === "companies" ? "selected" : ""}`} id="companies" component={Link} to="/companies">
                            <Tooltip title="Companies" placement="right" arrow>
                                <BusinessIcon fontSize="large" />
                            </Tooltip>
                        </MenuItem>
                    </li>
                    <li>
                        <MenuItem className={`menu-item ${selectedMenu === "infoproducts" ? "selected" : ""}`} id="infoproducts" component={Link} to="/infoproducts">
                            <Tooltip title="Info products" placement="right" arrow>
                                <FeedIcon fontSize="large" />
                            </Tooltip>
                        </MenuItem>
                    </li>
                    <li>
                        <MenuItem className={`menu-item ${selectedMenu === "communicationtasks" ? "selected" : ""}`} id="communicationtasks" component={Link} to="/communicationtasks">
                            <Tooltip title="Communication" placement="right" arrow>
                                <ShareIcon fontSize="large" />
                            </Tooltip>
                        </MenuItem>
                    </li>
                    {(currentUser.userRole === 'superuser' || currentUser.allowedAccess.includes('rec_ctrl')) &&
                        <li>
                            <MenuItem className={`menu-item ${selectedMenu === "receptioncontrol" ? "selected" : ""}`} id="receptioncontrol" component={Link} to="/receptioncontrol">
                                <Tooltip title="Reception Control" placement="right" arrow>
                                    <RuleIcon fontSize="large" />
                                </Tooltip>
                            </MenuItem>
                        </li>
                    }

                </ul>
            </div>
            <div className="bottom">
                <ul>
                    <li>
                        <div onClick={() => openUserAccountDialog()}>
                            <Tooltip title={`${currentUser.firstName} ${currentUser.lastName}`} placement="right" arrow>
                                <PersonIcon fontSize="large" />
                            </Tooltip>
                        </div>
                        <Dialog
                            className="dialog"
                            onClose={closeUserAccountDialog}
                            open={userAccountDialogOpen}
                            sx={{
                                "& .MuiDialog-container": {
                                    justifyContent: "flex-start",
                                    alignItems: "flex-end"
                                }
                            }}
                            PaperProps={{
                                sx: {
                                    m: 0,
                                    left: 50,
                                    bottom: 110
                                }
                            }}
                        >
                            <DialogTitle>{currentUser.firstName} {currentUser.lastName}</DialogTitle>
                            <List sx={{ backgroundColor: "blue" }}>
                                {userAccountDialogOptions.map((option) => (
                                    <ListItem key={option} button onClick={() => closeUserAccountDialog(option)}>
                                        <ListItemText>{option}</ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Dialog>
                    </li>
                    {currentUser?.userRole === 'superuser' &&
                        /*
                        <li>
                        <div>
                        <Tooltip title="Settings" placement="right" arrow>
                        <SettingsOutlinedIcon fontSize="large" />
                        </Tooltip>
                        </div>
                        </li>
                                */
                        <li>
                            <MenuItem className={`menu-item ${selectedMenu === "settings" ? "selected" : ""}`} id="settings" component={Link} to="/settings">
                                <Tooltip title="Settings" placement="right" arrow>
                                    <SettingsOutlinedIcon fontSize="large" />
                                </Tooltip>
                            </MenuItem>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;