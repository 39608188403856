import { apiSlice } from "../api/apiSlice";

export const rtkConversationsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConversations: builder.query({
            query: () => '/conversations',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Conversations', id })), 'Conversations']
                    : ['Conversations']
            }
        }),
        updateTelegramUserVerifiedStatus: builder.mutation({
            query: (telegramUserObject) => ({
                url: '/conversations/updateVerifiedStatus',
                method: 'PUT',
                body: telegramUserObject
            }),
            invalidatesTags: ['Conversations']
        }),
        sendMessageFromAdmin: builder.mutation({
            query: (msgObj) => ({
                url: '/conversations/sendMessage',
                method: 'POST',
                body: msgObj
            }),
            invalidatesTags: ['Conversations']
        }),
        sendMenuToChat: builder.mutation({
            query: (chatId) => ({
                url: '/conversations/sendMenu',
                method: 'POST',
                body: { chatId }
            })
        }),
        getMenuFromChat: builder.query({
            query: (chatId) => `/conversations/getMenu/${chatId}`
        }),
        deleteMenuFromChat: builder.mutation({
            query: (chatId) => ({
                url: `/conversations/deleteMenu/${chatId}`,
                method: 'DELETE'
            })
        })
    })
})

export const {
    useGetConversationsQuery,
    useUpdateTelegramUserVerifiedStatusMutation,
    useSendMessageFromAdminMutation,
    useSendMenuToChatMutation,
    useLazyGetMenuFromChatQuery,
    useDeleteMenuFromChatMutation
} = rtkConversationsSlice;