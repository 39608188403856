import { useNavigate, useParams } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDeleteUsergroupMutation, useGetUsergroupsQuery, useUpdateUsergroupMutation } from "../../../rtkSettingsSlice";
import "./singleusergroup.scss";
import { LightGreenButton, RedButton } from "../../../../../theme/buttons";
import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

const revenueDepartmentOptions = [
    {
        code: 'kvv',
        description: 'KlokVoorVerkoop'
    },
    {
        code: 'vmp',
        description: 'VMP'
    },
    {
        code: 'sag',
        description: 'Shop and Go'
    },
    {
        code: 'gc',
        description: 'Groencentrum'
    }
]

const menuOptions = [
    {
        code: 'rec_ctrl',
        description: 'Reception Control'
    },
    {
        code: 'comm_tasks',
        description: 'Communiation Tasks'
    }
]

const SingleUsergroup = () => {
    const navigate = useNavigate();
    const params = useParams();

    const { thisUsergroup } = useGetUsergroupsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            thisUsergroup: data?.find((usergroup) => usergroup.id === params.usergroupId)
        })
    });


    const tempRevenueDepartments = thisUsergroup?.revenueDepartments.split(",");
    let thisUsergroupRevenueDepartments = [];
    tempRevenueDepartments.forEach((record) => {
        const matchingOption = revenueDepartmentOptions.find((option) => {
            return option.code === record;
        });
        if (matchingOption) {
            thisUsergroupRevenueDepartments.push(matchingOption);
        }
    });

    const tempAllowedMenus = thisUsergroup?.allowedAccess.split(",");
    let thisUsergroupAllowedMenus = [];
    tempAllowedMenus.forEach((record) => {
        const matchingOption = menuOptions.find((option) => {
            return option.code === record;
        });
        if (matchingOption) {
            thisUsergroupAllowedMenus.push(matchingOption);
        }
    });

    const [description, setDescription] = useState(thisUsergroup?.description);
    const [selectedRevenueDepartments, setSelectedRevenueDepartments] = useState(thisUsergroupRevenueDepartments);
    const [allowedMenus, setAllowedMenus] = useState(thisUsergroupAllowedMenus);

    const descriptionChanged = (e) => setDescription(e.target.value);
    const selectedRevenueDepartmentsChanged = (event, newvalue) => setSelectedRevenueDepartments(newvalue);
    const allowedMenusChanged = (event, newvalue) => setAllowedMenus(newvalue);

    const [updateUsergroup] = useUpdateUsergroupMutation()
    const [deleteUsergroup] = useDeleteUsergroupMutation()

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        let finalRevenueDepartments = [];
        selectedRevenueDepartments.forEach((record) => {
            finalRevenueDepartments.push(record.code)
        });

        let finalAllowedMenus = [];
        allowedMenus.forEach((record) => {
            finalAllowedMenus.push(record.code)
        });

        const updatedUsergroupObject = {
            id: thisUsergroup.id,
            description,
            revenueDepartments: finalRevenueDepartments,
            allowedMenus: finalAllowedMenus
        }


        console.log(updatedUsergroupObject);

        try {
            await updateUsergroup(updatedUsergroupObject).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            setDescription("");
            setSelectedRevenueDepartments([]);
            setAllowedMenus([]);
            navigate("../../usergroups");
        }

    }

    const deleteBtnClicked = async (usergroupId) => {

        try {
            await deleteUsergroup(usergroupId);
        } catch (err) {
            console.log(err);
        } finally {
            navigate("../../usergroups");
        }

    }


    return (
        <div className="singleUsergroupForm">
            <div className="singleUsergroupFormContainer">
                <div className="header">
                    <div className="title">
                        <h3>{thisUsergroup.description}</h3>
                    </div>
                    <div className="deleteBtn">
                        <RedButton
                            startIcon={<DeleteForeverIcon />}
                            size="small"
                            onClick={() => deleteBtnClicked(thisUsergroup.id)}
                        >Delete</RedButton>
                    </div>
                </div>
                <div className="content">
                    <form onSubmit={formSubmitHandler}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Description</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" value={description} onChange={descriptionChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Selected revenue departments</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={revenueDepartmentOptions}
                                        onChange={(event, newvalue) => selectedRevenueDepartmentsChanged(event, newvalue)}
                                        value={selectedRevenueDepartments}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.code === value.code;
                                        }}
                                        getOptionLabel={(option) => {
                                            return (
                                                `${option.description}`
                                            )
                                        }
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Allowed menus</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={menuOptions}
                                        onChange={(event, newvalue) => allowedMenusChanged(event, newvalue)}
                                        value={allowedMenus}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.code === value.code;
                                        }}
                                        getOptionLabel={(option) => {
                                            return (
                                                `${option.description}`
                                            )
                                        }
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div className="cancelBtn">
                                <RedButton
                                    startIcon={<CancelIcon />}
                                    size="small"
                                    onClick={() => navigate("../")}
                                >Cancel</RedButton>
                            </div>
                            <div className="submitBtn">
                                <LightGreenButton
                                    type="submit"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                >Save</LightGreenButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleUsergroup;