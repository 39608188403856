import ReturnBar from "../../../../components/returnbar/ReturnBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import SingleUserForm from "../../components/singleuserform/SingleUserForm";
import "./singleuser.scss";

const SingleUser = () => {
    return (
        <div className="singleUser">
            <Sidebar/>
            <ReturnBar/>
            <SingleUserForm/>
        </div>
    )
}

export default SingleUser;