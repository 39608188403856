export const getAvatarShortCode = (firstName,lastName) => {
    if(!lastName){
        const shortCode = firstName.slice(0,1);
        return shortCode;
    } else {
        const shortCode = firstName.slice(0,1) + lastName.slice(0,1);
        return shortCode;
    }
}


