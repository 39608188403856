import { MenuItem, Select, TextField } from "@mui/material";
import { LightGreenButton } from "../../../../theme/buttons";
import SaveIcon from '@mui/icons-material/Save';
import "./addcompanyform.scss";
import Save from "@mui/icons-material/Save";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewCompany } from "../../companiesSlice";
import { useNavigate } from "react-router-dom"
import { useAddCompanyMutation } from "../../rtkCompaniesSlice";

const AddCompanyForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [addCompany, { isSuccess }] = useAddCompanyMutation();

    const [companyName, setCompanyName] = useState("");
    const [adminNumber, setAdminNumber] = useState("");
    const [companyRole, setCompanyRole] = useState("");

    const companyNameChanged = e => setCompanyName(e.target.value);
    const adminNumberChanged = e => setAdminNumber(e.target.value);
    const companyRoleChanged = e => setCompanyRole(e.target.value);

    const saveButtonClicked = async (e) => {
        e.preventDefault();

        const companyObject = {
            companyName,
            adminNumber,
            companyRole
        };

        try {
            // dispatch(addNewCompany(companyObject));
            await addCompany(companyObject)
        } catch (err) {
            console.log(err);
        } finally {
            setCompanyName("");
            setCompanyRole("");
            setAdminNumber("");

            navigate("../../companies");

        }


    }

    return (
        <div className="addCompanyForm">
            <div className="addCompanyFormContainer">
                <div className="header">
                    <h3>New Company</h3>
                </div>
                <div className="content">
                    <form onSubmit={saveButtonClicked}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Company Name:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={companyNameChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Admin. Nr.:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={adminNumberChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Company Role:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Select
                                        size="small"
                                        value={companyRole}
                                        onChange={companyRoleChanged}
                                        sx={{ width: "100%", height: "30px" }}>
                                        <MenuItem value="Agent">Agent</MenuItem>
                                        <MenuItem value="Supplier">Supplier</MenuItem>
                                        <MenuItem value="Buyer">Buyer</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="submitBtn">
                            <LightGreenButton
                                type="submit"
                                startIcon={<SaveIcon />}
                                size="small"
                            >Save</LightGreenButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddCompanyForm;