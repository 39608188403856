import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import { currentUserUpdated, fetchAllUsers, selectCurrentUser } from "../../features/users/usersSlice";
import "./home.scss";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMyHook from "../../hooks/useMyHook";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLogout from "../../hooks/useLogout";
import { baseURL } from "../../app/baseURL";
import { useGetUsersQuery } from "../../features/users/rtkUsersSlice";
import { selectRTKCurrentUser } from "../../features/auth/authSlice";

const Home = () => {
    const {
        data: rtkAllUsers,
        isSuccess
    } = useGetUsersQuery();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectRTKCurrentUser);

    const deleteHandler = async (e) => {
        console.log("from the delete handler");
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <div>
                    {currentUser &&
                        <>
                            <h1>{`Welkom ${currentUser.firstName} ${currentUser.lastName}.`}</h1>
                            {currentUser.userRole === "superuser"
                                ? <p>U bent een {currentUser.userRole}, dus je mag andere users verwijderen</p>
                                : <p>U bent een {currentUser.userRole}, dus je mag allen je eigen user verwijderen</p>
                            }
                        </>
                    }
                    <ul>
                        {isSuccess && rtkAllUsers.map(user => (
                            <li key={user.id}>
                                <p>{user.firstName}</p>
                                <button value={user.id} onClick={deleteHandler}>{`Delete ${user.firstName}`}</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Home;