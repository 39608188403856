import { Button, IconButton, MenuItem, Select, Step, stepClasses, StepLabel, Stepper, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LightGreenButton, RedButton } from "../../../../../theme/buttons";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import "./addnewdataset.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { addNewDataset, fetchAllIntegrations, selectAllIntegrations, selectStatus } from "../../../settingsSlice";
import ReturnBar from "../../../../../components/returnbar/ReturnBar";
import { useEffect } from "react";
import { useAddDatasetMutation, useGetIntegrationsQuery, useGetSingleIntegrationDataResultsQuery, useLazyGetSingleIntegrationDataResultsQuery } from "../../../rtkSettingsSlice";
import { selectRTKCurrentUser } from "../../../../auth/authSlice";

const AddNewDataset = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { data: integrations, isSuccess } = useGetIntegrationsQuery()
    const status = useSelector(selectStatus);
    const currentUser = useSelector(selectRTKCurrentUser);

    const [steps, setSteps] = useState(['Basic data', 'Column mapping', 'Refresh Schedule', 'Finish']);
    const [activeStep, setActiveStep] = useState(0);
    const [description, setDescription] = useState("");
    const [datasetType, setDatasetType] = useState("table");
    const [tableName, setTableName] = useState("");
    const [source, setSource] = useState("");
    const [datasetActive, setDatasetActive] = useState(false);
    const [availableUnifiers, setAvailableUnifiers] = useState([]);
    const [selectedUnifier, setSelectedUnifier] = useState("");
    const [weekdays, setWeekdays] = useState(["monday", "tuesday", "wednesday", "thursday", "friday"]);
    const [frequencyQuantity, setFrequencyQuantity] = useState(5);
    const [frequencyUnit, setFrequencyUnit] = useState("minutes");
    const [timeslotFromTimeHours, setTimeslotFromTimeHours] = useState("00");
    const [timeslotFromTimeMinutes, setTimeslotFromTimeMinutes] = useState("00");
    const [timeslotUntilTimeHours, setTimeslotUntilTimeHours] = useState("00");
    const [timeslotUntilTimeMinutes, setTimeslotUntilTimeMinutes] = useState("00");
    const [dataResultRows, setDataRestultRows] = useState([]);
    const [availableFields, setAvailableFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);

    const descriptionChanged = (e) => setDescription(e.target.value);
    const datasetTypeChanged = (e) => setDatasetType(e.target.value);
    const tableNameChanged = (e) => setTableName(e.target.value);
    const datasetActiveChanged = (e) => setDatasetActive(e.target.value);
    const selectedUnifierChanged = (e) => setSelectedUnifier(e.target.value);
    const weekdaysChanged = (e, newWeekDays) => setWeekdays(newWeekDays);
    const frequencyQuantityChanged = (e) => setFrequencyQuantity(e.target.value);
    const frequencyUnitChanged = (e) => setFrequencyUnit(e.target.value);
    const timeslotFromTimeHoursChanged = (e) => setTimeslotFromTimeHours(e.target.value);
    const timeslotFromTimeMinutesChanged = (e) => setTimeslotFromTimeMinutes(e.target.value);
    const timeslotUntilTimeHoursChanged = (e) => setTimeslotUntilTimeHours(e.target.value);
    const timeslotUntilTimeMinutesChanged = (e) => setTimeslotUntilTimeMinutes(e.target.value);
    const fieldTypeChanged = (field, value) => {
        const newSelectedFields = selectedFields.map((obj) => {
            return obj.key === field ? { ...obj, fieldType: value } : obj
        });

        console.log(newSelectedFields);

        setSelectedFields(newSelectedFields);
    }

    const [addDataset] = useAddDatasetMutation();
    const [trigger, result] = useLazyGetSingleIntegrationDataResultsQuery({
        selectFromResult: ({ data, isSuccess }) => {
            if (isSuccess) {
                return data;
            }
        }
    });

    const sourceChanged = async (e) => {
        setSource(e.target.value);
        const response = await trigger(e.target.value);
        const { data } = response;

        let keyValueArray = [];
        for (const [key, value] of Object.entries(data[0])) {
            const newObject = {
                key: key,
                value: String(value),
                fieldType: "string"
            };
            keyValueArray.push(newObject);
        };
        const filteredKeyValueArray = keyValueArray.filter((element) => {
            return element.key !== '@odata.etag' && element.key !== 'ItemInternalId';
        });
        filteredKeyValueArray.sort((a, b) => {
            if (a.key < b.key) {
                return -1;
            }
            if (a.key > b.key) {
                return 1;
            }
            return 0;
        });


        const uniqueIdentifiers = filteredKeyValueArray.filter(element => element.key.slice(-3) === '_ID');

        setAvailableUnifiers(uniqueIdentifiers);
        setDataRestultRows(filteredKeyValueArray);
        setAvailableFields(filteredKeyValueArray);
    }

    const nextBtnHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const previousBtnHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep = 1);
    }

    const submitBtnHandler = async (e) => {
        e.preventDefault();
        const newDataSetObject = {
            description,
            datasetType,
            tableName,
            source,
            datasetActive,
            selectedUnifier,
            selectedFields,
            refreshSchedule: [
                {
                    weekdays,
                    timeslotFrom: timeslotFromTimeHours + timeslotFromTimeMinutes,
                    timeslotUntil: timeslotUntilTimeHours + timeslotUntilTimeMinutes,
                    frequencyQuantity,
                    frequencyUnit
                }
            ]
        };

        try {
            await addDataset(newDataSetObject).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            setDescription("");
            setTableName("");

            navigate("../../datasets");
        }
    }

    const addFieldBtnClicked = (e) => {
        const newSelectedField = dataResultRows.find((element) => {
            return element.key === e.currentTarget.id;
        });

        setSelectedFields((prevData) => {
            return [...prevData, newSelectedField];
        });

        const newAvailableFieldsArray = availableFields.filter((element) => {
            return element.key !== e.currentTarget.id;
        });
        setAvailableFields(newAvailableFieldsArray);

    }

    return (
        <div className="addDatasetForm">
            <ReturnBar />
            <div className="addDatasetFormContainer">
                <div className="header">
                    <h3>New Dataset</h3>
                </div>
                <div className="content">
                    <div className="stepper">
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </div>
                    <div className="stepContentContainer">
                        <form className="stepContentForm" onSubmit={submitBtnHandler}>
                            {activeStep === 0 &&
                                <div className="stepContentFormFields">
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Description</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <TextField size="small" variant="outlined" className="field-input__input" onChange={descriptionChanged}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Type</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={datasetType}
                                                    onChange={datasetTypeChanged}
                                                    sx={{ width: "100%", height: "30px" }}>
                                                    <MenuItem value="table">Table</MenuItem>
                                                    <MenuItem value="view" disabled>View (to be developed)</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    {datasetType === "table" &&
                                        <div className="field-input">
                                            <div className="field-input__header">
                                                <label>Table name</label>
                                            </div>
                                            <div className="field-input__wrapper">
                                                <div className="field-input__container">
                                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={tableNameChanged}></TextField>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Source</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={source}
                                                    onChange={sourceChanged}
                                                    sx={{ width: "100%", height: "30px" }}>
                                                    {isSuccess && integrations.map((integration) => {
                                                        return (
                                                            <MenuItem key={integration.id} id={integration.id} value={integration.id}>{integration.description}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Active</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={datasetActive}
                                                    onChange={datasetActiveChanged}
                                                    sx={{ width: "100%", height: "30px" }}>
                                                    <MenuItem value={true}>Yes</MenuItem>
                                                    <MenuItem value={false}>No</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Unique column</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <Select
                                                    size="small"
                                                    value={selectedUnifier}
                                                    onChange={selectedUnifierChanged}
                                                    sx={{ width: "100%", height: "30px" }}>
                                                    {availableUnifiers.map((unifier) => {
                                                        return <MenuItem value={unifier.key} key={unifier.key} id={unifier.key}>{unifier.key}</MenuItem>
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                            {activeStep === 1 &&
                                <div className="stepContentFormFields">
                                    <div className="columnMapping">
                                        <div className="fetchResults">
                                            <h4>Columns from datasource</h4>
                                            <ul className="fetchResultList">
                                                {availableFields.length && availableFields.map((row) => {
                                                    return (
                                                        <li key={row.key} className="fetchResultListItem">
                                                            <div className="fetchResultListItemContainer">
                                                                <div className="fieldName">
                                                                    <label>Fieldname:</label>
                                                                    <p>{row.key}</p>
                                                                </div>
                                                                <div className="exampleValue">
                                                                    <label>Example Value</label>
                                                                    <p>{row.value}</p>
                                                                </div>
                                                                <div className="addButton">
                                                                    <IconButton id={row.key} color="primary" onClick={addFieldBtnClicked}>
                                                                        <AddBoxIcon fontSize="large" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="tableCreator">
                                            <h4>Table Creator</h4>
                                            <ul className="tableCreatorList">
                                                {selectedFields.length && selectedFields.map((row) => {
                                                    return (
                                                        <li key={row.key} className="tableCreatorListItem">
                                                            <div className="tableCreatorListItemContainer">
                                                                <div className="fieldName">
                                                                    <label>Fieldname:</label>
                                                                    <p>{row.key}</p>
                                                                </div>
                                                                <div className="exampleValue">
                                                                    <label>Example Value</label>
                                                                    <p>{row.value}</p>
                                                                </div>
                                                                <div id={row.key} className="fieldType">
                                                                    <Select
                                                                        size="small"
                                                                        value={row.fieldType}
                                                                        id={row.key}
                                                                        onChange={(event) => fieldTypeChanged(row.key, event.target.value)}
                                                                        sx={{ width: "100%", height: "30px" }}>
                                                                        <MenuItem value="string">String</MenuItem>
                                                                        <MenuItem value="datetime">Datetime</MenuItem>
                                                                        <MenuItem value="time">Time</MenuItem>
                                                                        <MenuItem value="number">Number</MenuItem>
                                                                        <MenuItem value="real">Decimal</MenuItem>
                                                                        <MenuItem value="boolean">Boolean</MenuItem>
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeStep === 2 &&
                                <div className="stepContentFormFields">
                                    <div className="field-input">
                                        <div className="field-input__header">
                                            <label>Refresh Schedule</label>
                                        </div>
                                        <div className="field-input__wrapper">
                                            <div className="field-input__container">
                                                <div className="refresh-schedule">
                                                    <div className="weekdays">
                                                        <div className="title">
                                                            <p>On these days</p>
                                                        </div>
                                                        <ToggleButtonGroup
                                                            value={weekdays}
                                                            onChange={weekdaysChanged}
                                                            className="weekdaysToggleButtonGroup"
                                                        >
                                                            <ToggleButton className="weekdayToggleButton" value="monday">M</ToggleButton>
                                                            <ToggleButton className="weekdayToggleButton" value="tuesday">T</ToggleButton>
                                                            <ToggleButton className="weekdayToggleButton" value="wednesday">W</ToggleButton>
                                                            <ToggleButton className="weekdayToggleButton" value="thursday">T</ToggleButton>
                                                            <ToggleButton className="weekdayToggleButton" value="friday">F</ToggleButton>
                                                            <ToggleButton className="weekdayToggleButton" value="saturday">S</ToggleButton>
                                                            <ToggleButton className="weekdayToggleButton" value="sunday">S</ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </div>
                                                    <div className="timeslot">
                                                        <div className="title">
                                                            <p>Between</p>
                                                        </div>
                                                        <div className="container">
                                                            <div className="fromTime">
                                                                <div className="hours">
                                                                    <TextField size="small" placeholder="hh" onChange={timeslotFromTimeHoursChanged}></TextField>
                                                                </div>
                                                                <div className="label">
                                                                    :
                                                                </div>
                                                                <div className="minutes">
                                                                    <TextField size="small" placeholder="mm" onChange={timeslotFromTimeMinutesChanged}></TextField>
                                                                </div>
                                                            </div>
                                                            <div className="label">
                                                                and
                                                            </div>
                                                            <div className="untilTime">
                                                                <div className="hours">
                                                                    <TextField size="small" placeholder="hh" onChange={timeslotUntilTimeHoursChanged}></TextField>
                                                                </div>
                                                                <div className="label">
                                                                    :
                                                                </div>
                                                                <div className="minutes">
                                                                    <TextField size="small" placeholder="mm" onChange={timeslotUntilTimeMinutesChanged}></TextField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="frequency">
                                                        <div className="title">
                                                            <p>Refresh every</p>
                                                        </div>
                                                        <div className="container">
                                                            <div className="quantity">
                                                                <TextField size="small" type="number" value={frequencyQuantity} onChange={frequencyQuantityChanged}></TextField>
                                                            </div>
                                                            <div className="unit">
                                                                <Select
                                                                    size="small"
                                                                    value={frequencyUnit}
                                                                    onChange={frequencyUnitChanged}
                                                                    sx={{ width: "100%" }}>
                                                                    <MenuItem value="minutes">minutes</MenuItem>
                                                                    <MenuItem value="hours">hours</MenuItem>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeStep === 3 &&
                                <div className="stepContentFormFields">
                                    Summary
                                </div>
                            }
                            <div className="stepContentFormButtons">
                                {activeStep !== 0 &&
                                    <div className="cancelBtn">
                                        <RedButton
                                            startIcon={<NavigateBeforeIcon />}
                                            size="small"
                                            onClick={previousBtnHandler}
                                        >Previous</RedButton>
                                    </div>
                                }
                                {activeStep !== steps.length - 1 &&
                                    <div className="nextBtn">
                                        <LightGreenButton
                                            startIcon={<NavigateNextIcon />}
                                            size="small"
                                            onClick={nextBtnHandler}
                                        >Next</LightGreenButton>
                                    </div>
                                }
                                {activeStep === steps.length - 1 &&
                                    <div className="submitBtn">
                                        <LightGreenButton
                                            type="submit"
                                            startIcon={<SaveIcon />}
                                            size="small"
                                        >Save</LightGreenButton>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewDataset;