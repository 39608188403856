import ReturnBar from "../../../../components/returnbar/ReturnBar";
import Sidebar from "../../../../components/sidebar/Sidebar";
import AddUserForm from "../../components/adduserform/AddUserForm";
import "./addnewuser.scss";

const AddNewUser = () => {
    return (
        <div className="newUser">
            <Sidebar/>
            <ReturnBar/>
            <AddUserForm/>
        </div>
    )
}

export default AddNewUser;