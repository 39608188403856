import { Autocomplete, TextField } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import "./addnewusergroup.scss";
import { useState } from "react";
import { LightGreenButton, RedButton } from "../../../../../theme/buttons";
import { useNavigate } from "react-router-dom";
import { useAddUserMutation } from "../../../../users/rtkUsersSlice";
import { useAddUsergroupMutation } from "../../../rtkSettingsSlice";

const revenueDepartmentOptions = [
    {
        code: 'kvv',
        description: 'KlokVoorVerkoop'
    },
    {
        code: 'vmp',
        description: 'VMP'
    },
    {
        code: 'sag',
        description: 'Shop and Go'
    },
    {
        code: 'gc',
        description: 'Groencentrum'
    }
]

const menuOptions = [
    {
        code: 'rec_ctrl',
        description: 'Reception Control'
    },
    {
        code: 'comm_tasks',
        description: 'Communiation Tasks'
    }
]

const AddNewUsergroup = () => {
    const navigate = useNavigate();

    const [description, setDescription] = useState("");
    const [selectedRevenueDepartments, setSelectedRevenueDepartments] = useState([]);
    const [allowedMenus, setAllowedMenus] = useState([]);

    const descriptionChanged = (e) => setDescription(e.target.value);
    const selectedRevenueDepartmentsChanged = (event, newvalue) => setSelectedRevenueDepartments(newvalue);
    const allowedMenusChanged = (event, newvalue) => setAllowedMenus(newvalue);

    const [addUsergroup] = useAddUsergroupMutation();

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        let finalRevenueDepartments = [];
        selectedRevenueDepartments.forEach((record) => {
            finalRevenueDepartments.push(record.code)
        });

        let finalAllowedMenus = [];
        allowedMenus.forEach((record) => {
            finalAllowedMenus.push(record.code)
        });


        const newUsergroupObject = {
            description,
            revenueDepartments: finalRevenueDepartments,
            allowedMenus: finalAllowedMenus
        }

        try {
            await addUsergroup(newUsergroupObject).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            setDescription("");
            setSelectedRevenueDepartments([]);
            setAllowedMenus([]);
            navigate("../../usergroups");
        }
    }

    return (
        <div className="addUsergroupForm">
            <div className="addUsergroupFormContainer">
                <div className="header">
                    <h3>New Usergroup</h3>
                </div>
                <div className="content">
                    <form onSubmit={formSubmitHandler}>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Description</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <TextField size="small" variant="outlined" className="field-input__input" onChange={descriptionChanged}></TextField>
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Selected revenue departments:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={revenueDepartmentOptions}
                                        onChange={(event, newvalue) => selectedRevenueDepartmentsChanged(event, newvalue)}
                                        value={selectedRevenueDepartments}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.code === value.code;
                                        }}
                                        getOptionLabel={(option) => {
                                            return (
                                                `${option.description}`
                                            )
                                        }
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field-input">
                            <div className="field-input__header">
                                <label>Allowed Menus:</label>
                            </div>
                            <div className="field-input__wrapper">
                                <div className="field-input__container">
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={menuOptions}
                                        onChange={(event, newvalue) => allowedMenusChanged(event, newvalue)}
                                        value={allowedMenus}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.code === value.code;
                                        }}
                                        getOptionLabel={(option) => {
                                            return (
                                                `${option.description}`
                                            )
                                        }
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div className="cancelBtn">
                                <RedButton
                                    startIcon={<CancelIcon />}
                                    size="small"
                                    onClick={() => navigate("../")}
                                >Cancel</RedButton>
                            </div>
                            <div className="submitBtn">
                                <LightGreenButton
                                    type="submit"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                >Save</LightGreenButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddNewUsergroup;