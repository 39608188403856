import "./returnbar.scss";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";

const ReturnBar = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }

    return (
        <div 
            className="returnbar"
            onClick={handleClick}
        >
            <ArrowBackIosNewIcon/>
        </div>
    )
}

export default ReturnBar;