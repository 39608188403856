import "./conversationsmainpage.scss";
import ConversationMenuBody from "../../components/conversationmenubody/ConversationMenuBody"
import ConversationMenuTabs from "../../components/conversationmenutabs/ConversationMenuTabs";
import Sidebar from "../../../../components/sidebar/Sidebar";
import { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMessageToConversation, conversationSelected, fetchConversations, selectAllConversations } from "../../conversationsSlice";
import ConversationMessages from "../../components/conversationmessages/ConversationMessages";
import ConversationProfile from "../../components/conversationprofile/ConversationProfile";
import { selectCurrentUser } from "../../../users/usersSlice";
import { useGetConversationsQuery } from "../../rtkConversationsSlice";

const ConversationsMainPage = () => {
    const dispatch = useDispatch();
    //const conversations = useSelector(selectAllConversations);
    const { data: conversations, isSuccess } = useGetConversationsQuery();
    //const selectedConversation = useSelector(state => state.conversations.currentConversation);

    useLayoutEffect(() => {
        if (isSuccess) {
            dispatch(conversationSelected(conversations[0]?.chatid));
        }
    }, [isSuccess, dispatch]);

    return (
        <div className="conversations">
            <Sidebar />
            <div className="conversationWrapper">
                <div className="conversationMenu">
                    <ConversationMenuTabs />
                    <ConversationMenuBody />
                    <div className="conversationMenuFooter">
                        {/* Conversation Menu Footer */}
                    </div>
                </div>
                <div className="conversationContainer">
                    <div className="conversationBox">
                        <ConversationMessages />
                    </div>
                    {/* <div className="conversationProfile">
                        <ConversationProfile />
                    </div> */}
                </div>
                <div className="conversationProfile">
                    <ConversationProfile />
                </div>
            </div>
        </div>
    )
}

export default ConversationsMainPage;