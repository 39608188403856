import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/sidebar/Sidebar";
import InfoProductsDataTable from "../../components/datatable/InfoProductsDataTable";
import InfoProductsNavbar from "../../components/navbar/InfoProductsNavbar";
import "./infoproductsmainpage.scss";

const InfoProductsMainPage = () => {

    return (
        <div className="infoproductsmainpage">
            <Sidebar/>
            <div className="infoproductsmainpagecontainer">
                <InfoProductsNavbar/>
                <InfoProductsDataTable />
            </div>
        </div>
    )
}

export default InfoProductsMainPage;