import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../app/baseURL";
import useAuth from "./useAuth";


const useLogout = () => {
    const { setCurrentUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const logout = async () => {
        setCurrentUser({})
        console.log("logout process starting")
        try{
            const response = await axios.post(
                `${baseURL}/auth/logout`, {},{
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );

            console.log("log out successfull");
            console.log(response.data);
            navigate("/login");
        } catch (err) {
            console.log(err.response.status);
            if(err.response.status === 403) {
                console.log("accessToken was not valid anyway, so we will send you back to login page")
                navigate("/login", {state: {from: location}, replace: true});
            }
        }
    }

    return logout;
}

export default useLogout;