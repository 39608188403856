import { useDispatch, useSelector } from "react-redux";
import "./eabstatusfilter.scss";
import { selectFilterInformation, setEabStatusFilter, setFilterInformation } from "../../../receptionControlSlice";
import { Box, Checkbox, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { applyFilter } from "../../../pages/main/applyFilter";

const EABStatusFilter = () => {
    const dispatch = useDispatch();
    const filterInformation = useSelector(selectFilterInformation);

    const eabStatusFilterOptions = [
        {
            fieldValue: 'no-eab',
            fieldDescription: 'No EAB',
            fieldColor: 'orange'
        },
        {
            fieldValue: 'eab-error',
            fieldDescription: 'EAB Error',
            fieldColor: 'rgba(255, 0, 0, 0.618)'
        },
        {
            fieldValue: 'eab-correct',
            fieldDescription: 'EAB Correct',
            fieldColor: 'lightgreen'
        }
    ];

    const currentEabStatusFilterChanged = (e) => {
        dispatch(setEabStatusFilter(e.target.value));
        const newFilterObject = {
            ...filterInformation,
            currentEabStatusFilter: e.target.value
        };
        const newFilteredCases = applyFilter(newFilterObject);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
    }

    const resetBtnClicked = () => {
        const newFilterObject = {
            ...filterInformation,
            currentEabStatusFilter: []
        };
        const newFilteredCases = applyFilter(newFilterObject);
        dispatch(setFilterInformation({
            ...newFilterObject,
            filteredCases: newFilteredCases
        }));
    }

    return (
        <div className="eabStatusFilterContainer">
            <div className="labelContainer">
                <label>EAB Status</label>
            </div>
            <div className="inputContainer">
                <Select
                    size="small"
                    value={filterInformation.currentEabStatusFilter}
                    onChange={currentEabStatusFilterChanged}
                    sx={{
                        width: '275px',
                        // height: '30px'
                    }}
                    multiple
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        let descriptionArray = [];
                        selected.forEach((item) => {
                            const matchingDescription = eabStatusFilterOptions.find((option) => {
                                return option.fieldValue === item;
                            });
                            descriptionArray.push(matchingDescription.fieldDescription);
                        })
                        return descriptionArray.join(', ')
                    }
                    }
                >
                    {eabStatusFilterOptions.map((option) => {
                        return (
                            <MenuItem key={option.fieldValue} value={option.fieldValue}>
                                <Checkbox checked={filterInformation.currentEabStatusFilter.indexOf(option.fieldValue) > -1} />
                                {/* <ListItemText primary={option} /> */}
                                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                    <Box sx={{ width: '15px', height: '15px', backgroundColor: option.fieldColor, borderRadius: '50px' }}></Box>
                                    <div>
                                        <label>{option.fieldDescription}</label>
                                    </div>
                                </Box>
                            </MenuItem>
                        )
                    })}
                    {/* <MenuItem value="no-eab">
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box sx={{ width: '15px', height: '15px', backgroundColor: 'orange', borderRadius: '50px' }}></Box>
                            <div>
                                <label>Geen EAB</label>
                            </div>
                        </Box>
                    </MenuItem>
                    <MenuItem value="eab-error">
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box sx={{ width: '15px', height: '15px', backgroundColor: 'rgba(255, 0, 0, 0.618)', borderRadius: '50px' }}></Box>
                            <div>
                                <label>EAB met verschillen</label>
                            </div>
                        </Box>
                    </MenuItem>
                    <MenuItem value="eab-correct">
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box sx={{ width: '15px', height: '15px', backgroundColor: 'lightgreen', borderRadius: '50px' }}></Box>
                            <div>
                                <label>EAB foutloos</label>
                            </div>
                        </Box>
                    </MenuItem> */}
                </Select>
                <span className="resetBtn" onClick={resetBtnClicked}>X</span>
            </div>
        </div>
    )
}

export default EABStatusFilter;