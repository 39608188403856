import { apiSlice } from "../api/apiSlice";
import infoproductsSlice from "./infoproductsSlice";

export const rtkInfoProductsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInfoproducts: builder.query({
            query: () => 'informationproducts',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Infoproducts', id })), 'Infoproducts']
                    : ['Infoproducts']
            }
        }),
        addInfoProduct: builder.mutation({
            query: (infoProductObject) => ({
                url: '/informationproducts',
                method: 'POST',
                body: infoProductObject
            }),
            invalidatesTags: ['Infoproducts']
        }),
        updateInfoProduct: builder.mutation({
            query: (infoProductObject) => ({
                url: `/informationproducts/${infoProductObject.id}`,
                method: 'PUT',
                body: infoProductObject
            }),
            invalidatesTags: ['Infoproducts']
        }),
        deleteInfoProduct: builder.mutation({
            query: (infoProductId) => ({
                url: `/informationproducts/${infoProductId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Infoproducts']
        }),
        getAllCommunicationTasks: builder.query({
            query: (dateParameters) => {
                console.log(dateParameters);
                const { fromDate, untilDate } = dateParameters;
                return `/informationproducts/communicationtasks?fromDate=${fromDate}&untilDate=${untilDate}`
            },
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Communicationtasks', id })), 'Communicationtasks']
                    : ['Communicationtasks']
            }

        }),
    })
})

export const {
    useGetInfoproductsQuery,
    useAddInfoProductMutation,
    useUpdateInfoProductMutation,
    useDeleteInfoProductMutation,
    useGetAllCommunicationTasksQuery
} = rtkInfoProductsSlice